@import '~@sonnen/shared-web/abstracts';

.c-account-community-profile {
  display: block;
}

.c-account-community-profile__description {
  margin-bottom: var(--gutter-section);

  @include breakpointUp(SM) {
    margin-bottom: var(--gap-8);
  }
}
