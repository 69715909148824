@import '~@sonnen/shared-web/abstracts';

.c-demo-battery-card-status {
  position: relative;
  height: 100%;
}

.c-demo-battery-card-status__bubble {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: var(--z-index-tooltip);
  width: 260px;
  transform: translate(-50%, -100%);

  @include breakpointUp(SM) {
    transform: translate(-50%, calc(-100% + var(--gap-10)));
  }
}
