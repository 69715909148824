@import '~@sonnen/shared-web/abstracts';

@mixin iconHoverState() {
  fill: map-get($infographic-colors, active);
  stroke: map-get($infographic-colors, active);
  stroke-width: 1.5;
}

.u-infographic-icon {
  cursor: pointer;
}

.u-infographic-icon__fill {
  fill: map-get($infographic-colors, white);
}

.u-infographic-icon__contour {
  fill: map-get($infographic-colors, dark);
  transition: fill 250ms ease;

  // stylelint-disable-next-line
  .u-infographic-icon.is-active & {
    @include iconHoverState();
  }
}

.u-infographic-icon__hover:hover .u-infographic-icon__contour {
  @include breakpointUp(XS) {
    @include iconHoverState();
  }
}
