@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-utility-pv-system-keys {
  width: 100%;
  margin: var(--gap-4) 0;
  line-height: 1.4;
}

.c-guide-acceptance-utility-pv-system-keys__text {
  margin: var(--gap-2) 0;
}

.c-guide-acceptance-utility-pv-system-keys__input {
  margin-bottom: var(--gap-3);

  @include breakpointUp(XS) {
    max-width: (var(--guide-acceptance-utility-input-width));
  }
}

.c-guide-acceptance-utility-pv-system-keys__first-pv {
  margin-bottom: var(--gap-2);
}

.c-guide-acceptance-utility-pv-system-keys__info {
  @include breakpointUp(XS) {
    max-width: (var(--guide-acceptance-utility-input-width));
  }
}

.c-guide-acceptance-utility-pv-system-keys__link-wrapper {
  margin-bottom: var(--gap-2);
  font-size: 14px;
}

.c-guide-acceptance-utility-pv-system-keys__link-icon {
  @include createIcon($_width: var(--gap-2), $_height: var(--gap-2));
}

.c-guide-acceptance-utility-pv-system-keys__link-question {
  margin-bottom: 0;
}

.c-guide-acceptance-utility-pv-system-keys__modal-question {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
}

.c-guide-acceptance-utility-pv-system-keys__modal-answer {
  margin-bottom: var(--gap-3);
  font-size: 14px;
}
