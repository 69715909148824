@import '~@sonnen/shared-web/abstracts';

.c-guide-document-entry {
  display: block;
}

.c-guide-document-entry__text {
  font-size: 14px;
  color: getTextColor('grey');
}

.c-guide-document-entry__text--em {
  color: getTextColor('dark');
}

.c-guide-document-entry__side {
  display: flex;
  justify-content: flex-end;
}

.c-guide-document-entry__button-icon {
  @include createIcon(
    $_color: getTextColor('blue'),
    $_width: var(--gap-3),
  );
}
