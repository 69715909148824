@import '~@sonnen/shared-web/abstracts';
@import '../SiteSwitcher/SiteSwitcher.helper';

.m-c-site-switcher-button {
  display: block;
  width: 100%;
}

.m-c-site-switcher-button__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--gap-2) var(--gap-2) calc(var(--gap-8) + 4px);
}

.m-c-site-switcher-button__content-label {
  padding-right: var(--gap-1);
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.m-c-site-switcher-button__content-icon {
  @include createIcon(
    $_color: getTextColor('blue'),
    $_height: 18px
  );
  flex: 0 18px;
  transform: rotate(180deg);
}
