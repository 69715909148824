@import '~@sonnen/shared-web/abstracts';

.c-cession-image-tutorial {
  display: block;
}

.c-cession-image-tutorial__content {
  margin: var(--gap-2) 0;
}

.c-cession-image-tutorial__title {
  margin-bottom: 0;
  color: getTextColor('dark');
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4em;
  white-space: pre-wrap;
}
