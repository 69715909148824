@import '~@sonnen/shared-web/abstracts';
/* stylelint-disable */
// TODO: Add variables in SW for those colors
.c-banner {
  position: relative;
  z-index: var(--z-index-banner);
  width: 100%;
  color: getTextColor('dark');
  background: #edfafd;
}

.c-banner__icon {
  @include createIcon(
    $_color: getTextColor('white'),
    $_width: 18px
  );
}

.c-banner--warning {
  background: #fffbe5;
}

.c-banner__icon--warning {
  @include createIcon(
    $_color: getTextColor('white'),
    $_width: 14px
  );
}

.c-banner__inner {
  padding: var(--gap-1) 0;
}

.c-banner__content {
  display: flex;
  align-items: center;
}

.c-banner__content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-banner__header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.c-banner__side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: var(--gap-2);
}

.c-banner__title-icon {
  @include createIcon(
    $_color: getTextColor('white'),
    $_width: 18px,
    $_height: 18px,
  );
}

.c-banner__icon-wrapper {
  display: inline-block;
  align-self: flex-start;
  margin-right: var(--gap-1);
  padding: 0 3px;
  width: var(--gap-3);
  height: var(--gap-3);
  border-radius: 50%;
  background: getColor('blue');
}

.c-banner__icon-wrapper--warning {
  padding: 0 5px;
  background: $color-light-gold;
}

.c-banner__title {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: .25px;
  text-transform: uppercase;
}

.c-banner__side-item {
  display: flex;
  align-items: center;
}

.c-banner__side-item .sw-button__label {
  color: getTextColor('dark');
}

.c-banner__side-item--spaced {
  margin-right: var(--gap-1);
}

.c-banner__arrow-icon {
  @include createIcon(
    $_color: getTextColor('dark'),
    $_width: 14px,
  );
  display: inline-block;
  transform: rotate(-90deg);
  transition: transform 250ms ease;
}

.c-banner__arrow-icon--rotated {
  transform: rotate(90deg);
}

.c-banner__description {
  max-height: 270px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 18px;
  opacity: 1;
  transition: all 250ms linear;
}

.c-banner__link {
  display: inline-block;
  padding: var(--gap-1) 0;
  color: getTextColor('blue');
  font-weight: 500;
}

.c-banner__description--closed {
  max-height: 0;
  opacity: 0;
}

.c-banner__description--m-closed {
  display: none;
}
