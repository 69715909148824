@import '~@sonnen/shared-web/abstracts';

.c-state-alert {
  --height: var(--gap-3);
  --font-size: 18px;
}

.c-state-alert {
  display: flex;
  font-size: var(--font-size);
  font-weight: 500;
  color: var(--color, #{getTextColor('dark')});
  padding: calc((var(--height) - var(--font-size)) / 2) 0;
  line-height: 1.2;
}

.c-state-alert--success {
  --color: #{$color-green};
}

.c-state-alert--warning {
  --color: #{$color-light-gold};
}

.c-state-alert--small {
  --font-size: 14px;
}

.c-state-alert--info {
  --color: #{getTextColor('grey')};
}

.c-state-alert__icon {
  @include createIcon(
    $_color: var(--color, #{getTextColor('dark')}),
    $_width: var(--font-size),
    $_height: var(--font-size),
  );
  display: inline-block;
  margin-right: 4px;
  line-height: 20px;
}
