@import '~@sonnen/shared-web/abstracts';

@mixin hasErrorState {
  &.has-error {
    border-color: $color-coral;
    color: $color-coral;
  }
}

.c-form-input__radio {
  @include createRadioInput(
    $_size: 16px,
    $_circle-size: 8px,
    $_font-size: 14px,
    $_font-weight: 400,
    $_color: getTextColor('dark'),
    $_background: getColor('white'),
    $_border-color: getColor('grey-light')
  );
}

.c-form-input__radio-label {
  @include hasErrorState();
  line-height: 1.4;
}

.c-form-input__radio-circle {
  @include hasErrorState();
}
