@import '~@sonnen/shared-web/abstracts';

.c-battery-downloads-list {
  margin-left: var(--gap-1);
  margin-top: var(--gap-3);
}

.c-battery-downloads-list__header {
  display: flex;
  padding: var(--gap-2) var(--gutter-card);
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('grey');
}

.c-battery-downloads-list__list-item {
  display: block;
  padding: var(--gap-1) 0;
  font-size: 14px;
  border-bottom: 1px solid getColor('grey-lightest');

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }

  @include breakpointUp(SM) {
    padding: var(--gap-2) 0;
  }
}
