@import '~@sonnen/shared-web/abstracts';

.c-nested-nav-slide-content {
  display: block;
  height: calc(100% - 64px); // 100% - header height
  overflow-y: scroll;
}

.c-nested-nav-slide-content--gap {
  padding: var(--gap-3);
}
