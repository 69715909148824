@import '~@sonnen/shared-web/abstracts';
@import './EFLine.animations';

$_color-production: $color-light-gold;
$_color-consumption: getColor('blue');
$_color-battery: $color-green;
$_color-grid: $color-gray;
$_line-gap: $_chart-line-gap;
$_dot-count: $_chart-dot-count;

// Animation speeds. If you want more kinds you can add them here.
// Keep in mind that this will affect .is-flowing-* class names.
$_animation-speed: (
  normal: 5s,
);

// Powerlines kinds.
$_line-params: (
  production-consumption: (
    top: 0,
    left: 0,
    width: calc(50% - #{$_line-gap}),
    height: calc(50% - #{$_line-gap}),
    border-width: 0 2px 2px 0,
    color: $_color-production,
    color-reverse: $_color-consumption,
    animation-name: makeBottomRightTurn
  ),
  production-grid: (
    top: 0,
    left: calc(50% - 1px),
    width: 2px,
    height: 100%,
    border-width: 0 2px 0 0,
    color: $_color-production,
    color-reverse: $_color-grid,
    animation-name: makeTopBottomTurn
  ),
  production-battery: (
    top: 0,
    right: 0,
    width: calc(50% - #{$_line-gap}),
    height: calc(50% - #{$_line-gap}),
    border-width: 0 0 2px 2px,
    color: $_color-production,
    color-reverse: $_color-battery,
    animation-name: makeBottomLeftTurn
  ),
  grid-consumption: (
    bottom: 0,
    left: 0,
    width: calc(50% - #{$_line-gap}),
    height: calc(50% - #{$_line-gap}),
    border-width: 2px 2px 0 0,
    color: $_color-grid,
    color-reverse: $_color-consumption,
    animation-name: makeTopRightTurn
  ),
  battery-grid: (
    bottom: 0,
    right: 0,
    width: calc(50% - #{$_line-gap}),
    height: calc(50% - #{$_line-gap}),
    border-width: 2px 0 0 2px,
    color: $_color-battery,
    color-reverse: $_color-grid,
    animation-name: makeTopLeftTurn,
    is-powerline-two-directional: true
  ),
  battery-consumption: (
    top: calc(50% - 1px),
    left: 0,
    width: 100%,
    height: 2px,
    border-width: 2px 0 0 0,
    color: $_color-battery,
    color-reverse: $_color-consumption,
    animation-name: makeRightLeftTurn
  )
);

// ==========================================================================
// This mixin adds colors to powerlines
// and it adds animations and colors to dots.
// ==========================================================================

@mixin createPowerFlow($_speed, $_line-color, $_animation-name, $_reverse: false) {
  z-index: 2;
  border-color: $_line-color;

  @for $_i from 1 through $_dot-count {
    .c-ef-line__dot:nth-of-type(#{$_i}) {
      $_animation-delay: ($_speed / $_dot-count) * ($_i - 1);

      animation: #{$_animation-name} $_speed linear $_animation-delay infinite;

      @if $_reverse {
        animation-direction: reverse;
      }

      @include breakpointUp(SM) {
        animation-name: #{$_animation-name}Desktop;
      }
    }
  }

  .c-ef-line__dot::after {
    background: $_line-color;
  }
}

.c-ef-line {
  position: absolute;
  border-style: solid;
  border-color: getColor('grey-lightest');
}

// ==========================================================================
// This is the main section.
// The powerline variations are generated here. Each powerline has
// additional classes which control the animation speed, color and direction.
// ==========================================================================

@each $_line in map-keys($_line-params) {
  .c-ef-line--#{$_line} {
    top: mapDeepGet($_line-params, $_line, 'top');
    right: mapDeepGet($_line-params, $_line, 'right');
    bottom: mapDeepGet($_line-params, $_line, 'bottom');
    left: mapDeepGet($_line-params, $_line, 'left');
    width: mapDeepGet($_line-params, $_line, 'width');
    height: mapDeepGet($_line-params, $_line, 'height');
    border-width: mapDeepGet($_line-params, $_line, 'border-width');

    // Each powerline has its own classes that control animations.
    @each $_name, $_value in $_animation-speed {
      &.is-flowing-#{$_name} {
        @include createPowerFlow(
          $_speed: $_value,
          $_line-color: mapDeepGet($_line-params, $_line, 'color'),
          $_animation-name: mapDeepGet($_line-params, $_line, 'animation-name')
        );
      }
    }

    @if mapDeepGet($_line-params, $_line, 'is-powerline-two-directional') {
      @each $_name, $_value in $_animation-speed {
        &.is-flowing-reverse-#{$_name} {
          @include createPowerFlow(
            $_speed: $_value,
            $_line-color: mapDeepGet($_line-params, $_line, 'color-reverse'),
            $_animation-name: mapDeepGet($_line-params, $_line, 'animation-name'),
            $_reverse: true
          );
        }
      }
    }
  }
}

// ==========================================================================
// This element serves as a wrapper for the powerline dot.
// Its size must be set to 100% so that we can translate it relative
// to parent. In the end we must make adjustments, because of parent's
// border, therefore the size is increased.
//
// The starting position of the wrapper is always the same. Also, the
// position of the dot is constant (see comment below). For some powerlines
// this resulted in showing the dot outside of the powerline in the beginning
// of animation. To prevent this, the opacity is set to 0 and it is set to 1
// when the animation begins.
// ==========================================================================

.c-ef-line__dot {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  opacity: 0;
  pointer-events: none;
  will-change: transform;
}

// ==========================================================================
// The actual dot. It is always placed in top right corner of the parent.
// ==========================================================================

.c-ef-line__dot::after {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
