@import '~@sonnen/shared-web/abstracts';
@import '../LiveEnergyFlow.helper';

@include getCircleGridImage();

.c-ef-circle__image-grid {
  height: 38px;
  transform: translate(-100%, 0);

  @include breakpointUp(SM) {
    height: 84px;
  }
}
