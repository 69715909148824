@import '~@sonnen/shared-web/abstracts';

$_checkmark-size: calc(var(--checkmark-size, 32) * 1px);

.c-checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: $_checkmark-size;
  height: $_checkmark-size;
  border-radius: 50%;
  background-color: getColor('grey-lightest');
}

.c-checkmark--active {
  background-color: getColor('blue');
}

.c-checkmark__icon {
  @include createIcon(
    $_color: getTextColor('white'),
    $_height: 50%,
  );
}
