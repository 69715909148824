@import '~@sonnen/shared-web/abstracts';

.c-notification {
  display: block;
  width: 100%;
  margin-bottom: -1px;
  border: 1px solid getColor('grey-lightest');
  border-width: 1px 0;
  padding: var(--gap-2) var(--gap-3);
  text-align: initial;
  transition: background-color 100ms ease 50ms;

  &:hover {
    background-color: getColor('grey-lightest');
  }
}

.c-notification__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.c-notification__date {
  font-size: 12px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: 1em;
}

.c-notification__type {
  display: flex;
  align-items: center;
}

.c-notification__type-title {
  display: inline-block;
  margin-top: 2px;
  font-size: 11px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: 1em;
  text-transform: uppercase;
}

.c-notification__icon {
  $_size: 20px;

  @include createIcon(
    $_height: $_size
  );
  display: flex;
  align-items: center;
  justify-content: center;
  width: $_size;
  height: $_size;
  margin-right: var(--gap-1);
}

.c-notification__title {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5em;
  color: getTextColor('dark');
}

.c-notification__subtitle {
  margin-bottom: 0;
  font-size: 14px;
  color: getTextColor('dark');
  line-height: 1.5em;
}
