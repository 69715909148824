@import '~@sonnen/shared-web/abstracts';

.c-infographic-value {
  width: 100%;
}

.c-infographic-value__title {
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('grey');
  margin-bottom: var(--gap-3);
}

.c-infographic-value__content {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.c-infographic-value__icon {
  @include createIcon(
    $_color: getTextColor('dark'),
    $_width: 36px,
  );
  align-self: center;
  margin-right: var(--gap-1);
}

.c-infographic-value__value {
  margin-right: var(--gap-1);
  font-size: 48px;
  font-weight: 500;
  color: $color-green;
  line-height: 1;
}

.c-infographic-value__value--theme-inactive {
  color: getTextColor('grey');
}

.c-infographic-value__unit {
  font-size: 20px;
  color: getTextColor('grey');
}

.c-infographic-value__description {
  text-align: center;
}
