@import '~@sonnen/shared-web/abstracts';

.c-csv-modal {
  --form-input-radio-group-right-padding: var(--gap-2);
  --form-input-radio-group-down-padding: var(--gap-2);
  --modal-layout-body-padding-v: var(--gap-3);
  --modal-layout-body-padding-h: var(--gap-3);
  --form-checkbox-color: getColor('grey');
  --form-checkbox-font-size: 14px;

  @include breakpointUp(SM) {
    --modal-layout-body-padding-v: 40px;
    --modal-layout-body-padding-h: 96px;
  }
}

.c-csv-modal__footer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.c-csv-modal__form-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: var(--gap-2);
  margin-top: var(--gap-1);
}

.c-csv-modal__checkbox-subtitle {
  margin-left: var(--gap-4);
  margin-top: var(--gap-1);
  font-size: 12px;
  color: getTextColor('grey');
}

.c-csv-modal__checkbox-promp {
  margin-top: var(--gap-3);
}

.c-csv-modal__year-picker {
  margin-bottom: var(--gap-3);
}

.c-csv-modal__checkbox-subtitle-data {
  @include breakpointDown(SM) {
    display: none;
  }
}
