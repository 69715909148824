@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-moving-details {
  position: relative;
}

.c-guide-acceptance-moving-details__date-input,
.c-guide-acceptance-moving-details__select {
  margin-top: var(--gap-2);

  @include breakpointUp(XS) {
    max-width: (var(--guide-acceptance-utility-input-width));
  }
}
