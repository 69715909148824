@import '~@sonnen/shared-web/abstracts';

.m-c-demo-analysis-energy-flow {
  position: relative;
}

.m-c-demo-analysis-energy-flow__bubble {
  position: absolute;
  max-width: 250px;
  z-index: var(--z-index-tooltip);
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
