@import '~@sonnen/shared-web/abstracts';
@import '~@sonnen/shared-web/src/styles/ds/typography';

.c-modal-success__icon {
  @include createIcon(
    $_color: getColor('blue'),
    $_height: 86px,
  );

  display: flex;
  justify-content: center;
  width: 100%;
}

.c-modal-success__paragraph {
  @include fontBodyMedium();

  width: 100%;
  margin-top: var(--gap-3);
  text-align: center;
  color: getTextColor('dark');
}
