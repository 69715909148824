@import '~@sonnen/shared-web/abstracts';

.c-tooltip-hoverable {
  position: relative;
}

.c-tooltip-hoverable__bubble {
  position: absolute;
  top: -40px;
  left: 15px;
  width: 250px;
  font-size: 12px;
}
