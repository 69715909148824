@import '~@sonnen/shared-web/abstracts';

.c-guide-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.c-guide-table-row__cell {
  flex: 1;

  @include breakpointUp(SM) {
    flex: 0 0 percentage(2 / 6);
    justify-content: flex-end;
  }
}

.c-guide-table-row__cell--narrow {
  @include breakpointUp(SM) {
    flex: 0 0 percentage(1 / 6);
  }
}
