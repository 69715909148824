@import '~@sonnen/shared-web/abstracts';

.c-full-width-kanva {
  --height: 500;
}

.c-full-width-kanva {
  position: relative;
  width: 100%;
  height: calc(var(--height) * 1px);
}

.c-full-width-kanva__canvas {
  width: 100%;
  height: 100%;
}

.c-analysis-day-chart__canvas {
  width: 100%;
  height: 500px;

  @include breakpointUp(SM) {
    height: 620px;
  }
}

.c-analysis-day-chart__canvas--with-single-line-chart {
  height: 560px;

  @include breakpointUp(SM) {
    height: 680px;
  }
}

.c-full-width-kanva__loader-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: getColor('white');
  transition: opacity 400ms ease-in-out;
  z-index: 1;

  &:not(.is-active) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &.is-active {
    opacity: .75;
    visibility: visible;
    pointer-events: all;
  }
}

.c-full-width-kanva__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
