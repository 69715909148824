@import '~@sonnen/shared-web/abstracts';

$_arrow-size: 30px;
$_button-height: 95px;
$_transition: .25s ease .1s;

@mixin buttonActiveState() {
  box-shadow: none;
  color: getTextColor('dark');
  background: getColor('grey-lightest');
}

%site-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: $_button-height;
  margin-top: -1px;
  border: 1px solid getColor('grey-lightest');
  border-width: 1px 0;
  padding: var(--gap-1) var(--gutter-layout);
  box-shadow: 0 2px 2px -2px rgba(getColor('grey-darkest'), .3);
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 500;
  color: getTextColor('grey');
  text-align: left;

  @include breakpointUp(NAV) {
    padding-left: var(--gap-4);
    padding-right: var(--gap-4);
  }
}
