// @TODO make the container div height 100% and remove the padding

.c-error-page {
  --icon-size: 100px;

  max-width: 350px;
  margin: 0 auto;
  padding: 20vh 0;
  text-align: center;
}

.c-error-page__button {
  margin-top: 20px;
}
