@import '~@sonnen/shared-web/abstracts';

.c-default-nav-subnav__icon {
  @include createIcon(
    $_height: 12px
  );
  display: inline-block;
  transform: rotate(-90deg);
  transition: transform 500ms ease 100ms;

  &.is-rotated {
    transform: rotate(90deg);
  }
}

.c-default-nav-subnav__content {
  display: flex;
  align-items: center;
}
