@import '~@sonnen/shared-web/abstracts';

.c-guide-cession-sign {
  display: block;
}

.c-guide-cession-sign__headline {
  margin-bottom: var(--gap-1);
  font-size: 20px;
  font-weight: 400;
  color: getTextColor('dark');
  line-height: 1.4em;
  white-space: pre-wrap;
}

.c-guide-cession-sign__headline--small {
  font-size: 14px;
  font-weight: 500;
}

.c-guide-cession-sign__text {
  margin-bottom: var(--gap-3);
  font-size: 14px;
  font-weight: 400;
  color: getTextColor('dark');
  line-height: 1.4em;
  white-space: pre-wrap;
}

.c-guide-cession-sign__help {
  margin-bottom: var(--gap-3);
}

.c-guide-cession-sign__help-description {
  margin-bottom: 0;
}

.c-guide-cession-sign__tutorial {
  margin-bottom: var(--gap-4);
}

.c-guide-cession-sign__document {
  margin: 0 calc(var(--gutter-layout) * -1) var(--gap-5);
  border: 1px solid getColor('grey-lightest');
  border-width: 1px 0;

  @include breakpointUp(SM) {
    margin-left: calc(var(--gutter-card) * -1);
    margin-right: calc(var(--gutter-card) * -1);
  }
}

.c-guide-cession-sign__footer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: var(--gap-3) 0 var(--gap-1);

  @include breakpointUp(MD) {
    margin: var(--gap-1);
    padding-right: var(--gap-4);
    justify-content: flex-end;
  }
}
