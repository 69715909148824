@import '~@sonnen/shared-web/abstracts';

.c-demo-analysis-energy-flow {
  position: relative;
}

.c-demo-analysis-energy-flow__content {
  position: relative;
  margin: 0 calc(var(--gutter-layout) * -1) var(--gutter-section);

  @include breakpointUp(SM) {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-demo-analysis-energy-flow__bubble {
  position: absolute;
  max-width: 250px;
  z-index: var(--z-index-tooltip);
}

.c-demo-analysis-energy-flow__bubble--mobile {
  bottom: var(--gap-9);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.c-demo-analysis-energy-flow__bubble--desktop {
  top: 0;
  left: 20%;
}
