@import '~@sonnen/shared-web/abstracts';

.c-account-tooltip-error {
  @include dropdown($_bottom: 40px, $_right: 0);
  @include createTriangleArrow($_side: 'bottom', $_offset: '100% - 30px');
  min-width: initial;
  width: 180px;
  padding: var(--gap-1);
}

.c-account-tooltip-error__content {
  display: flex;
  align-items: center;
}

.c-account-tooltip-error__icon {
  @include createIcon(
    $_width: var(--gap-2),
    $_color: $color-coral,
  );
  flex: 0 0 var(--gap-2);
  margin-right: var(--gap-1);
}

.c-account-tooltip-error__message {
  color: $color-coral;
  font-size: 12px;
  line-height: 1.3em;
}
