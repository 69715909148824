@import '~@sonnen/shared-web/abstracts';

.c-guide-purchase-flat-offer {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  &__product {
    @include boxShadow();
    width: 100%;
    background-color: $grey_50;
    display: block;
    border-radius: 4px 4px 0 0;
    transition: box-shadow 200ms ease-in-out;

    &:hover {
      box-shadow: 0 0 10px 0 getColor('grey-light');
    }
  }

  &__header {
    padding: 20px 32px 16px;
    font-weight: 500;
    font-size: 20px;
    line-height: 27.5px;
  }

  &__button {
    width: 100%;
    margin-top: var(--gap-3);
    margin-bottom: 22px;
    color: getTextColor('blue');
    font-size: 14px;
    font-weight: 500;
    text-align: center;

    &:disabled {
      color: getTextColor('grey');
      cursor: default;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    @include borderRadius('big');
  }

  &__section {
    background-color: $white;
    border-radius: 4px 4px 0 0;
    margin: 8px 0;
    padding: 0 32px;
    width: 100%;

    &--expired {
      opacity: .5;
    }

    &-dates {
      padding: 24px 32px;
    }

    &-documents {
      padding: 16px 32px;
    }

    &-configuration-details {
      padding: 32px;
      margin: 8px 0 0;
    }
  }
}
