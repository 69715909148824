@import '~@sonnen/shared-web/abstracts';

.c-contract-termination-modal-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--gap-2);

  > :not(:last-child) {
    margin-bottom: var(--gap-1);
  }

  @include breakpointUp('SM') {
    flex-direction: row-reverse;
    justify-content: flex-start;

    > :not(:last-child) {
      margin-bottom: 0;
    }

    > :last-child {
      margin-right: var(--gap-1);
    }
  }
}
