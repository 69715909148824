// ==========================================================================
// *1. Horizontal gaps are handled in 'main-inner' element below NAV
//     breakpoint and in root element above NAV breakpoint.
// *2. Mobile Safari doesn't support sliding in the menu from the top so we
//     slide the menu "the old way".
// *3. Prevents grid blowout:
//     https://css-tricks.com/preventing-a-grid-blowout/
//
// API:
// --layout-bottom-section-sticky-offset: allows to customize sticky
// position, useful when footer content is fixed.
// ==========================================================================

@import '~@sonnen/shared-web/abstracts';

// @TODO: Rethink z-index stacking context. Should layout sort this out? If not
// sticky position should not be in set in __content-bottom. Prepare Layout in
// a way that z-index is not affected by layout elements order.

// @TODO: Rethink grid layout. Maybe flex would be enough now.
// We can render different markup for mobiles.

.c-layout {
  --layout-content-gap-h: var(--gutter-layout); // 1
  --layout-content-gap-top: var(--gap-2);
  --layout-content-gap-bottom: var(--gap-5);
  --layout-side-width: 100%;
  --layout-bottom-section-sticky-offset-protected: var(--layout-bottom-section-sticky-offset, 0);

  @include breakpointUp(NAV) {
    --layout-content-gap-top: var(--gap-6);
    --layout-content-gap-bottom: var(--gap-7);
    --layout-side-gap: var(--gap-6);
    --layout-side-width: 250px;
  }
}

.c-layout {
  position: relative;
  display: grid;
  grid-template: auto auto auto auto 1fr auto auto / 100%;
  grid-template-areas:
    'header'
    'side'
    'portal'
    'top'
    'main'
    'bottom'
    'footer';
  min-height: 100vh;
  background: getColor('white');

  &.is-disabled {
    height: calc(100vh - var(--header-height));
    overflow: hidden;
  }

  @include breakpointUp(NAV) {
    grid-template:
      auto auto auto 1fr auto auto /
      var(--layout-content-gap-h)
      var(--layout-side-width)
      var(--layout-side-gap)
      minmax(0, 1fr) // 3
      var(--layout-content-gap-h);
    grid-template-areas:
      'header header header header header'
      'portal portal portal portal portal'
      'top top top top top'
      '. side . main .'
      'bottom bottom bottom bottom bottom'
      'footer footer footer footer footer';
  }

  @include breakpointUp(XLG) {
    // @TODO: For some reason, layout is a little wider than var(--container-max-width)
    // slightly above XLG breakpoint. Only when scrollbar is present.
    grid-template-columns:
      1fr
      var(--layout-side-width)
      var(--layout-side-gap)
      calc(var(--container-max-width) - var(--layout-side-width) - var(--layout-side-gap) - (2 * var(--gutter-layout)))
      1fr;
  }
}

.c-layout--gray {
  background-color: getColor('grey-lightest');
}

.c-layout--no-side {
  grid-template: auto auto auto 1fr auto auto / 100%;
  grid-template-areas:
    'header'
    'portal'
    'top'
    'main'
    'bottom'
    'footer';

  @include breakpointUp(NAV) {
    grid-template:
      auto auto auto 1fr auto auto /
      var(--layout-content-gap-h) minmax(0, 1fr) var(--layout-content-gap-h);
    grid-template-areas:
      'header header header'
      'portal portal portal'
      'top top top'
      '. main .'
      'bottom bottom bottom'
      'footer footer footer';
  }

  @include breakpointUp(XLG) {
    grid-template-columns: 1fr calc(var(--container-max-width) - (2 * var(--gutter-layout))) 1fr;
  }
}

.c-layout__header {
  grid-area: header;
}

.c-layout__portal {
  position: sticky;
  top: 0;
  z-index: var(--z-index-alert);
  grid-area: portal;
}

.c-layout__content-top {
  grid-area: top;
}

.c-layout__content-bottom {
  position: sticky;
  z-index: 2;
  bottom: var(--layout-bottom-section-sticky-offset-protected);
  grid-area: bottom;
}

.c-layout__side {
  position: relative;
  grid-area: side;
}

// @TODO: Add 'is-disabled' state (for example when SiteSwitcher is open)?
.c-layout__side-inner {
  @include breakpointUp(NAV) {
    position: sticky;
    z-index: 1;
    top: 0;
    padding: var(--layout-content-gap-top) 0 var(--layout-content-gap-bottom);
  }
}

.c-layout__side-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgba(getColor('white'), .75);
  cursor: not-allowed;

  @include breakpointUp(NAV) {
    padding: var(--layout-content-gap-top) 0 var(--layout-content-gap-bottom);
    background-clip: content-box;
  }
}

.c-layout__main {
  grid-area: main;
}

.c-layout__main-inner {
  height: 100%;
  padding: var(--layout-content-gap-top) var(--layout-content-gap-h) var(--layout-content-gap-bottom);

  @include breakpointUp(NAV) {
    padding-left: 0;
    padding-right: 0;
  }
}

.c-layout__main-inner--no-top-gap {
  padding-top: 0;
}

.c-layout__footer {
  grid-area: footer;
}
