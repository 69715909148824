@import '~@sonnen/shared-web/abstracts';

.c-demo-bubble {
  display: none;

  &.is-open {
    display: block;
  }
}

.c-demo-bubble__icon {
  @include createIcon(
    $_height: 18px,
    $_color: $color-dark
  );
  display: inline-block;
  margin-right: var(--gap-1);
}

.c-demo-bubble__headline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  font-size: 14px;
  color: getTextColor('dark');
  font-weight: 500;
}

.c-demo-bubble__content {
  font-size: 16px;
  line-height: 1.4em;
  color: getTextColor('grey');
}

.c-demo-bubble__button {
  margin-top: var(--gap-1);
}
