@import '~@sonnen/shared-web/abstracts';

.c-form-input-radio-group {
  --form-input-radio-group-right-padding-private: var(--form-input-radio-group-right-padding, --gap-1);
  --form-input-radio-group-down-padding-private: var(--form-input-radio-group-down-padding, --gap-1);
}

.c-form-input-radio-group__input {
  padding: 0 var(--form-input-radio-group-right-padding-private) var(--form-input-radio-group-down-padding-private) 0;
}

.c-form-input-radio-group__error-message {
  color: $color-coral;
  font-size: 12px;
  padding: var(--gap-1) 0;
}

.c-form-input-radio-group--row {
  display: flex;
}
