@import '~@sonnen/shared-web/abstracts';

.c-guide-purchase-hardware-offer__title {
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: 1em;
  white-space: nowrap;
}

.c-guide-purchase-hardware-offer__label {
  font-size: 14px;
  font-weight: 700;
  color: getTextColor('grey');
  padding: var(--gap-1) 0;
}

.c-guide-purchase-hardware-offer__products-title {
  font-size: 10px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: 1em;
  white-space: nowrap;
}

.c-guide-purchase-hardware-offer__products {
  width: 100%;
}

.c-guide-purchase-hardware-offer__img {
  height: 42px;
  width: auto;
  margin: 0 auto;
}

.c-guide-purchase-hardware-offer__attachment-title {
  font-size: 12px;
}
