@import '~@sonnen/shared-web/abstracts';

.c-demo-community-map-section-web {
  position: relative;
}

.c-demo-community-map-section-web__bubble {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: var(--z-index-tooltip);
  width: 280px;
  transform: translate(-50%, calc(-100% + var(--gap-5)));

  @include breakpointUp(SM) {
    left: 75%;
    transform: translate(-50%, calc(-100% + var(--gap-7)));
  }
}
