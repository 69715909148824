@import '~@sonnen/shared-web/abstracts';

.c-main-nav {
  @include breakpointUp(NAV) {
    @include boxShadow();
    @include borderRadius();
    background-color: getColor('white');
  }
}

.c-main-nav__item {
  padding: var(--gap-2) var(--gap-4);
  line-height: 0;
}

.c-main-nav__icon {
  @include createIcon(
    $_width: var(--gap-2)
  );
  margin-right: var(--gap-1);
}
