@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-offer-details {
  @include borderRadius('big');
  @include boxShadow();
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'headline'
    'img'
    'data';

  @include breakpointUp(MD) {
    grid-template: 1fr 4fr / 1fr var(--gap-3) 1fr;
    grid-template-areas:
      'img . headline'
      'img . data';
  }
  padding: 0 var(--gap-3);
  background-color: getColor('grey-lightest');
}

.c-guide-acceptance-offer-details__header {
  grid-area: headline;
  margin: 20px 0 var(--gap-2);
}

.c-guide-acceptance-offer-details__separator {
  margin: 0 calc(var(--gap-3) * -1) var(--gap-2);
  border-bottom: 2px solid getColor('grey-lightest');
}

.c-guide-acceptance-offer-details__img {
  grid-area: img;
  display: block;
  height: 30vh;
  margin: 0 calc(var(--gap-3) * -1);
  background: {
    image: url('../../../../../images/sonnen-flat-battery.jpg');
    position: center;
    repeat: no-repeat;
    size: cover;
  }

  @include breakpointUp(MD) {
    height: 100%;
    margin: 0;
    margin-left: calc(var(--gap-3) * -1);
  }
}

.c-guide-acceptance-offer-details__data {
  grid-area: data;
}

.c-guide-acceptance-offer-details__info {
  margin-bottom: var(--gap-2);
}
