@import '~@sonnen/shared-web/abstracts';

$_box-size: var(--gap-2);
$_transition: 200ms ease 100ms;

.c-guide-controlled-checkbox {
  --form-checkbox-color: #{getTextColor('dark')};
  --form-checkbox-box-background-color: #{getColor('white')};
  --form-checkbox-box-border-color: #{getTextColor('grey')};
  --form-checkbox-box-icon-color: #{getTextColor('blue')};
  --form-checkbox-box-icon-scale: 0;
  --form-checkbox-box-connect-line-color: #{getTextColor('grey')};
  --form-checkbox-margin-top: var(--gap-2);
  --form-checkbox-margin-bottom: var(--gap-3);
}

.c-guide-controlled-checkbox--connection-active {
  --form-checkbox-box-connect-line-color: #{getColor('blue')};
}

.c-guide-controlled-checkbox--error {
  --form-checkbox-color: #{$color-coral};
  --form-checkbox-box-border-color: #{$color-coral};
  --form-checkbox-box-icon-color: #{$color-coral};
}

.c-guide-controlled-checkbox {
  margin: var(--form-checkbox-margin-top) 0 var(--form-checkbox-margin-bottom);
}

.c-guide-controlled-checkbox--connected {
  position: relative;
}

.c-guide-controlled-checkbox--connected::after {
  content: '';
  display: block;
  width: 1px;
  height: calc(100% - #{$_box-size} + var(--form-checkbox-margin-bottom));
  border-left: 1px solid var(--form-checkbox-box-connect-line-color);
  position: absolute;
  top: $_box-size;
  left: calc(#{$_box-size} / 2);
  transition: border-color $_transition;
}

.c-guide-controlled-checkbox__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.c-guide-controlled-checkbox__label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.c-guide-controlled-checkbox__input:checked + .c-guide-controlled-checkbox__label {
  --form-checkbox-box-border-color: #{getColor('blue')};
  --form-checkbox-box-icon-scale: 1;
}

.c-guide-controlled-checkbox__input:disabled + .c-guide-controlled-checkbox__label {
  --form-checkbox-color: #{$color-gray};
  --form-checkbox-box-background-color: #{$color-gray-light};
  --form-checkbox-box-border-color: #{getTextColor('grey')};
  --form-checkbox-box-icon-color: #{getTextColor('grey')};
  cursor: not-allowed;
}

.c-guide-controlled-checkbox__box {
  @include borderRadius();
  flex: 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: $_box-size;
  height: $_box-size;
  margin-right: var(--gap-2);
  border: 1px solid var(--form-checkbox-box-border-color);
  background: var(--form-checkbox-box-background-color);
  transition: border-color $_transition, background-color $_transition;
}

.c-guide-controlled-checkbox__box-icon {
  @include createIcon(
    $_width: 10px,
    $_height: 10px,
    $_color: var(--form-checkbox-box-icon-color)
  );
  transform: scale(var(--form-checkbox-box-icon-scale));
  transition: fill $_transition, transform $_transition;
}

.c-guide-controlled-checkbox__text {
  font-size: 14px;
  color: var(--form-checkbox-color);
  line-height: $_box-size;
  text-transform: initial;
  user-select: none;
  transition: color $_transition;

  // stylelint-disable selector-max-type
  a:not([class]) {
    color: getTextColor('blue');
  }
}
