@import '~@sonnen/shared-web/abstracts';

.m-c-page__header {
  margin: 0 calc(var(--layout-content-gap-h) * -1);
  padding: var(--gap-2) 0;
}

.m-c-page__headline {
  display: flex;
  flex-wrap: wrap;
  margin: 0 var(--layout-content-gap-h);
  color: getTextColor('dark');
  font-size: 24px;
  font-weight: 300;
}

.m-c-page__headline--bordered {
  margin-bottom: var(--gap-2);
  padding-bottom: var(--gap-2);
  border-bottom: 2px solid getColor('grey-lightest');
}

.m-c-page__nav {
  margin-top: var(--gap-2);
  margin-bottom: var(--gap-1);
  box-shadow: 0 4px 4px -1px getColor('grey-light');
}

.m-c-page__side {
  align-self: flex-end;
  margin-left: auto;
}
