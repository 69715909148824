// ==========================================================================
// *1. @TODO: Move somewhere else, so that it doesn't have to be implemented
//     in every feature.
// ==========================================================================

@import '~@sonnen/shared-web/abstracts';

.c-demo-nav {
  position: relative;

  @include breakpointUp(NAV) {
    @include boxShadow();
    @include borderRadius();
  }
}

.c-demo-nav__bubble {
  position: absolute;
  z-index: var(--z-index-tooltip); // 1
  top: calc(100% + var(--gap-2));
  left: 50%;
  width: 210px;
  transform: translateX(-50%);

  @include breakpointUp(NAV) {
    top: 0;
    left: calc(100% + var(--gap-3));
    transform: translateX(0);
  }
}
