@import '~@sonnen/shared-web/abstracts';

.c-guide-purchase-flat-terms-modal__content {
  margin-bottom: var(--gap-2);

  @include breakpointUp(SM) {
    width: 85%;
  }
}

.c-guide-purchase-flat-terms-modal__section:not(:last-of-type) {
  margin-bottom: var(--gap-3);
}

.c-guide-purchase-flat-terms-modal__section-headline {
  margin-bottom: var(--gap-1);
}
