@import '~@sonnen/shared-web/abstracts';

$_background-elements: (
  background-cloud: map-get($infographic-colors, gray-light),
  city: map-get($infographic-colors, gray),
  grass: map-get($infographic-colors, green),
  hill: map-get($infographic-colors, green-light),
  powerline-cable: map-get($infographic-colors, gray-dark),
  powerline-pole: map-get($infographic-colors, gray-light-3),
  road: map-get($infographic-colors, green-dark),
  sky: map-get($infographic-colors, blue),
  windmill: map-get($infographic-colors, gray),
);

@each $_element in map-keys($_background-elements) {
  .c-infographic-scond-plan__#{$_element} {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: map-get($_background-elements, $_element);
  }
}

.c-infographic-scond-plan__cloud {
  fill: map-get($infographic-colors, white);
  stroke: map-get($infographic-colors, dark);
  stroke-width: 1.3;
  stroke-miterlimit: 10;
}

.c-infographic-scond-plan__shadow {
  isolation: isolate;
  fill: getColor('grey-darkest');
  opacity: .2;
}
