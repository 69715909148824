@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-details {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gap-2);

  &--big {
    margin: var(--gap-2) 0;
  }

  &__helper {
    margin-top: 2px;
    color: $grey_400;
    font-size: 12px;
  }

  &__line {
    display: flex;
    justify-content: space-between;
    font-size: 12px;

    &--big {
      font-size: 14px;
    }

    &--light {
      color: getTextColor('grey');
    }

    &-value {
      margin-left: var(--gap-4);
      font-weight: 500;
      white-space: nowrap;

      &--big {
        font-size: 16px;
        color: getTextColor('dark');
      }
    }
  }
}
