@import '~@sonnen/shared-web/abstracts';

.c-analysis-history {
  position: relative;
  overflow: hidden;
}

.c-analysis-history__headline {
  display: none;

  @include breakpointUp(SM) {
    @include typographySmallHeadline();
    margin-bottom: var(--gap-4);
  }
}

.c-analysis-history__bar-chart {
  position: relative;
  padding-bottom: 20px;
}

.c-analysis-history__pie-charts-container {
  display: flex;
  flex-direction: row;

  @include breakpointUp(MD) {
    flex-direction: column;
  }
}

.c-analysis-history__pie-chart {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  // @TODO: Rewrite when Analysis page is ready
  // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector, selector-max-universal
  & > * {
    max-width: 175px;
  }

  @include breakpointUp(SM) {
    max-width: 50%;
    flex-direction: row;
  }

  @include breakpointUp(MD) {
    max-width: 100%;
    flex-direction: column;
  }

  @include breakpointUp(config(BREAKPOINTS, LG) + 200px) {
    flex-direction: row;
  }
}

.c-analysis-history__tooltip-vertical {
  width: 180px;
}

.c-analysis-history__tooltip {
  padding: 0 var(--gap-1);
}

.c-analysis-history__banner-container {
  margin: 0 var(--gap-1);

  @include breakpointUp(SM) {
    margin: 0;
  }
}
