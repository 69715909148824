@import '~@sonnen/shared-web/abstracts';

.c-battery-specs-tech {
  padding-bottom: var(--gap-4);
}

.c-battery-specs-tech__divider {
  width: 100%;
  height: 1px;
  margin: var(--gap-3) 0;
  background-color: getColor('grey-lightest');
}

.c-battery-specs-tech__link-arrow {
  transform: translateY(.2em) rotate(180deg);
}
