@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-utility-move-house {
  width: 100%;
  margin: var(--gap-4) 0;
}

.c-guide-acceptance-utility-move-house__text {
  margin: var(--gap-2) 0;
}

.c-guide-acceptance-utility-move-house__radio-btn:not(:last-of-type) {
  margin-bottom: var(--gap-2);
}
