@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-input__title {
  font-size: 14px;
  font-weight: 500;
  margin: var(--gap-4) 0 var(--gap-3);
}

.c-guide-acceptance-input__button {
  display: flex;
  justify-content: center;
  margin-top: var(--gap-6);

  @include breakpointUp(SM) {
    width: 100%;
    padding-right: var(--gap-7);
    margin: var(--gap-3) 0;
    justify-content: flex-end;
  }
}

.c-guide-acceptance-input__fields {
  @include breakpointUp(SM) {
    width: 300px;
  }
}
