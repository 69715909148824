.c-guide-purchase-flat-offer-documents__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

.c-guide-purchase-flat-offer-documents__no-offer {
  color: getTextColor('grey');
  font-size: 12px;
  line-height: 1.67;
}
