@import '~@sonnen/shared-web/abstracts';

$_transition: 250ms ease 100ms;

@mixin getElementWhenSideNavIsOpen() {
  @at-root {
    .c-mobile-side-nav.is-open & {
      @content;
    }
  }
}

.c-mobile-side-nav {
  --nav-item-font-size: 16px;
}

.c-mobile-side-nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: var(--z-index-mobile-nav);
  height: calc(100vh - var(--bottom-nav-height));
}

.c-mobile-side-nav__header {
  padding-bottom: var(--gap-2);
  background-color: getColor('grey-darkest');
}

.c-mobile-side-nav__app-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  pointer-events: none;
  transition: background-color $_transition;

  @include getElementWhenSideNavIsOpen() {
    background: getColor('grey');
    pointer-events: initial;
  }
}

.c-mobile-side-nav__nav {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: inline-block;
  width: 75vw;
  max-width: 360px;
  height: 100%;
  background-color: getColor('white');
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateX(100%);
  transition:
    transform $_transition,
    box-shadow $_transition;

  @include getElementWhenSideNavIsOpen() {
    @include boxShadow($_side: 'left');
    transform: translateX(0);
  }
}

.c-mobile-side-nav__logo {
  --logo-height: var(--gap-3);
  --logo-color: #{getTextColor('white')};
  padding: var(--gap-3) var(--gap-4) var(--gap-2);
}

.c-mobile-side-nav__app-info {
  padding: var(--gap-2) var(--gap-4);
  color: getTextColor('disabled');
  font-size: 10px;
}

.c-mobile-side-nav__app-version {
  margin-bottom: var(--gap-1);
}
