@import '~@sonnen/shared-web/abstracts';

.c-battery-downloads-item {
  display: block;
}

.c-battery-downloads-item__link-icon {
  @include createIcon(
    $_width: 20px,
    $_color: getTextColor('blue'),
  );
}
