@import '~@sonnen/shared-web/abstracts';
@import '../LiveEnergyFlow.helper';

@include getCircleGridImage();

.c-ef-circle__image-windmills {
  height: 45px;
  transform: translate(100%, 0);

  @include breakpointUp(SM) {
    height: 99px;
  }
}
