@import '~@sonnen/shared-web/abstracts';

.c-demo-header {
  display: block;
}

.c-demo-header__logo {
  display: flex;
  align-items: center;
}

.c-demo-header__logo-label {
  margin-left: var(--gap-2);
  font-size: 14px;
  font-weight: 500;
}

.c-demo-header__nav {
  display: block;
}

.c-demo-header__nav-overlay {
  position: fixed;
  z-index: calc(var(--z-index-header) - 2);
  top: var(--header-height);
  width: 100%;
  height: calc(100vh - var(--header-height));
  background: rgba(getColor('white'), .7);
}

.c-demo-header__nav-content {
  @include boxShadow();
  position: absolute;
  z-index: calc(var(--z-index-header) - 1);
  top: -100%;
  width: 100%;
  padding: var(--gap-3) var(--gap-5);
  background: getColor('white');
  transition: top 500ms ease-in-out;

  &.is-open {
    top: var(--header-height);
  }
}

.c-demo-header__nav-description {
  font-size: 14px;
  text-align: center;
}

.c-demo-header__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--gap-3);

  @include breakpointUp(NAV) {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0;
  }
}

.c-demo-header__button-primary {
  margin-bottom: var(--gap-2);

  @include breakpointUp(NAV) {
    margin-bottom: 0;
    margin-right: var(--gap-1);
    order: 2;
  }
}
