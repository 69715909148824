@import '~@sonnen/shared-web/abstracts';

.c-phases-overview {
  --phase-overview-gap-h: var(--gap-1);
  --phase-overview-gap-v: var(--gap-2);
}

.c-phases-overview {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  margin: 0 calc(var(--phase-overview-gap-h) * -1) calc(var(--phase-overview-gap-v) * -1);
}

.c-phases-overview__item {
  display: flex;
  align-items: stretch;
  flex: 0 0 100%;
  margin-bottom: var(--phase-overview-gap-v);
  padding: 0 var(--phase-overview-gap-h);

  @include breakpointUp(XXS) {
    flex-basis: 50%;
  }

  @include breakpointUp(SM) {
    flex-basis: 25%;
  }
}
