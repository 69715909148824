@import '~@sonnen/shared-web/abstracts';

.c-specs-item {
  & + & {
    margin-top: var(--gap-1);
  }
}

.c-specs-item__label {
  @include typographyLabel($_type: 'light');
}

.c-specs-item__label--bold {
  @include typographyLabel($_type: 'bold');
}

.c-specs-item__label--dotted {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-specs-item__label--em {
  font-size: 36px;
  color: $color-green;
  line-height: var(--gap-6);
}

.c-specs-item__info {
  position: absolute;
  transform: translateY(4px);
}
