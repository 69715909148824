@import '~@sonnen/shared-web/abstracts';

.c-filter-section {
  margin-bottom: var(--gap-2);
}

.c-filter-section__available-contracts {
  margin-bottom: var(--gap-1);

  // font styles reflect `@mixin fontLabelMedium`. Use it after upgrading to dart-sass
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  color: getTextColor('primary');
}

.c-filter-section__dropdown-container {
  width: 100%;

  @include breakpointUp(SM) {
    width: 50%;
  }

  @include breakpointUp(MD) {
    width: 33%;
  }
}
