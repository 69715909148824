@import '~@sonnen/shared-web/abstracts';

$_toast-styles: (
  info: (
    bg-color: $color-gray-light,
    border-color: lighten($color-gray-light, 10%),
    text-color: getTextColor('grey'),
    button-color: getColor('blue'),
  ),
  success: (
    bg-color: $color-green,
    border-color: lighten($color-green, 10%),
    text-color: getTextColor('white'),
    button-color: getColor('white'),
  ),
  warning: (
    bg-color: $color-light-gold,
    border-color: lighten($color-light-gold, 10%),
    text-color: getTextColor('white'),
    button-color: getColor('white'),
  ),
  danger: (
    bg-color: $color-coral,
    border-color: lighten($color-coral, 10%),
    text-color: getTextColor('white'),
    button-color: getColor('white'),
  ),
);

.c-toast {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--toast-border-color);
  padding: var(--gap-1) var(--gutter-layout);
  background-color: var(--toast-bg-color);
}

@each $_type, $_style in $_toast-styles {
  .c-toast--#{$_type} {
    --toast-text-color: #{map-get($_style, 'text-color')};
    --toast-border-color: #{map-get($_style, 'border-color')};
    --toast-bg-color: #{map-get($_style, 'bg-color')};
    --toast-button-color: #{map-get($_style, 'button-color')};
  }
}

.c-toast--high {
  padding-top: var(--gap-3);
  padding-bottom: var(--gap-3);
}

.c-toast__content {
  display: flex;
  flex: 1 1;
  align-items: center;
  font-size: 14px;
  color: var(--toast-text-color);
  line-height: 1.4em;
}

.c-toast__side {
  position: relative;
  z-index: 0;
  flex: 0 0;
  margin-left: var(--gap-2);
}

.c-toast__button {
  position: relative;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  color: var(--toast-button-color);
  text-transform: uppercase;
  white-space: nowrap;

  &::before {
    @include borderRadius();
    @include fadeOut();
    content: '';
    position: absolute;
    z-index: -1;
    top: calc(var(--gap-1) * -1);
    left: calc(var(--gap-1) * -1);
    width: calc(100% + var(--gap-2));
    height: calc(100% + var(--gap-2));
    background: rgba(getColor('grey-darkest'), .05);
  }

  &:hover::before {
    @include fadeIn();
  }
}

.c-toast__button--round::before {
  border-radius: 50%;
}

.c-toast__icon {
  @include createIcon(
    $_width: var(--gap-2),
    $_height: var(--gap-2),
    $_color: var(--toast-text-color)
  );
  margin-right: var(--gap-1);
}

.c-toast__close-icon {
  @include createIcon(
    $_width: 12px,
    $_height: 12px,
    $_color: var(--toast-text-color)
  );
  opacity: .6;
}
