@import '~@sonnen/shared-web/abstracts';
@import '~leaflet/dist/leaflet.css';
@import '~leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

$_map-gesture-touch-warning: 'leaflet-gesture-handling-touch-warning';
$_map-gesture-scroll-warning: 'leaflet-gesture-handling-scroll-warning';
$_map-container: 'leaflet-container';
$_map-buttons: '.leaflet-left .leaflet-control';
$_zoom-buttons-height: 64px;
$_floating-padding: 20px;
$_map-height: 350px;

.c-community-map {
  position: relative;
}

.c-community-map__loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: getColor('grey-lighter');
}

.c-community-map__map {
  position: relative;
  z-index: 0;
  height: $_map-height;
  min-height: $_map-height;

  &.#{$_map-container} {
    font-family: var(--font-family);
    background-color: getColor('grey-lighter');

    a.c-community-map__popover-link {
      display: inline-block;
      margin-top: 13px;
      font-size: 12px;
      font-weight: 500;
      color: getTextColor('blue');
    }
  }

  &.#{$_map-container}::after {
    color: getTextColor('dark');
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 500;
  }

  &.#{$_map-gesture-touch-warning}::after,
  &.#{$_map-gesture-scroll-warning}::after {
    background-color: rgba(255, 255, 255, .5);
  }

  #{$_map-buttons} {
    margin-left: $_floating-padding;
    margin-top: $_floating-padding;
  }
}

.c-community-map__badge-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  // iOS doesn't support `transparent` keyword in gradients so we have to use rgba instead
  background-image: linear-gradient(to bottom, getColor('white'), rgba(getColor('white'), 0.5) 50%);
}

.c-community-map__icon {
  position: relative;
  font-size: 2.5em;
  color: getTextColor('dark');
  line-height: 1em;
  opacity: 1;

  &::before {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: getColor('white');
  }
}

.c-community-map__icon-location::after {
  content: '';
  position: absolute;
  left: 13px;
  bottom: -20px;
  border-width: 20px 2px 0;
  border-style: solid;
  border-color: getColor('grey-darkest') transparent transparent;
}

.c-community-map__locate {
  top: ($_zoom-buttons-height + $_floating-padding + 5px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: $_floating-padding;
  box-sizing: content-box;
  background-color: getColor('white');
  cursor: pointer;
  transition: all 0.25s ease 0.1s;

  &:hover {
    background-color: getColor('grey-lightest');
  }
}

.c-community-map__locate-icon {
  @include createIcon(
    $_color: getTextColor('dark'),
    $_width: 19px,
  )
}

// ==========================================================================
// Popover
// ==========================================================================

.c-community-map__popover {
  position: absolute;
  z-index: var(--z-index-map);
  max-width: 180px;
  padding: 17px 15px;
  @include borderRadius();
  @include boxShadow();
  background: getColor('white');
}

.c-community-map__popover--arrow-left-up {
  @include createTriangleArrow($_side: 'left', $_offset: 18px);
}

.c-community-map__popover--arrow-left-down {
  @include createTriangleArrow($_side: 'left', $_offset: '100% - 30px');
}

.c-community-map__popover--arrow-right-up {
  @include createTriangleArrow($_side: 'right', $_offset: 18px);
}

.c-community-map__popover--arrow-right-down {
  @include createTriangleArrow($_side: 'right', $_offset: '100% - 30px');
}

.c-community-map__popover-title {
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('dark');
  line-height: 1em;
}

.c-community-map__popover-content {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  border-top: 2px solid getColor('grey-lightest');
  padding-top: 12px;
}

.c-community-map__popover-text {
  font-size: 12px;
  font-weight: 300;
  color: getTextColor('grey');
}

// ==========================================================================
// Grid meter
// ==========================================================================

.c-community-map__grid-meter {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.c-community-map__grid-meter-content {
  padding-left: 30px;
}

.c-community-map__grid-meter-icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  transition: all 0.5s ease;
}

.c-community-map__grid-meter-icon--feed-in {
  color: $color-light-gold;
}

.c-community-map__grid-meter-icon--purchase {
  color: getTextColor('grey');
  transform: scaleX(-1);
}

.c-community-map__grid-meter-value {
  font-size: 20px;
  font-weight: 500;
}

.c-community-map__grid-meter-value--feed-in {
  color: $color-light-gold;
}

.c-community-map__grid-meter-value--purchase {
  color: getTextColor('grey');
}

.c-community-map__grid-meter-unit {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 300;
  color: getTextColor('dark');
}


.c-community-map__grid-meter-caption {
  display: block;
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: 1.4em;
}
