@charset "UTF-8";
.c-frequency-chart__axis-line {
  stroke: #EFEFF0;
  stroke-width: 2; }

.c-frequency-chart__axis-label {
  fill: #0A1535; }

.c-frequency-chart__axis-label--bold {
  font-weight: 600; }

.c-frequency-chart__plot-line {
  fill: transparent;
  stroke-width: 2.5; }

.c-frequency-chart__expected-line {
  stroke: #B4B8BF;
  stroke-width: 2; }

.c-frequency-chart__expected-line-text {
  fill: #B4B8BF; }

.c-frequency-chart__circle-circle {
  fill: #FFFFFF;
  stroke: #61687A;
  stroke-width: 2; }

.c-frequency-chart__circle-icon {
  font-family: var(--font-family-icons);
  font-size: 22px;
  text-anchor: middle;
  dominant-baseline: central; }

.c-frequency-chart__label-border {
  fill: #FFFFFF;
  stroke: #EFEFF0;
  stroke-width: 1.5; }

.c-frequency-chart__label-text {
  fill: #61687A;
  font-size: 11px;
  text-anchor: middle;
  dominant-baseline: central; }
  @media screen and (min-width: 768px) {
    .c-frequency-chart__label-text {
      font-size: 16px; } }

.c-frequency-chart__munchkins path {
  fill: #01BFE9; }

.c-frequency-chart__energy-dot {
  fill: #01BFE9; }

:export {
  iconFrequency: ""; }
