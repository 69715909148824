@import '~@sonnen/shared-web/abstracts';

.c-form-input-select {
  --select-height: 185px;
}

.c-form-input-select {
  position: relative;
  margin-bottom: var(--gap-4);
}

.c-form-input-select--no-gap {
  margin-bottom: 0;
}

.c-form-input-select--horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.c-form-input-select__label {
  display: block;
  margin: 0 0 var(--gap-1);
  font-size: 13px;
  font-weight: 500;
  color: getTextColor('grey');
}

.c-form-input-select__label--horizontal {
  margin: 0 var(--gap-1) 0 0;
}

.c-form-input-select__field--error {
  border: 1px solid $color-coral;
  color: $color-coral;
}

.c-form-input-select__error {
  position: absolute;
  padding-top: var(--gap-1);
}

.c-form-input-select__error-message {
  color: $color-coral;
  font-size: 12px;
}
