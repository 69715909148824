@import '~@sonnen/shared-web/abstracts';

.c-form-input-date {
  [type='date']::-webkit-inner-spin-button {
    display: none;
  }

  [type='date']::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.c-form-input-date__datepicker-container {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.c-form-input-date__datepicker-trigger {
  @include createIcon($_width: 20px, $_color: getTextColor('blue'));
  display: block;
  background-color: getColor('white');
  z-index: 1;
  cursor: pointer;
}

.c-form-input-date__datepicker {
  position: absolute;
  z-index: var(--z-index-dropdown);
  transform: translate(-100%, 0);

  @include breakpointUp(SM) {
    transform: translate(-75%, -50%);
  }
}

.c-form-input-date__datepicker--bottom {
  @include breakpointUp(SM) {
    transform: translate(-75%, var(--gap-3));
  }
}

// stylelint-disable sonnen/rule-no-different-bem-blocks

.c-date-picker {
  --date-picker-header-height-protected: var(--date-picker-header-height, auto);
}

.c-date-picker {
  @include boxShadow($_side: 'bottom');
  @include fadeOut();
  transition:
    visibility 200ms ease 150ms,
    opacity 200ms ease 150ms;

  &.is-active {
    @include fadeIn();
  }
}

.c-date-picker__content {
  position: relative;
  margin: 0 auto var(--gap-2);
  padding-top: var(--gap-2);
  background-color: getColor('white');
}
