@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-checkbox {
  display: block;
  margin: var(--gap-2) 0 var(--gap-3);
}

.c-guide-acceptance-checkbox__error-message {
  color: $color-coral;
  margin: var(--gap-1) 0 0;
}
