@import '~@sonnen/shared-web/abstracts';

// TODO: All types of slides should be moved to /components/slides or something.
// TODO: Investigate why max-width is not respected in .c-article-slide.
//       Something to do with tables...
// TODO: Find simpler solution for setting equal height to slides. Right now
//       component and its child have 'height: 100%' set.

.c-article-slide {
  height: 100%;
  padding: 3px;

  @include breakpointUp(SM) {
    padding-left: calc(var(--gutter-columns) / 2);
    padding-right: calc(var(--gutter-columns) / 2);
    padding-bottom: var(--gap-4);
  }
}

.c-article-slide__inner {
  @include borderRadius();
  @include boxShadow();
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  overflow: hidden;
}

.c-article-slide__image-container {
  position: relative;
  padding-bottom: percentage(425 / 770);
  border-bottom: 1px solid getColor('grey-lightest');
}

.c-article-slide__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-article-slide__content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--gap-2) var(--gutter-card) var(--gutter-card);
}

.c-article-slide__content-fixed-box {
  position: relative;
  max-height: 200px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 25%;
    background-image: linear-gradient(to top, getColor('white'), rgba(getColor('white'), 0));
  }
}

.c-article-slide__meta {
  display: flex;
  margin-bottom: var(--gap-3);
  font-size: 12px;
  font-weight: 500;
  color: getTextColor('dark');
}

.c-article-slide__title {
  margin-bottom: var(--gap-2);
  font-size: 18px;
  font-weight: 300;
  color: getTextColor('dark');
}

.c-article-slide__link {
  display: inline-block;
  margin-top: var(--gap-3);
  color: getTextColor('blue');
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}
