@import '~@sonnen/shared-web/abstracts';

.c-nested-nav-slider {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-mobile-nav);
}

.c-nested-nav-slider__slides {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}
