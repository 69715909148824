@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-documents {
  @include breakpointUp(SM) {
    width: 100%;
    padding: var(--gap-4);
  }
}

.c-guide-acceptance-documents__checkbox {
  margin-bottom: var(--gap-2);
}

.c-guide-acceptance-documents__submit {
  text-align: center;

  @include breakpointUp(SM) {
    text-align: right;
  }
}

.c-guide-acceptance-documents__error {
  margin: var(--gap-3) 0;
  text-align: center;
  color: $color-coral;
  font-size: 14px;
  font-weight: 500;
}
