@import '~@sonnen/shared-web/abstracts';

.c-download-link {
  padding-bottom: var(--gap-1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-download-link__button {
  align-self: flex-end;
}

.c-download-link__icon {
  @include createIcon(
    $_width: var(--gap-3),
    $_color: getTextColor('blue'),
  );

  &--disabled {
    @include createIcon(
      $_width: var(--gap-3),
      $_color: getTextColor('grey'),
    );
    cursor: default;
  }
}

.c-download-link__label {
  display: flex;
  width: 100%;
  font-size: 14px;
  padding-right: var(--gap-1);
}
