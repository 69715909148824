@import '~@sonnen/shared-web/abstracts';

.c-contract-flat-steps-counter {
  margin-bottom: var(--gap-2);
}

.c-contract-flat-steps-counter__attribute {
  font-weight: 300;
}

.c-contract-flat-steps-counter__value {
  font-weight: 500;
}
