@import '~@sonnen/shared-web/abstracts';

.c-infographic-first-plan__grass {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: map-get($infographic-colors, green);
}

.c-infographic-first-plan__shadow {
  isolation: isolate;
  fill: getColor('grey-darkest');
  opacity: .2;
}
