@import '~@sonnen/shared-web/abstracts';

.m-c-account-password-change {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.m-c-account-password-change__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-top: var(--gap-2);
}

.m-c-account-password-change__button {
  min-width: 130px;

  @include breakpointUp(XXS) {
    min-width: 150px;
  }

  @include breakpointUp(XS) {
    min-width: 160px;
  }
}

.m-c-account-password-change__toasts {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
