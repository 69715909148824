@import '~@sonnen/shared-web/abstracts';

.c-info-tooltip__content {
  display: none;
  font-weight: 300;
  line-height: 1.43;
  color: getTextColor('grey');
  position: absolute;
  width: 215px;
  bottom: var(--gap-2);
  padding-bottom: var(--gap-2);
  left: auto;
  right: calc(var(--gap-2) * -1);

  @include breakpointUp(SM) {
    left: var(--gap-2);
    right: auto;
    transform: translateX(-50%);
    width: 300px;
  }
}

.c-info-tooltip {
  position: relative;

  &:hover .c-info-tooltip__content {
    display: block;
  }
}

.c-info-tooltip__icon {
  @include createIcon(
    $_width: var(--gap-2),
    $_color: getTextColor('blue'),
  );
  margin-left: var(--gap-1);
}
