@import '~@sonnen/shared-web/abstracts';

.c-info-banner {
  @include boxShadow();
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 0 var(--gap-2);
  padding: var(--gap-1) var(--gap-2);
  font-size: 14px;
  overflow: hidden;
  border-radius: 3px;
}

.c-info-banner--warning {
  background-color: $color-accent-yellow-25;
  box-shadow: none;
}

.c-info-banner__button-label {
  display: none;

  @include breakpointUp(XS) {
    display: block;
  }
}

.c-info-banner__content {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.c-info-banner__border {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3px;
  background-color: getColor('blue');
}

.c-info-banner__category {
  margin: 0;
  max-width: 170px;
  font-size: 14px;
}

.c-info-banner__icon {
  @include createIcon(
    $_color: getTextColor('white'),
    $_width: 18px
  );
}

.c-info-banner__icon--warning {
  @include createIcon(
    $_color: getTextColor('white'),
    $_width: 14px
  );

  padding-bottom: 2px;
}

.c-info-banner__icon-wrapper {
  display: flex;
  justify-content: center;
  margin-right: var(--gap-1);
  width: var(--gap-3);
  height: var(--gap-3);
  border-radius: 50%;
  background-color: getColor('blue');
}

.c-info-banner__icon-wrapper--warning {
  background-color: darken($color-light-gold, 15%);
}

.c-info-banner__link-button {
  flex: 0 1 auto;
  flex-direction: row;
  align-items: center;
  padding: 14px var(--gap-2);
  margin: -8px calc(-1 * var(--gap-2));

  @include breakpointUp(XS) {
    padding: 0;
    margin: 2px 0 0 var(--gap-4);
  }
}

.c-info-banner__messages-container {
  display: flex;
  flex-direction: column;
  margin-top: 4px;

  @include breakpointUp(XS) {
    flex-direction: row;
  }
}

.c-info-banner__message {
  font-size: 14px;
  font-weight: 500;
}

.c-info-banner__additional-message {
  margin: var(--gap-1) 0 0 0;
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('grey');

  @include breakpointUp(XS) {
    margin: 0 0 0 var(--gap-1);
  }
}

.c-info-banner__description {
  margin-top: var(--gap-2);
}

.c-info-banner__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}

.c-info-banner__arrow-icon {
  @include createIcon(
    $_color: getTextColor('blue'),
    $_width: 12px,
  );
  display: inline-block;
  margin-left: var(--gap-1);
  transform: rotate(-90deg);
  transition: transform 250ms ease;
}

.c-info-banner__arrow-icon--rotated {
  transform: rotate(90deg);
}
