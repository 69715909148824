@import '~@sonnen/shared-web/abstracts';

.c-check-list-item {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-3);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.c-check-list-item__title {
  @include typographyLabel($_type: 'light');
  margin-bottom: 0;
}

.c-check-list-item__icon {
  @include createIcon(
    $_color: getTextColor('blue'),
    $_width: 14px
  );
  flex: 0 0 14px;
  margin-right: var(--gap-1);
}
