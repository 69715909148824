@import '~@sonnen/shared-web/abstracts';

.c-guide-next-step-button__title {
  font-size: 12px;
  white-space: pre;
  text-align: center;
  color: getTextColor('grey');
  margin-bottom: var(--gap-2);
}

.c-guide-next-step-button__label {
  display: flex;
  justify-content: center;
}
