@import '~@sonnen/shared-web/abstracts';

.c-key-value-row {
  --key-value-row-value-white-space: nowrap;
  --key-value-row-value-text-align: right;
}

.c-key-value-row {
  margin-bottom: var(--gap-2);
  font-size: 14px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include breakpointUp(XXS) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}

.c-key-value-row--dark {
  color: getTextColor('dark');
}

.c-key-value-row--light {
  color: getTextColor('grey');
}

.c-key-value-row--vertical {
  --key-value-row-value-white-space: initial;
  --key-value-row-value-text-align: initial;
  flex-direction: column;
  align-items: flex-start;
}

.c-key-value-row--horizontal {
  flex-direction: row;
}

.c-key-value-row__title-wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0;

  @include breakpointUp(XXS) {
    flex: 1 1;
    padding-right: var(--gap-2);
  }
}

.c-key-value-row__title {
  @include typographyLabel($_type: 'light');
}

.c-key-value-row__title--bold {
  @include typographyLabel($_type: 'bold');
}

.c-key-value-row__title--detailed {
  margin-right: var(--gap-1);
}

.c-key-value-row__tooltip {
  position: relative;

  &:hover {
    --key-value-row-tooltip-content-visibility: visible;
    --key-value-row-tooltip-content-opacity: 1;
  }
}

.c-key-value-row__tooltip-content {
  width: 150px;
  padding-bottom: var(--gap-2);
  position: absolute;
  top: var(--gap-4);
  left: calc(var(--gap-1) + 2px);
  transform: translateX(-50%);
  z-index: var(--z-index-popup);
  color: getTextColor('dark');
  line-height: 1.43;
  visibility: var(--key-value-row-tooltip-content-visibility, hidden);
  opacity: var(--key-value-row-tooltip-content-opacity, 0);
  transition:
    opacity 200ms ease 100ms,
    visibility 200ms ease 100ms;

  @include breakpointUp(XXS) {
    width: 250px;
  }
}

.c-key-value-row__tooltip-icon {
  cursor: pointer;

  @include createIcon(
    $_width: 20px,
    $_color: getTextColor('blue'),
  );
}

.c-key-value-row__tooltip-paragraph:last-of-type {
  margin: 0;
}

.c-key-value-row__value {
  @include typographyLabel();
  margin-bottom: 0;
  white-space: var(--key-value-row-value-white-space);

  @include breakpointUp(XXS) {
    flex: 0 1;
    text-align: var(--key-value-row-value-text-align);
  }
}

.c-key-value-row__value--bold {
  @include typographyLabel($_type: 'bold');
}
