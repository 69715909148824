@import '~@sonnen/shared-web/abstracts';

.c-contract-termination {
  margin-top: var(--gap-3);
}

.c-contract-termination__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include breakpointUp(SM) {
    flex-direction: row;
    align-items: center;
  }
}

.c-contract-termination__button {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: var(--gap-2);
  white-space: nowrap;

  @include breakpointUp(SM) {
    margin-top: 0;
  }
}
