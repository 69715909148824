@import '~@sonnen/shared-web/abstracts';

.c-contract-list-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-contract-list-row__item {
  display: flex;
}

.c-contract-list-row__item:first-child {
  flex: 4;
}

.c-contract-list-row__item:nth-child(2) {
  flex: 3;
}
