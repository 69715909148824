@import '~@sonnen/shared-web/abstracts';

.c-infographic-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.c-infographic-card__nav-item {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  min-width: var(--gutter-card);
  width: auto;

  @include breakpointUp(SM) {
    top: initial;
    display: inline-block;
  }
}

.c-infographic-card__nav-item--prev {
  left: calc(var(--gutter-card) * -1);

  @include breakpointUp(SM) {
    left: 0;
  }
}

.c-infographic-card__nav-item--next {
  right: calc(var(--gutter-card) * -1);

  @include breakpointUp(SM) {
    right: 0;
  }
}
