@import '~@sonnen/shared-web/abstracts';

.c-form-input {
  --input-font-size: 13px;
  --input-text-align: left;

  position: relative;
  margin-bottom: var(--gap-4);
}

.c-form-input--no-gap {
  margin-bottom: 0;
}

.c-form-input--horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.c-form-input--disabled {
  --form-input-cursor: not-allowed;
}

.c-form-input__container {
  position: relative;
  display: flex;
  align-items: center;
}

.c-form-input__label {
  display: block;
  margin: 0 0 var(--gap-1);
  font-size: 13px;
  font-weight: 500;
  color: getTextColor('grey');
}

.c-form-input__label--horizontal {
  display: block;
  margin: 0 var(--gap-1) 0 0;
  font-size: 13px;
  font-weight: 500;
  color: getTextColor('grey');
}

.c-form-input__unit-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
}

.c-form-input__unit {
  padding-right: var(--gap-2);
  padding-left: var(--gap-2);
  color: getTextColor('grey');
  text-align: right;
}

.c-form-input__field {
  @include borderRadius();
  flex: 1 0 auto;
  height: var(--gap-5);
  padding: 12px 15px;
  font-family: var(--font-family);
  font-size: var(--input-font-size);
  font-weight: 300;
  line-height: 1.4;
  color: getTextColor('dark');
  border: 1px solid getColor('grey-lightest');
  background-color: getColor('white');
  box-shadow: none;
  text-align: var(--input-text-align);
  cursor: var(--form-input-cursor, text);

  &::placeholder {
    color: getTextColor('grey');
    font-weight: 300;
  }
}

.c-form-input__field:focus {
  border: 1px solid getColor('blue');
}

.c-form-input__field--pointer {
  cursor: var(--form-input-cursor, pointer);
}

// stylelint-disable property-no-vendor-prefix

.c-form-input__field[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.c-form-input__field[type='date'],
.c-form-input__field[type='time'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;

  &::-ms-clear,
  &::-webkit-clear-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

// stylelint-enable

.c-form-input__field--error {
  border-color: $color-coral;
  color: $color-coral;
}

.c-form-input__error {
  position: absolute;
  padding-top: var(--gap-1);
}

.c-form-input__error-message {
  color: $color-coral;
  font-size: 12px;
}
