@import '~@sonnen/shared-web/abstracts';

.c-auth-transition-gate {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
}

.c-auth-transition-gate__logo {
  position: relative;
  top: -10px;
  width: 130px;
  height: 130px;
  background-image: url('../../../../images/logo_sonnen_vertical.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.c-auth-transition-gate__loader {
  position: absolute;
  margin-top: 115px;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
}
