@import '~@sonnen/shared-web/abstracts';
@import './Form.helper';

.c-form {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.c-form__alert {
  margin-bottom: 0;
  font-size: 12px;
  color: $color-coral;
}

.c-form__center {
  text-align: center;
}

.c-form__cols {
  display: flex;
  align-items: flex-start;
  margin: 0 calc(var(--gap-1) * -1);
}

.c-form__col-narrow {
  width: percentage(1/3);
  padding: 0 var(--gap-1);
}

.c-form__col-wide {
  width: percentage(2/3);
  padding: 0 var(--gap-1);
}

.c-form__group {
  margin-bottom: var(--gap-5);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.c-form__item {
  position: relative;
  display: flex;
  padding: $_input-padding 0;

  > * {
    flex: 1 0;
  }
}

.c-form__item-suffix {
  flex: 0 1;
  padding-left: var(--gap-1);
  font-size: 13px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: $_input-height;
}

.c-form__item-info {
  position: absolute;
  bottom: $_input-padding;
  left: 0;
  padding-top: 6px;
  transform: translate(0, 100%);
}

.c-form__input {
  @include formElementTheme();
}

.c-form__input--disabled {
  @include disableField;
}

// ==========================================================================
// Selects
// ==========================================================================

.c-form__select {
  @include formElementTheme();
}

.c-form__select--disabled {
  @include disableField;

  // TODO: Needs a refactor for Select components
  .Select-control {
    cursor: not-allowed !important;
  }
}

// ==========================================================================
// Labels
// ==========================================================================

.c-form__label {
  @include formLabelTheme();
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  margin: 0;
}

.c-form__label--floating {
  font-size: 18px;
  font-weight: 300;
  color: getTextColor('grey');
  line-height: $_input-height;
  transform: translate(0, $_input-padding);
  transition: all 0.25s ease;
  pointer-events: none;

  &.is-active {
    @include formLabelTheme();
    transform: translate(0, 0);
  }
}

.c-form__label--static {
  position: static;
}
