@import '~@sonnen/shared-web/abstracts';

$_container-desktop-height: 375px;

.c-infographic-painting {
  position: relative;
  display: block;
  flex: 1 1 100%;
  overflow: hidden;

  @include breakpointUp(SM) {
    @include borderLeftRadius('big');
    height: $_container-desktop-height;
  }
}

.c-infographic-painting__svg {
  width: 100%;

  @include breakpointUp(SM) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.c-infographic-painting__plan {
  transition: transform 300ms ease;
}

.c-infographic-painting__plan--first {
  @include breakpointDown(XS) {
    transform: scale(1.1) translate(-50px, -20px);
  }
}

.c-infographic-painting__plan--second {
  @include breakpointDown(XS) {
    transform: scale(1.1) translate(0, -50px);
  }
}
