@import '~@sonnen/shared-web/abstracts';

.c-guide-purchase-product {
  display: flex;
  line-height: 20px;
}

.c-guide-purchase-product__icon {
  @include createIcon(
    $_width: var(--gap-3),
    $_height: var(--gap-3),
    $_color: getTextColor('grey'),
  );
  flex-basis: var(--gap-3);
  margin-right: var(--gap-1);
}

.c-guide-purchase-product__title {
  margin-right: var(--gap-1);
  font-weight: 500;
  font-size: 14px;
  color: getTextColor('grey');
}

.c-guide-purchase-product__subtitle {
  flex-grow: 1;
  color: getTextColor('grey');
}
