@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-flat-overview__paragraph {
  margin-bottom: var(--gap-5);
  color: getTextColor('dark');
  font-size: 14px;
  font-weight: 300;

  @include breakpointUp(SM) {
    font-size: 18px;
  }
}

.c-guide-acceptance-flat-overview__title {
  margin-bottom: 10px;
  font-weight: 500;
}

.c-guide-acceptance-flat-overview__offer-icon {
  @include createIcon(
    $_height: 20px,
    $_color: getTextColor('blue')
  );
  margin-bottom: 10px;
}

.c-guide-acceptance-flat-overview__offer-name {
  margin-left: 5px;
  color: getTextColor('grey');
  font-size: 12px;
  font-weight: 500;
}

.c-guide-acceptance-flat-overview__offer-name-wrapper {
  display: flex;
  align-items: center;
}
