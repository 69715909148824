@import '~@sonnen/shared-web/abstracts';

.c-configuration-line-row {
  --key-value-row-value-white-space: nowrap;
  --key-value-row-value-text-align: right;
}

.c-configuration-line-row {
  margin-bottom: var(--gap-1);
  font-size: 14px;
  display: flex;
  flex-direction: column;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include breakpointUp(XS) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__title {
    @include typographyLabel($_type: 'bold');

    @include breakpointUp(XS) {
      margin-right: 16px;
    }

    // margin bottom quick fix to disable 8px set in root for all paragraphs in shared-web
    &-helper {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $grey_500;
      margin-bottom: 0;
    }
  }

  &__value {
    @include typographyLabel($_type: 'bold');
    margin-bottom: 0;
    white-space: var(--key-value-row-value-white-space);
  }
}
