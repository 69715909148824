@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-stepper {
  // @NOTE: Should this width be universal and moved to `<Layout />`?
  max-width: 1050px;
  margin: 0 auto;
}

// @TODO: Refactor shared <Wizard /> so that it does not overflow content
.c-guide-acceptance-stepper__header {
  margin: 0 0 var(--gap-5);
  padding-left: var(--gap-6);
  padding-right: calc(var(--gap-6) + 20px);
  overflow: hidden;
}
