@import '~@sonnen/shared-web/abstracts';

// stylelint-disable color-no-hex

$_color-status-open-icon: #0a1535;
$_status-background-colors: (
  active: #8dba3a,
  blocked: #e8201d,
  canceled: #e8201d,
  done: #8dba3a,
  open: #f1f1f3,
  pending: #ffac1e,
  terminationpending: #ffac1e,
);

// stylelint-enable color-no-hex

.c-contract-flat-x-card {
  --gutter-card: var(--gap-2);
  position: relative;
  z-index: 0;

  width: 100%;
}

.c-contract-flat-x-card__card-wrapper {
  position: relative;
  z-index: 2;
}

.c-contract-flat-x-card__card-inner {
  position: relative;
  min-height: 200px;
}

.c-contract-flat-x-card__header {
  display: flex;
  justify-content: space-between;
}

.c-contract-flat-x-card__headline {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: var(--gap-1) 0;
}

.c-contract-flat-x-card__status {
  color: getTextColor('white');
  padding: 2px 8px;
  border-radius: 10px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

@each $_status, $_background-color in $_status-background-colors {
  .c-contract-flat-x-card__status--#{$_status} {
    background: $_background-color;
  }

  .c-contract-flat-x-card__icon--#{$_status} {
    --ds-icon-color: #{$_background-color};
  }
}

.c-contract-flat-x-card__status--open {
  color: getTextColor('dark');
}

.c-contract-flat-x-card__icon--open {
  --ds-icon-color: #{$_color-status-open-icon};
}

.c-contract-flat-x-card__info-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: var(--gap-1);
  }
}

.c-contract-flat-x-card__info-attribute {
  font-size: 14px;
}

.c-contract-flat-x-card__info-value {
  font-size: 14px;
  font-weight: 500;
}

.c-contract-flat-x-card__paragraph {
  font-size: 14px;
  margin-top: var(--gap-4);
  margin-bottom: var(--gap-4);
}

.c-contract-flat-x-card__collapse-button-wrapper {
  position: absolute;
  bottom: var(--gap-2);
  left: 50%;
  transform: translateX(-50%);
}

.c-contract-flat-x-card__collapse-button {
  flex-grow: unset;
}

.c-contract-flat-x-card__collapse-button-icon {
  @include createIcon($_color: getTextColor('blue'));
  transform: rotate(-90deg);
  transition: transform 300ms ease 200ms;

  &.is-rotated {
    transform: rotate(90deg);
  }
}

.c-contract-flat-x-card__content-wrapper {
  height: var(--c-contract-flat-x-card-content-height);
  transition: height 300ms ease 200ms;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 0 3px;
}

.c-contract-flat-x-card__content {
  @include borderRadius('big');
  @include boxShadow();

  padding: var(--gap-2);
  padding-top: calc(var(--gap-2) + 5px);
  background: getColor('grey-lightest');
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 14px;
  line-height: 1.43;
  margin-top: -5px;
}

// selector-max-types are disabled for this section because a and ul elements
// cames from translation and we don't want to add class names to POEditor
// stylelint-disable-next-line selector-max-type
.c-contract-flat-x-card__content a {
  font-size: var(--link-button-font-size);
  font-weight: 500;
  color: getTextColor('blue');
  line-height: 1.2;

  // stylelint-disable-next-line selector-max-type
  &:hover {
    color: getTextColor('blue');
  }
}

// stylelint-disable-next-line selector-max-type
.c-contract-flat-x-card__content ul {
  padding: 0 var(--gap-3) var(--gap-3);
  list-style: disc;
}
