// ==========================================================================
// *1. Gap between two adjacent powerlines.
// *2. Number of dots on given powerline.
// ==========================================================================

$_chart-circle-size: 70px;
$_chart-circle-size--desktop: 140px;
$_chart-horizontal-line-size: 160px;
$_chart-horizontal-line-size--desktop: 426px;
$_chart-vertical-line-size: 210px;
$_chart-vertical-line-size--desktop: 250px;
$_chart-line-gap: 12px; // 1
$_chart-dot-count: 3; // 2

@mixin getCircleGridImage() {
  .c-ef-circle__image {
    min-height: 38px;
    width: auto;
    transition: 1200ms ease;
    will-change: transform;
    @content;

    .is-animated & {
      transform: translate(0, 0);
    }
  }

  @include breakpointUp(SM) {
    min-height: 84px;
  }
}
