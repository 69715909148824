@import '~@sonnen/shared-web/abstracts';

// @TODO: Make a mixin for drop-shadow, that uses the same
//        shadow as boxShadow() mixin
$_image-shadow: 0 1px 4px rgba(0, 0, 0, .15);
$_counter: image-tutorial-counter;
$_counter-size: var(--gap-3);

.c-image-tutorial {
  margin: 0;
  padding: 0;
  list-style-type: none;
  counter-reset: $_counter;
}

.c-image-tutorial__item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: var(--gap-3);

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 $_counter-size;
    width: $_counter-size;
    height: $_counter-size;
    margin-right: var(--gap-2);
    border: 1.5px solid currentColor;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 500;
    color: getTextColor('blue');
    line-height: 1em;
    content: counter($_counter);
    counter-increment: $_counter;
  }
}

.c-image-tutorial__figure {
  flex: 1 0;
  margin: 0; // @TODO: Reset <figure> styles globally in shared-web
}

.c-image-tutorial__title {
  $_line-height: 20px;

  margin-top: calc((#{$_counter-size} - #{$_line-height}) * .5);
  margin-bottom: var(--gap-2);
  font-size: 14px;
  color: getTextColor('dark');
  line-height: $_line-height;
}

.c-image-tutorial__image {
  @supports (filter: drop-shadow($_image-shadow)) {
    filter: drop-shadow($_image-shadow);
  }

  @supports not (filter: drop-shadow($_image-shadow)) {
    @include boxShadow();
  }
}
