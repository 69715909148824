@import '~@sonnen/shared-web/abstracts';

@mixin addMobileGaps($_negative: false) {
  $_gap: var(--gutter-layout);

  @if $_negative {
    margin-left: calc(#{$_gap} * -1);
    margin-right: calc(#{$_gap} * -1);
  } @else {
    padding-left: $_gap;
    padding-right: $_gap;
  }

  @include breakpointUp(SM) {
    @if $_negative {
      margin-left: 0;
      margin-right: 0;
    } @else {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.c-guide-discovery-active__subheadline {
  margin-bottom: var(--gap-5);
}

.c-guide-discovery-active__description {
  margin: var(--gap-3) 0;
}

.c-guide-discovery-active__tabs {
  @include addMobileGaps($_negative: true);
  overflow: hidden;

  @include breakpointUp(SM) {
    overflow: initial;
  }
}

.c-guide-discovery-active__tab {
  @include addMobileGaps();
}
