// ==========================================================================
// *1. Fix for truncated text inside flexbox elements:
//     https://css-tricks.com/flexbox-truncated-text/
// ==========================================================================

@import '~@sonnen/shared-web/abstracts';

.c-guide-accordion {
  --accordion-vertical-gap: var(--gap-3);
  position: relative;
}

.c-guide-accordion__detached-side {
  position: absolute;
  top: calc(var(--accordion-vertical-gap) + 1px);
  left: 0;
}

.c-guide-accordion__header-wrapper {
  padding: var(--accordion-vertical-gap) 0;
}

.c-guide-accordion__header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.c-guide-accordion__header-content {
  flex: 1 0;
  min-width: 0; // 1
}

.c-guide-accordion__header-content--spaced-left {
  padding-left: var(--gap-4);
}

.c-guide-accordion__main {
  border-bottom: 1px solid getColor('grey-lightest');
  padding-bottom: var(--gap-3);
}

.c-guide-accordion__content {
  padding-top: 3px;
}

.c-guide-accordion__title {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('dark');
  line-height: var(--gap-2);
}

.c-guide-accordion__details {
  margin-top: var(--gap-1);
}
