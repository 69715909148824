@import '~@sonnen/shared-web/abstracts';

.c-battery-card-grid {
  @include breakpointUp(XXS) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 calc(var(--gap-2) * -1);
  }
}

.c-battery-card-grid__item {
  @include breakpointUp(XXS) {
    flex: 0 0 50%;
    padding: 0 var(--gap-2);
  }
}

.c-battery-card-grid__item--side {
  @include breakpointDown(XS) {
    flex-basis: 110px;
    margin: 0 auto;
  }
}
