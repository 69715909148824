@import '~@sonnen/shared-web/abstracts';
@import '../../Form.helper';

.c-textarea {
  @include formElementTheme();
  @include borderRadius();
  height: calc(var(--gap) * 12px);
  border-width: 1px;
  padding: 12px var(--gap-2);
  transition: height .25s ease .1s;
  resize: none;

  &.is-active {
    border-color: $_input-border-color;
  }
}
