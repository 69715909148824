@import '~@sonnen/shared-web/abstracts';

.c-footer {
  --footer-font-color: #{getTextColor('grey')};
  --footer-nav-font-color: var(--footer-font-color);
  --language-select-font-color: var(--footer-font-color);
}

.c-footer {
  display: flex;
  align-items: center;
  border-top: 1px solid getColor('grey-lightest');
  background: getColor('grey-lightest');

  @include breakpointUp(SM) {
    height: var(--footer-height);
  }
}

.c-footer--onboard-page {
  position: relative;

  @include breakpointUp(SM) {
    --footer-font-color: #{getTextColor('white')};

    height: 100px;
    border-top: 0;
    background: transparent;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background: linear-gradient(to top, rgba(getColor('grey-darkest'), .85), transparent);
    }
  }
}

.c-footer__content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: var(--gap-4) 0;

  @include breakpointUp(SM) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.c-footer__social {
  margin-bottom: var(--gap-3);

  @include breakpointUp(SM) {
    margin-bottom: 0;
  }
}

.c-footer__nav {
  margin-bottom: var(--gap-3);

  @include breakpointUp(SM) {
    margin: 0 var(--gap-3) 0 auto;
  }
}

.c-footer__cpr {
  margin-bottom: 0;
  font-size: 12px;
  color: var(--footer-font-color);

  @include breakpointUp(SM) {
    margin-right: var(--gap-3);
    white-space: nowrap;
  }
}
