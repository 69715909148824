@import '~@sonnen/shared-web/abstracts';

.c-infographic-wrapper {
  @include boxShadow();
  @include createTriangleArrow($_side: 'bottom', $_offset: '50% - 11px', $_size: 22px);
  position: relative;
  margin: 0 calc(var(--gutter-layout) * -1) var(--gap-4);

  @include breakpointUp(XS) {
    @include borderRadius('big');
    margin-left: 0;
    margin-right: 0;
  }

  @include breakpointUp(SM) {
    @include createTriangleArrow(
      $_side: 'bottom',
      $_offset: '50% - 11px',
      $_color: map-get($infographic-colors, green),
      $_size: 22px
    );
    display: flex;
  }
}

.c-infographic-wrapper__painting-cover {
  display: none;
}

.c-infographic-wrapper__painting-cover--enabled {
  position: absolute;
  z-index: 2;
  display: block;
  width: 100%;
  height: calc(100% + 22px);
  background-color: rgba(getColor('white'), .5);
}

.c-infographic-wrapper__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding: var(--gutter-card);
  background: getColor('white');
  z-index: 3;

  @include breakpointUp(XS) {
    @include borderBottomRadius('big');
  }

  @include breakpointUp(SM) {
    @include borderRadius('big');
    @include boxShadow();
    width: 315px;
    min-width: 315px;
    min-height: 350px;
    margin: -2px -2px -2px 0;
    padding-bottom: var(--gap-4);
  }
}
