@import '~@sonnen/shared-web/abstracts';

.c-default-nav-tag {
  margin-left: var(--gap-2);
  padding: 2px 10px;
  font-size: 12px;
  background-color: getColor('blue');
  color: getTextColor('white');
  border-radius: 3px;
}
