@import '~@sonnen/shared-web/abstracts';

.c-account-community-profile-form__card {
  max-width: 280px;
  margin: 0 auto var(--gutter-section);

  @include breakpointUp(SM) {
    position: sticky;
    top: var(--content-gutter-top);
    margin-bottom: 0;
  }
}

.c-account-community-profile-form__card-title {
  @include typographySectionTitle();
}
