@import '~@sonnen/shared-web/abstracts';

.c-guide-vat-info {
  line-height: 1em;
}

.c-guide-vat-info__icon {
  @include createIcon(
    $_height: 20px,
    $_color: getTextColor('grey')
  );
  padding-right: var(--gap-1);
}

.c-guide-vat-info__content {
  margin-bottom: 0;
  font-size: 14px;
  color: getTextColor('grey');
  line-height: 1.5em;
}
