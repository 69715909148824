@import '~@sonnen/shared-web/abstracts';

.c-table-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.c-table-list__item {
  position: relative;
  border-bottom: 1px solid getColor('grey-lightest');
  padding: var(--gap-1) 0;

  &:first-of-type {
    padding-top: 0;
  }

  @include breakpointUp(XXS) {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }

  @include breakpointUp(SM) {
    padding: var(--gap-2) 0;
  }
}

.c-table-list__title {
  @include typographyLabel($_type: 'light');
  flex: 1 0 50%;
  margin-bottom: 0;
  padding-right: var(--gap-1);
}

.c-table-list__value {
  @include typographyLabel($_type: 'default');
  flex: 0 1 50%;
  margin-bottom: 0;
  word-break: break-all;
}
