@import '~@sonnen/shared-web/abstracts';

.c-graphic-banner {
  position: relative;
}

.c-graphic-banner--outside {
  @include breakpointDown(SM) {
    --graphic-banner-image-position: relative;
    --graphic-banner-image-height: 160px;
  }
}

.c-graphic-banner__image-wrapper {
  position: var(--graphic-banner-image-position, absolute);
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  line-height: 0;
}

.c-graphic-banner__image {
  height: var(--graphic-banner-image-height, 100%);
  object-fit: cover;
}

.c-graphic-banner__content {
  position: relative;
  z-index: 2;
}
