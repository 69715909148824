@import '~@sonnen/shared-web/abstracts';

.c-guide-info-card-list {
  padding: 0 var(--gap-2);
}

.c-guide-info-card-list__item {
  margin-bottom: var(--gap-1);
  list-style: disc;
}
