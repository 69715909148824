@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-personal-data-table {
  padding-top: var(--gap-3);
  padding-bottom: var(--gap-3);

  @include breakpointUp(SM) {
    display: flex;
    flex-flow: column wrap;
    padding-top: var(--gap-6);
    padding-bottom: var(--gap-6);
  }
}

.c-guide-acceptance-personal-data-table__body {
  @include breakpointUp(SM) {
    display: flex;
    flex-flow: column wrap;
    max-height: 210px;
  }
}

.c-guide-acceptance-personal-data-table__key {
  @include typographyLabel($_type: 'light');
  padding-right: var(--gap-3);
  min-width: 125px;
  vertical-align: text-top;
  font-size: 12px;

  @include breakpointUp(SM) {
    min-width: 150px;
  }
}

.c-guide-acceptance-personal-data-table__value {
  line-height: 2;
  font-weight: 500;
  color: getTextColor('dark');
}

.c-guide-acceptance-personal-data-table__row {
  @include breakpointUp(SM) {
    min-width: 50%;
  }
}

.c-guide-acceptance-personal-data-table__warning {
  margin-bottom: var(--gap-3);

  @include breakpointUp(SM) {
    margin-left: 50%;
    margin-top: calc(var(--gap-3) * -1);
    margin-bottom: 0;
  }
}
