@import '~@sonnen/shared-web/abstracts';

.c-battery-update-history__grid {
  display: flex;
}

.c-battery-update-history__grid-column {
  flex: 0 0 50%;
}

.c-battery-update-history__divider {
  width: 100%;
  height: 1px;
  margin: var(--gap-1) 0;
  background-color: getColor('grey-lightest');
}
