// ==========================================================================
// *1. max-width ensures that `text-overflow: ellipsis` will work
//     in subtitle.
// ==========================================================================

@import '~@sonnen/shared-web/abstracts';

@mixin ellipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.c-user-badge {
  --user-badge-icon-size: 60px;
}

.c-user-badge {
  @include dropdownItem();
  max-width: 340px;
  padding-top: var(--gap-3);
  padding-bottom: var(--gap-3);
  color: getTextColor('dark');
}

.c-user-badge__inner {
  display: flex;
  align-items: flex-start;
}

.c-user-badge__side {
  align-self: center;
  line-height: 1em;
}

.c-user-badge__content {
  flex: 1 1;
  align-self: center;
  margin-left: var(--gap-2);
  max-width: calc(100% - var(--gap-2) - var(--user-badge-icon-size)); // 1
}

.c-user-badge__image {
  width: var(--user-badge-icon-size);
  height: var(--user-badge-icon-size);
  border-radius: 50%;
}

.c-user-badge__icon {
  @include createIcon(
    $_color: getTextColor('dark'),
    $_width: var(--user-badge-icon-size)
  );
}

.c-user-badge__title {
  @include typographyLabel($_type: 'bold');
  @include ellipsis();
}

.c-user-badge__subtitle {
  @include typographyLabel($_type: 'light');
  @include ellipsis();
}
