@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-select {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: var(--gap-2);
}

.c-guide-acceptance-select__trigger {
  @include borderRadius();
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1 0 100%;
  width: 100%;
  height: var(--gap-4);
  border: 1px solid getColor('grey-lightest');
  padding: 5px 0 5px 15px;
  background-color: getColor('white');
  color: getTextColor('dark');
  font-weight: 500;
}

.c-guide-acceptance-select__trigger-content {
  display: inline-flex;
  align-content: center;
  align-items: center;
  width: calc(100% - 32px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-guide-acceptance-select__trigger-icon {
  margin-top: 4px;
  transition: transform var(--transition-leave-ms) ease-in-out;

  @include createIcon(
    $_color: getTextColor('blue'),
    $_width: 12px,
    $_height: 12px,
  );
}

.c-guide-acceptance-select {
  --guide-acceptance-select-icon-angle: -90deg;

  &.is-active {
    --guide-acceptance-select-icon-angle: 90deg;
  }
}

.c-guide-acceptance-select__trigger-icon {
  transform: rotate(var(--guide-acceptance-select-icon-angle));
}

.c-guide-acceptance-select__list {
  @include borderRadius();
  @include boxShadow($_side: 'bottom');
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  max-height: var(--height);
  margin: 0;
  border: 1px solid getColor('grey-lightest');
  padding: 15px;
  background-color: getColor('white');
  opacity: 0;
  list-style: none;
  overflow-y: scroll;
  transform: scaleY(0);
  transform-origin: center top;
  z-index: var(--z-index-dropdown);
  -webkit-overflow-scrolling: touch;

  .is-enter > &,
  .is-enter-done > & {
    opacity: 1;
    transform: scaleY(1);
    transition:
      opacity var(--transition-enter-ms) ease-in-out,
      transform var(--transition-enter-ms) ease-in-out;
  }

  .is-exit > &,
  .is-exit-done > & {
    opacity: 0;
    transform: scaleY(0);
    transition:
      opacity var(--transition-leave-ms) ease-in-out,
      transform var(--transition-leave-ms) ease-in-out;
  }
}

.c-guide-acceptance-select__list-item {
  position: relative;
  font-weight: 500;
  padding: 0 0 var(--gap-2);
  color: getTextColor('grey');
  counter-increment: select-counter;
  display: flex;
  flex: 1 0 auto;
  min-height: 14px;
  transition: color 400ms ease-in-out;

  &:last-of-type {
    padding: 0;
  }

  &:hover {
    color: getTextColor('blue');
  }
}

.c-guide-acceptance-select__link {
  width: 100%;
}

.c-guide-acceptance-select__list-item--selected {
  position: relative;
  color: getTextColor('blue');
}
