@import '~@sonnen/shared-web/abstracts';
@import 'SiteSwitcher.helper';

.c-site-switcher {
  position: relative;
}

// @TODO: Rethink height and offsets. May not work on Safari or outside navigation.
.c-site-switcher__list-wrapper {
  position: fixed;
  z-index: 2;
  top: var(--header-height);
  right: 0;
  width: 100%;
  height: calc(100vh - var(--header-height));
  min-height: calc(100vh - var(--header-height));
  border-left: 1px solid getColor('grey-lightest');
  background: getColor('white');
  transition:
    transform $_transition,
    box-shadow $_transition;
  transform: translate(100%, 0);
  overflow-y: scroll;

  &.is-open {
    transform: translate(0, 0);
  }
}
