@import '~@sonnen/shared-web/abstracts';

.c-contract-notification-paragraph {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.c-contract-notification-paragraph--theme-card {
  @include boxShadow();
  @include borderRadius();
  padding: var(--gap-1) var(--gap-2);
}

.c-contract-notification-paragraph__icon {
  @include createIcon(
    $_width: var(--gap-2),
    $_color: getTextColor('grey')
  );
  flex: 0 0 var(--gap-2);
  margin-right: var(--gap-1);
  line-height: 0;
}

.c-contract-notification-paragraph__text {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: var(--gap-2);
}
