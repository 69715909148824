@import '~@sonnen/shared-web/abstracts';

.c-contract-flat-home-overview {
  --page-headline-gutter: var(--gutter-headline-small);
}

.c-contract-flat-home-overview__banner {
  display: flex;
  justify-content: center;
  margin: var(--gutter-section) 0;

  @include breakpointUp(SM) {
    margin: 60px 0;
  }
}
