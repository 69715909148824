@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-confirmation-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.c-guide-acceptance-confirmation-modal__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-guide-acceptance-confirmation-modal__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--gap-8) var(--gap-8) var(--gap-6);
}

.c-guide-acceptance-confirmation-modal__footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--gap-2) var(--gap-3);
  border-top: 1px solid getColor('grey-lightest');

  @include breakpointUp(XS) {
    flex-direction: row;
  }
}

.c-guide-acceptance-confirmation-modal__title {
  margin-bottom: var(--gap-2);
  font-size: 20px;
  text-align: center;
}

.c-guide-acceptance-confirmation-modal__description {
  font-size: 14px;
  color: getTextColor('grey');
  text-align: center;
}

.c-guide-acceptance-confirmation-modal__button {
  margin-bottom: var(--gap-2);

  @include breakpointUp(XS) {
    margin-bottom: 0;
  }
}
