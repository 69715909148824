@import '~@sonnen/shared-web/abstracts';

.c-demo-analysis-history-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.c-demo-analysis-history-header__date {
  margin-left: var(--gap-3);
  font-size: 12px;
  font-weight: 500;
  line-height: 1em;
  color: getTextColor('dark');
}

.c-demo-analysis-history-header__date-label {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  color: getTextColor('grey');
}
