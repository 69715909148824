@import '~@sonnen/shared-web/abstracts';

$_transition: 350ms ease 50ms;
$_icon-size: 10px;
$_icon-gap: 2px;

.c-validation-badge {
  --password-strengh-checker-icon-offset: #{-$_icon-size};
  --password-strengh-checker-icon-opacity: 0;
  --password-strengh-checker-label-gap: #{($_icon-size + $_icon-gap) / 2};

  @include borderRadius();
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px var(--gap-1);
  font-size: 12px;
  line-height: var(--gap-2);
  font-weight: 500;
  color: getTextColor('dark');
  background-color: getColor('grey-lightest');
  transition: background-color $_transition;

  &.is-active {
    --password-strengh-checker-icon-offset: #{$_icon-gap};
    --password-strengh-checker-icon-opacity: 1;
    --password-strengh-checker-label-gap: 0;

    background-color: rgba($color-light-blue, .35); // @TODO: Use proper color without rgba function
  }
}

.c-validation-badge__icon {
  @include createIcon(
    $_width: $_icon-size,
    $_color: getTextColor('blue')
  );
  margin-right: var(--password-strengh-checker-icon-offset);
  opacity: var(--password-strengh-checker-icon-opacity);
  transition:
    opacity $_transition,
    margin $_transition;
}

.c-validation-badge__label {
  padding: 0 var(--password-strengh-checker-label-gap);
  transition: padding $_transition;
}
