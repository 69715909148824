@import '~@sonnen/shared-web/abstracts';

// TODO: Check if looks correctly.

.m-c-app-loader {
  position: relative;
  text-align: center;
}

.m-c-app-loader__loader {
  margin-bottom: var(--gap-4);
}
