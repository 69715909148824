@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-personal-details {
  display: block;
}

.c-guide-acceptance-personal-details__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpointUp(SM) {
    flex-direction: row-reverse;
    align-self: flex-end;
    margin-right: var(--gap-4);
  }
}

.c-guide-acceptance-personal-details__button {
  margin: var(--gap-1);
  width: fit-content;
}

.c-guide-acceptance-personal-details__paragraph {
  @include breakpointUp(SM) {
    font-size: 18px;
    font-weight: 300;
    color: getTextColor('dark');
  }
}

.c-guide-acceptance-personal-details__loader {
  position: relative;
  padding: var(--gap-8) 0;
}
