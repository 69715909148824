@import '~@sonnen/shared-web/abstracts';

$_icon-size: 10px;

.c-guide-stepper {
  display: flex;
  margin: var(--gap-2) var(--gap-4) var(--gap-8) var(--gap-4);
}

.c-guide-stepper__phase {
  display: flex;
  flex: 1;
  align-items: center;

  &:last-of-type {
    flex: 0;
  }
}

.c-guide-stepper__button {
  position: relative;
}

.c-guide-stepper__icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 2px solid getColor('grey-light');
  border-radius: 50%;
  background: none;
}

.c-guide-stepper__icon-wrapper--with-icon {
  border-color: getColor('blue');
  background-color: getColor('blue');
}

.c-guide-stepper__icon-wrapper--active {
  border-color: getColor('blue');
}

.c-guide-stepper__icon {
  @include createIcon(
    $_color: getTextColor('white'),
    $_width: $_icon-size,
    $_height: $_icon-size,
  );
}

.c-guide-stepper__phase-name {
  position: absolute;
  left: 50%;
  font-size: 16px;
  font-weight: 300;
  transform: translate(-50%, 50%);

  &.is-active {
    font-weight: 500;
  }
}

.c-guide-stepper__progress-bar {
  flex: 1;
  height: 2px;
  background-color: getColor('grey-light');
}

.c-guide-stepper__progress-bar--active {
  background-color: getColor('blue');
}
