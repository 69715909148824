@import '~@sonnen/shared-web/abstracts';

.c-account-form-alert {
  display: block;
}

.c-account-form-alert__button--invisible {
  @include fadeOut($_transition: null);
  transform: translate(100%, 0);
  transition:
    opacity 250ms ease,
    transform 250ms ease;
}

.c-account-form-alert__button-icon {
  width: 12px;
  margin-right: 4px;
}

.c-account-form-alert__success-icon {
  @include createIcon(
    $_width: var(--gap-3),
    $_height: var(--gap-3),
    $_color: getTextColor('white')
  );
  margin: 4px var(--gap-4);
  border: 1px solid getColor('white');
  border-radius: 50%;
  padding: 4px;
}
