@import '~@sonnen/shared-web/abstracts';

.c-footer-nav {
  --footer-nav-font-color-private: var(--footer-nav-font-color, ${getTextColor('dark')});
}

.c-footer-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;

  @include breakpointUp(SM) {
    justify-content: flex-end;
    margin: 0 -12px;
    width: initial;
  }
}

.c-footer-nav__list-item {
  display: inline-block;
  flex: 0 0 50%;
  font-size: 12px; // @TODO: Move typography to __item element
  font-weight: 500;
  color: var(--footer-nav-font-color-private);
  line-height: 17px;

  &:hover {
    --footer-nav-font-color-private: #{getTextColor('blue')};
  }

  @include breakpointUp(SM) {
    flex-basis: initial;
    margin: 0 12px; // @TODO: Move margins to __item element
  }
}

.c-footer-nav__item {
  display: block;
  padding: 6px 0;
}
