@import '~@sonnen/shared-web/abstracts';

.p-community-members {
  @include borderRadius('big');
  @include boxShadow();
  display: flex;
  width: auto;
  max-width: 450px;
  padding: var(--gap-2);
  background-color: getColor('white');

  @include breakpointUp(XXS) {
    padding: var(--gap-2) var(--gap-4);
  }
}

.p-community-members__inner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;

  @include breakpointUp(XXS) {
    justify-content: center;
  }
}

.p-community-members__icon {
  @include createIcon(
    $_color: getTextColor('grey'),
    $_width: 48px
  );
  margin-right: var(--gap-1);

  @include breakpointUp(XXS) {
    margin-right: var(--gap-3);
  }
}

.p-community-members__value {
  display: flex;
  align-items: baseline;
  width: auto;
}

.p-community-members__amount {
  margin-right: var(--gap-1);
  font-size: 46px;
  font-weight: 500;
  line-height: 1;
  color: getTextColor('turquoise');
}

.p-community-members__unit {
  display: block;
  align-self: flex-end;
  max-width: 65px;
  margin-bottom: 4px;
  font-size: 14px;
  color: getTextColor('grey');
  line-height: 1.2;
  line-height: 1;
}

.p-community-members__range {
  font-size: 13px;
  font-weight: 300;
  color: getTextColor('grey');
  line-height: 1;
}
