@import '~@sonnen/shared-web/abstracts';

.c-account-profile-card-link__button-icon {
  @include createIcon(
    $_color: getTextColor('blue'),
    $_height: 14px,
    $_width: 14px,
  );
  margin-left: 2px;
  margin-top: 2px;
  transform: rotate(180deg);
}
