@import '~@sonnen/shared-web/abstracts';

.c-analysis-summer-time-notification {
  display: flex;
  align-items: center;
  min-height: 28px;
  padding: var(--gap-2);
  background-color: $color-blue-gray-light-4;

  @include borderRadius();
}

.c-analysis-summer-time-notification__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  margin-right: var(--gap-1);
  border-radius: 50%;
  background-color: getColor('blue');
}

.c-analysis-summer-time-notification__icon {
  @include createIcon(
    $_width: 16px,
    $_color: getTextColor('white'),
  );
}

.c-analysis-summer-time-notification__text {
  font-size: 13px;
  font-weight: 500;
  color: getTextColor('dark');
  line-height: 1em;
}
