@import '~@sonnen/shared-web/abstracts';

.c-demo-analysis-history-card__content {
  position: relative;
  margin: 0 calc(var(--gutter-card) * -1) calc(var(--gutter-card) * -1);

  @include breakpointUp(SM) {
    margin: 0;
  }
}
