@import '~@sonnen/shared-web/abstracts';

.c-guide-preview-nav {
  display: flex;
  justify-content: space-between;
  margin-top: var(--gap-5);

  @include breakpointUp(MD) {
    justify-content: center;
  }
}

.c-guide-preview-nav--align-right {
  @include breakpointDown(MD) {
    justify-content: flex-end;
  }
}

.c-guide-preview-nav__button--left {
  margin-right: var(--gap-1);
}

.c-guide-preview-nav__button--right {
  margin-left: var(--gap-1);
}
