@import '~@sonnen/shared-web/abstracts';

.c-accordion-list {
  --accordion-vertical-gap: var(--gap-2);
}

.c-accordion-list__title {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('dark');
  line-height: 1.4em;
}

.c-accordion-list__text {
  white-space: pre-wrap;
}
