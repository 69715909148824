@import '~@sonnen/shared-web/abstracts';

.c-contract-flat-x-overview {
  --page-headline-gutter: var(--gutter-headline-small);
  display: flex;
  flex-direction: column;
}

.c-contract-flat-x-overview__cards {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;

  @include breakpointUp(SM) {
    flex-direction: row;
  }
}

.c-contract-flat-x-overview__contract-number {
  order: 1;
  font-size: 14px;
  margin-bottom: var(--gutter-headline);
}

.c-contract-flat-x-overview__usage-allowance {
  order: 3;
  padding-top: var(--gap-3);
  padding-bottom: var(--gap-3);

  @include breakpointDown(SM) {
    order: 2;
  }
}

.c-contract-flat-x-overview__order-status {
  order: 2;
  padding-top: var(--gap-3);
  padding-bottom: var(--gap-3);

  @include breakpointDown(SM) {
    order: 3;
  }
}
