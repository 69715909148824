@import '~@sonnen/shared-web/abstracts';

.c-panel-message {
  text-align: center;
}

.c-panel-message--bottom-gap {
  margin-bottom: var(--gap-3);
}

.c-panel-message__icon {
  @include createIcon(
    $_height: 60px,
    $_color: getTextColor('blue'),
  );
  display: inline-block;
  margin: var(--gap-3) 0;
}

.c-panel-message__bottom-content {
  margin-top: var(--gap-3);
}
