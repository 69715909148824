@import '~@sonnen/shared-web/abstracts';

.c-contract-energy-documents-list {
  @include breakpointUp(SM) {
    margin-top: var(--gap-4);
  }
}

.c-contract-energy-documents-list__header {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;
  margin-bottom: var(--gap-3);
}

.c-contract-energy-documents-list__title {
  margin: 0 var(--gap-1) 0 0;
  font-size: 18px;
  color: getTextColor('dark');
}

.c-contract-energy-documents-list__documents {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 calc(var(--gap-1) * -1) calc(var(--gap-3) * -1);
}

.c-contract-energy-documents-list__document {
  flex: 1 1 50%;
  margin-bottom: var(--gap-3);
  padding: 0 var(--gap-1);
}

.c-contract-energy-documents-list__document-link {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: getTextColor('blue');
  white-space: nowrap;

  &:hover {
    color: rgba($color-light-blue, .7);

    // stylelint-disable-next-line sonnen/rule-no-different-bem-blocks
    .c-contract-energy-documents__document-icon {
      @include createIcon(
        $_color: rgba($color-light-blue, .7),
      );
    }
  }
}

.c-contract-energy-documents-list__document-icon {
  @include createIcon(
    $_width: var(--gap-2),
    $_color: getTextColor('blue'),
  );
  margin-right: var(--gap-1);
}

.c-contract-energy-documents-list__document-info {
  margin-bottom: 0;
  padding-left: var(--gap-3);
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('grey');
  white-space: nowrap;
}
