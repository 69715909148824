@import '~@sonnen/shared-web/abstracts';

$_strength-colors: (
  0: $color-coral,
  1: $color-coral,
  2: $color-light-gold,
  3: $color-green,
  4: $color-greenish-teal,
);

.c-password-input__dropdown-content {
  @include dropdown($_top: 40px, $_left: calc(100% - 260px));
  @include createTriangleArrow($_side: 'top', $_offset: 85%);

  @include breakpointUp(SM) {
    @include createTriangleArrow($_side: 'left', $_offset: 45px);
    top: -50px;
    left: 15px;
  }
}

.c-password-input__dropdown-item {
  @include dropdownItem();
}

.c-password-input__dropdown-headline {
  @include typographySectionTitle();
}

.c-password-input__strength-check {
  @include borderRadius();
  position: relative;
  width: 100%;
  height: 4px;
  margin: var(--gap-2) 0;
  background-color: rgba(getColor('grey-darkest'), .1);

  &::before {
    @include borderRadius();
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  @for $_i from 0 to length($_strength-colors) {
    &.level-#{$_i}::before {
      width: (100% * $_i / 4);
      background-color: map-get($_strength-colors, $_i);
    }
  }
}

.c-password__icon-button {
  position: absolute;
  top: calc(50% - 10px);
  right: 0;
  outline: none;
  cursor: pointer;
}

.c-password__icon {
  @include createIcon(
    $_color: getTextColor('grey'),
    $_width: 20px
  );

  &.is-active {
    @include createIcon(
      $_color: getTextColor('blue'),
    );
  }
}
