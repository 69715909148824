@import '~@sonnen/shared-web/abstracts';

.c-community-header {
  margin-bottom: var(--gutter-headline);

  @include breakpointUp(XS) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.c-community-header__image {
  display: block;
  width: 50px;
  margin: 0 auto var(--gap-1);
  opacity: .5;

  @include breakpointUp(XS) {
    width: 130px;
    margin: 0 var(--gap-6);
  }
}

.c-community-header__content {
  @include breakpointUp(XS) {
    flex: 0 1 500px;
  }
}

.c-community-header__title {
  @include typographySubheadline($_include-box-model: false);
  margin-bottom: var(--gap-2);
}
