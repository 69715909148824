@import '~@sonnen/shared-web/abstracts';

.c-guide-purchase-offer-card {
  display: flex;
  flex-direction: column;
}

.c-guide-purchase-offer-card__header {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: var(--gap-2) 0;
  border-bottom: 1px solid getColor('grey-lightest');
  font-size: 20px;
}

.c-guide-purchase-offer-card__footer {
  display: flex;
  flex-direction: column;
  border-top: 1px solid getColor('grey-lightest');
  padding: var(--gap-3) var(--gap-2);
}
