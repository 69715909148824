@import '~@sonnen/shared-web/abstracts';

$_social-items: (
  tw: $color-twitter,
  fb: $color-facebook,
  yt: $color-youtube
);

.c-social-media-list {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
}

.c-social-media-list__list-item:not(:last-child) {
  margin-right: var(--gap-1);
}

.c-social-media-list__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--gap-4);
  height: var(--gap-4);
  border-radius: 50%;
  background-color: var(--social-media-list-item-bg-color);
  transition:
    background-color 250ms ease,
    fill 250ms ease;
}

@each $_name, $_color in $_social-items {
  .c-social-media-list__item--#{$_name} {
    --social-media-list-item-bg-color: #{getColor('white')};
    --social-media-list-icon-color: #{$_color};

    &:hover {
      --social-media-list-item-bg-color: #{$_color};
      --social-media-list-icon-color: #{getTextColor('white')};
    }
  }

  .c-social-media-list__item--#{$_name}.c-social-media-list__item--shadow {
    --social-media-list-item-bg-color: transparent;
    --social-media-list-icon-color: #{getTextColor('white')};

    &:hover {
      --social-media-list-icon-color: #{$_color};
    }
  }
}

.c-social-media-list__icon {
  @include createIcon(
    $_width: var(--gap-2),
    $_color: var(--social-media-list-icon-color),
  );
  transition: inherit;
}
