@import '~@sonnen/shared-web/abstracts';

.c-guide-usage__preview-steps {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include breakpointUp(MD) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
}

.c-guide-usage__subheadline {
  margin-bottom: var(--gap-3);
}

.c-guide-usage__wave {
  @include breakpointUp(MD) {
    margin: 130px var(--gap-3) 0;
    transform: rotate(90deg);
  }
}
