@import '~@sonnen/shared-web/abstracts';
@import '~@sonnen/shared-web/src/styles/ds/typography';

.c-modal-confirm__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-modal-confirm__paragraph {
  @include fontBodyMedium();

  max-width: 400px;
  text-align: center;
  color: getTextColor('dark');
}

.c-modal-confirm__footer {
  width: 100%;
  margin-bottom: var(--gap-2);
}
