@import '~@sonnen/shared-web/abstracts';

.c-contract-flat-expert-view__legend-item {
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: getTextColor('dark');

  & + & {
    margin-top: var(--gap-1);
  }

  &::before {
    content: '';
    width: 15px;
    height: 2px;
    margin-right: var(--gap-2);
  }

  @include breakpointUp(MD) {
    font-size: 14px;
  }
}

.c-contract-flat-expert-view__legend-item--success::before {
  background: $color-green;
}

.c-contract-flat-expert-view__legend-item--warning::before {
  background: $color-light-gold;
}

.c-contract-flat-expert-view__legend-item--dashed::before {
  background:
    linear-gradient(
      to right,
      getColor('grey') 30%,
      getColor('white') 30%,
      getColor('white') 65%,
      getColor('grey') 65%
    );
}

.c-contract-flat-expert-view__legend-item--blue::before {
  background: getColor('blue');
}

.c-contract-flat-expert-view__legend-item-label {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 500;
  color: getTextColor('grey');
}

.c-contract-flat-expert-view__expandable-section {
  margin-bottom: var(--gutter-section);
}

.c-contract-flat-expert-view__card {
  margin-bottom: var(--gutter-section);
}

.c-contract-flat-expert-view__chart {
  display: grid;
  grid-template-rows: auto 25px auto 35px auto;
  grid-template-areas:
    'legend'
    '.'
    'chart'
    '.'
    'description';

  @include breakpointUp(MD) {
    grid-template: 1fr 35px 1fr / 12fr 1fr 5fr;
    grid-template-areas:
      'chart . legend'
      'chart . .'
      'chart . description';
  }
}

.c-contract-flat-expert-view__chart-inner {
  grid-area: chart;

  @include breakpointUp(MD) {
    display: flex;
    align-items: center;
  }
}

.c-contract-flat-expert-view__chart-description {
  grid-area: description;

  @include breakpointUp(MD) {
    max-width: 225px;
  }
}

.c-contract-flat-expert-view__chart-legend {
  grid-area: legend;

  @include breakpointUp(MD) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
