@import '~@sonnen/shared-web/abstracts';

.c-battery-downloads-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.c-battery-downloads-table-row__content {
  flex: 0 0 percentage(3 / 4);
  padding-right: var(--gap-3);
  overflow: hidden;

  @include breakpointUp(SM) {
    flex: 0 0 percentage(2 / 6);
    justify-content: flex-end;
  }
}

.c-battery-downloads-table-row__secondary-content {
  padding-right: var(--gap-3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include breakpointUp(SM) {
    flex: 0 0 percentage(3 / 6);
  }
}

.c-battery-downloads-table-row__download {
  flex: 0 0 percentage(1 / 4);
  justify-content: center;
  display: flex;

  @include breakpointUp(SM) {
    flex: 0 0 percentage(1 / 6);
    display: block;
  }
}
