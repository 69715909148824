@import '~@sonnen/shared-web/abstracts';

.c-popup {
  --popup-width-protected: var(--popup-width, 100%);
  --popup-height-protected: var(--popup-height, 100%);
  --popup-content-padding: var(--gap-5) var(--gap-3) var(--gap-3);
  --popup-button-offset: var(--gap-2);

  @include breakpointUp(SM) {
    --popup-content-padding: var(--gap-5);
    --popup-button-offset: var(--gap-3);
  }
}

.c-popup {
  @include fadeOut($_transition: none);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-popup);
  margin-top: -100px;
  transition:
    margin-top 200ms ease-in-out 100ms,
    opacity 200ms ease-in-out 100ms,
    visibility 200ms ease-in-out 100ms;

  &.is-open {
    @include fadeIn();
    margin-top: 0;
  }
}

.c-popup__content {
  @include boxShadow();
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: var(--popup-width-protected);
  height: var(--popup-height-protected);
  transform: translate(-50%, -50%);
  background-color: getTextColor('white');
}

.c-popup__content-inner {
  padding: var(--popup-content-padding);
}

.c-popup__close-button {
  @include createIcon($_color: getTextColor('dark'));
  position: absolute;
  z-index: 1;
  top: var(--popup-button-offset);
  right: var(--popup-button-offset);
  width: var(--gap-3);
  height: var(--gap-3);
  border-radius: 50%;
  padding: 4px;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -25%;
    left: -25%;
    width: 150%;
    height: 150%;
    background-image: radial-gradient(circle at center, getColor('white') 30%, transparent);
    pointer-events: none;
  }
}
