@import '~@sonnen/shared-web/abstracts';

.c-contract-flat-statistics {
  display: block;
}

.c-contract-flat-statistics__banner {
  display: flex;
  justify-content: center;
  margin-bottom: var(--gutter-section);
}
