@import '~@sonnen/shared-web/abstracts';

.c-migration-banner__content {
  max-width: 500px;
  padding-top: var(--gap-3);

  @include breakpointUp(SM) {
    padding: var(--gap-7);
  }
}

.c-migration-banner__label {
  font-size: 12px;
  font-weight: 500;
  color: getTextColor('grey');
  text-transform: uppercase;
  letter-spacing: 2px;
}

.c-migration-banner__title {
  margin: var(--gap-2) 0;
  font-size: 32px;
  color: getTextColor('dark');
}

.c-migration-banner__description {
  margin-bottom: var(--gap-2);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: getTextColor('dark');
}

.c-migration-banner__buttons {
  display: flex;
  align-items: center;
  margin-top: var(--gap-2);
}

.c-migration-banner__button {
  margin-right: var(--gap-1);
  min-width: 130px;
}
