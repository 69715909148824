@import '~@sonnen/shared-web/abstracts';

.c-demo-live-energy-flow {
  position: relative;
}

.c-demo-live-energy-flow__bubble {
  max-width: 400px;
}

.c-demo-live-energy-flow__bubble--mobile {
  margin: var(--gap-2) auto 0;
}

.c-demo-live-energy-flow__bubble--desktop {
  position: absolute;
  z-index: var(--z-index-tooltip);
  top: 0;
  left: calc(50% + 90px); // @TODO: 50% + half of the EnergyFlow circle size.
}
