@import '~@sonnen/shared-web/abstracts';

.c-demo-community-map-section-mobile {
  position: relative;
}

.c-demo-community-map-section-mobile__bubble {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: var(--z-index-tooltip);
  width: 280px;
  transform: translate(-50%, calc(-100% - var(--gap-2)));

  @include breakpointUp(SM) {
    left: 75%;
  }
}
