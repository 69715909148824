@import '~@sonnen/shared-web/abstracts';

.c-year-picker {
  display: flex;
}

.c-year-picker__subtract-icon {
  --ds-icon-height: 16px;
  --ds-icon-color: #{getColor('blue')};

  transform: rotate(90deg);
}

.c-year-picker__add-icon {
  --ds-icon-height: 16px;
  --ds-icon-color: #{getColor('blue')};

  transform: rotate(-90deg);
}

.c-year-picker__year-container {
  display: flex;
  margin: 0 var(--gap-1);
  border: solid 1px getColor('grey-light');
  border-radius: 4px;
  width: 150px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.c-year-picker__year {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-left: var(--gap-1);
}

.c-year-picker__calendar-icon {
  @include createIcon(
    $_width: 14px,
    $_color: getTextColor('dark'),
  );
}
