@import '~@sonnen/shared-web/abstracts';
@import '../LiveEnergyFlow.helper';

// Circle kinds.
$_circle-params: (
  production: (
    top: 0,
    left: calc(50% - (#{$_chart-circle-size} / 2)),
    leftDesktop: calc(50% - (#{$_chart-circle-size--desktop} / 2)),
    color: $color-light-gold
  ),
  consumption: (
    top: calc(50% - (#{$_chart-circle-size} / 2)),
    topDesktop: calc(50% - (#{$_chart-circle-size--desktop} / 2)),
    left: 0,
    color: getTextColor('blue')
  ),
  battery: (
    top: calc(50% - (#{$_chart-circle-size} / 2)),
    topDesktop: calc(50% - (#{$_chart-circle-size--desktop} / 2)),
    right: 0,
    color: $color-green
  ),
  grid: (
    bottom: 20px,
    bottomDesktop: 0,
    left: calc(50% - (#{$_chart-circle-size} / 2)),
    leftDesktop: calc(50% - (#{$_chart-circle-size--desktop} / 2)),
    color: getTextColor('grey')
  )
);

@mixin createCircle($_color) {
  &.is-online {
    border-color: $_color;
    color: getTextColor('dark');

    .c-ef-circle__value,
    .c-ef-circle__sub-value {
      @include fadeIn();
      color: $_color;
    }
  }

  &.is-online.is-animated {
    border: none;
    box-shadow: inset 0 0 5px 1px rgba($_color, .5);
  }

  &.is-blured {
    box-shadow: 0 0 10px $_color;
  }
}

%ef-circle-content {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;

  @include breakpointUp(SM) {
    position: static;
    overflow: hidden;
  }
}

.c-ef-circle {
  position: absolute;
  display: inline-block;
  width: $_chart-circle-size;
  height: $_chart-circle-size;
  border: 2px solid getColor('grey-lightest');
  border-radius: 50%;
  color: getTextColor('grey');
  background: getColor('white');
  cursor: pointer;
  transition:
    border-color 250ms ease 100ms,
    box-shadow 250ms ease 100ms,
    color 250ms ease 100ms;

  @include breakpointUp(SM) {
    width: $_chart-circle-size--desktop;
    height: $_chart-circle-size--desktop;
  }
}

.c-ef-circle__ring {
  position: absolute;
  width: $_chart-circle-size;
  height: $_chart-circle-size;
  border-radius: 50%;
  animation: rotate 2000ms linear infinite;

  @include breakpointUp(SM) {
    width: $_chart-circle-size--desktop;
    height: $_chart-circle-size--desktop;
  }
}

@each $_ring in map-keys($_circle-params) {
  .c-ef-circle--#{$_ring} {
    .c-ef-circle__ring {
      box-shadow: inset 3px 0 0 0 mapDeepGet($_circle-params, $_ring, 'color');

      @include breakpointUp(SM) {
        box-shadow: inset 4px 0 0 0 mapDeepGet($_circle-params, $_ring, 'color');
      }
    }
  }
}

@each $_circle in map-keys($_circle-params) {
  .c-ef-circle--#{$_circle} {
    top: mapDeepGet($_circle-params, $_circle, 'top');
    right: mapDeepGet($_circle-params, $_circle, 'right');
    bottom: mapDeepGet($_circle-params, $_circle, 'bottom');
    left: mapDeepGet($_circle-params, $_circle, 'left');

    @include createCircle(mapDeepGet($_circle-params, $_circle, 'color'));

    @include breakpointUp(SM) {
      top: mapDeepGet($_circle-params, $_circle, 'topDesktop');
      right: mapDeepGet($_circle-params, $_circle, 'rightDesktop');
      bottom: mapDeepGet($_circle-params, $_circle, 'bottomDesktop');
      left: mapDeepGet($_circle-params, $_circle, 'leftDesktop');
    }
  }
}

.c-ef-circle__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: initial;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.c-ef-circle__icon {
  --ds-icon-color: #{getTextColor('dark')};
  --ds-icon-height: 30px;

  margin-bottom: 6px;
}

.c-ef-circle__above-content {
  @extend %ef-circle-content;
  bottom: 100%;
  padding-bottom: 10px;

  @include breakpointUp(SM) {
    padding-bottom: 0;
  }
}

.c-ef-circle__below-content {
  @extend %ef-circle-content;
  top: 100%;
  padding-top: 10px;

  @include breakpointUp(SM) {
    padding-top: 0;
  }
}

.c-ef-circle__title {
  display: block;
  margin: 4px -40%;
  font-size: 11px;
  font-weight: 500;
  color: getTextColor('grey');

  @include breakpointUp(SM) {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-ef-circle__value {
  @include fadeOut();
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.c-ef-circle__value-error-icon-wrapper {
  position: absolute;
  padding-left: var(--gap-1);
  height: 13px;
}

.c-ef-circle__value-error-icon {
  @include createIcon(
    $_color: getTextColor('grey'),
    $_width: 14px
  );
}

.c-ef-circle__sub-value {
  position: absolute;
  bottom: calc(#{$_chart-circle-size * (-1)} - 1em - 5px);
  width: 100%;
  margin: 4px 0 0;
  font-size: 11px;
  font-weight: 500;

  @include breakpointUp(SM) {
    position: static;
    font-size: 14px;
  }
}
