@import '~@sonnen/shared-web/abstracts';

$_icon-size: var(--gap-3);

.c-account-settings-card {
  display: block;
}

.c-account-settings-card__action-bar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  color: getTextColor('dark');
  line-height: $_icon-size;
}

.c-account-settings-card__description {
  border-top: 1px solid getColor('grey-lightest');
  margin-top: var(--gap-2);
  padding-top: var(--gap-2);
}

.c-account-settings-card__toggle-title {
  display: flex;
  align-items: center;
}

.c-account-settings-card__icon {
  @include createIcon(
    $_width: $_icon-size
  );
  margin-right: var(--gap-1);
}

.c-account-settings-card__switch-wrapper {
  position: relative;
}
