@import '~@sonnen/shared-web/abstracts';

$_icon-size: var(--gap-2);
$_icon-gap: 4px;

.c-analysis-accessories-tooltip-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-analysis-accessories-tooltip-item__header {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  font-weight: 500;
  color: getTextColor('dark');
  white-space: nowrap;
}

.c-analysis-accessories-tooltip-item__footer {
  margin-top: 2px;
  padding-left: calc(#{$_icon-size} + #{$_icon-gap});
  font-size: 9px;
  font-weight: 500;
  color: getTextColor('grey');
  white-space: nowrap;
}

.c-analysis-accessories-tooltip-item__icon {
  @include createIcon(
    $_width: var(--gap-2),
    $_height: var(--gap-2),
  );
  align-self: center;
  margin-right: 4px;
}

.c-analysis-accessories-tooltip-item__symbol {
  display: inline-block;
  width: var(--gap-1);
  height: var(--gap-1);
  margin-right: 4px;
  border-radius: 50%;
  background-color: getColor('grey-light');
}

.c-analysis-accessories-tooltip-item__symbol--active {
  background-color: $color-light-green;
}

.c-analysis-accessories-tooltip-item__small-label {
  margin: 0 3px 0 12px;
  font-size: 10px;
}
