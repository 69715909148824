@import '~@sonnen/shared-web/abstracts';

.c-header-spacing-container {
  display: flex;
  align-items: center;
}

.c-header-spacing-container__item {
  display: block;
}

.c-header-spacing-container__item--spaced {
  margin-left: var(--gap-3);
}
