@import '~@sonnen/shared-web/abstracts';

.m-c-analysis-history {
  display: flex;
  max-width: 400px;
  margin: 0 auto;
}

.m-c-analysis-history__item {
  flex: 0 1 50%;
  max-width: 50%;
}
