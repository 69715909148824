@import '~@sonnen/shared-web/abstracts';

.c-infographic-card-layout {
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: 100%;
}

.c-infographic-card-layout__header {
  position: relative;
}

.c-infographic-card-layout__header-button {
  @include createIcon(
    $_width: 20px,
    $_color: getTextColor('blue')
  );
  position: absolute;
  top: 2px;
  right: 0;
  line-height: 0;
}

.c-infographic-card-layout__content {
  display: block;
}

.c-infographic-card-layout__notifications {
  min-height: calc(var(--gap) * 11px);
  padding-top: var(--gap-1);
}

.c-infographic-card-layout__popup {
  @include boxShadow();
  position: absolute;
  top: calc(var(--gap-2) * -1);
  left: calc(var(--gap-2) * -1);
  z-index: -1;
  width: calc(100% + var(--gap-4));
  min-height: calc(100% + var(--gap-4));
  padding: var(--gap-2);
  background: getColor('white');
  transform: scale(.95);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition:
    opacity 250ms ease-out 100ms,
    transform 250ms ease-out 100ms;

  &.is-active {
    z-index: var(--z-index-popup);
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    transform: scale(1);
  }
}

.c-infographic-card-layout__popup-button {
  @include createIcon(
    $_width: var(--gap-2),
    $_color: getTextColor('dark')
  );
  position: absolute;
  top: calc(var(--gap-2) + 4px);
  right: var(--gap-2);
  line-height: 0;
}
