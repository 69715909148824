@import '~@sonnen/shared-web/abstracts';
@import '../EFCircle/EFCircle.component';

.c-ef-circle-animations {
  display: flex;
  position: absolute;
  top: mapDeepGet($_circle-params, 'grid', 'top');
  right: mapDeepGet($_circle-params, 'grid', 'right');
  bottom: mapDeepGet($_circle-params, 'grid', 'bottom');
  left: mapDeepGet($_circle-params, 'grid', 'left');
  height: $_chart-circle-size;
  width: $_chart-circle-size;
  justify-content: flex-end;
  align-items: center;
  z-index: -1;

  @include breakpointUp(SM) {
    height: $_chart-circle-size--desktop;
    width: $_chart-circle-size--desktop;
    top: mapDeepGet($_circle-params, 'grid', 'topDesktop');
    right: mapDeepGet($_circle-params, 'grid', 'rightDesktop');
    bottom: mapDeepGet($_circle-params, 'grid', 'bottomDesktop');
    left: mapDeepGet($_circle-params, 'grid', 'leftDesktop');
  }
}

.c-ef-circle-animations__animation {
  position: absolute;
  bottom: var(--gap-3);
  overflow: hidden;
}

.c-ef-circle-animations__animation--windmills {
  right: calc(50% - 5px);
  width: 140px;

  @include breakpointUp(SM) {
    right: calc(50% + 45px);
    width: 238px;
  }
}

.c-ef-circle-animations__animation--grid {
  left: calc(50% + 25px);
  width: 108px;

  @include breakpointUp(SM) {
    left: calc(50% + 45px);
    width: 308px;
  }
}
