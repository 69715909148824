@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-old-provider {
  position: relative;
}

.c-guide-acceptance-old-provider__text-wrapper {
  margin-bottom: var(--gap-2);
}

.c-guide-acceptance-old-provider__select {
  @include breakpointUp(XS) {
    max-width: (var(--guide-acceptance-utility-input-width));
  }
}

.c-guide-acceptance-old-provider__loader {
  margin: var(--gap-4) 0 var(--gap-4);

  @include breakpointUp(XS) {
    max-width: 200px;
  }
}
