@charset "UTF-8";
.c-select .Select-option, .c-select--simple .Select-control {
  display: block;
  width: 100%;
  padding: 0 35px 0 15px;
  font-size: 13px;
  font-weight: 500;
  color: #0A1535;
  text-align: left;
  transition: all 0.25s ease 0.1s; }

.c-select .Select-menu, .c-select--simple .Select-control {
  border: 1px solid #EFEFF0;
  border-radius: var(--border-radius); }

.c-select {
  position: relative;
  height: 46px;
  width: 100%; }
  .c-select .Select-control {
    height: 46px;
    width: 100%;
    line-height: 44px;
    cursor: pointer; }
  .c-select .Select-multi-value-wrapper {
    width: 100%; }
  .c-select .Select-value-label div {
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .c-select .Select-menu {
    position: absolute;
    z-index: var(--z-index-dropdown);
    width: 100%;
    margin-top: -1px;
    padding: 5px 0;
    box-shadow: 0 1px 3px 0 rgba(128, 128, 128, 0.15);
    background: #FFFFFF; }
  .c-select .Select-option {
    position: relative;
    font-family: var(--font-family);
    color: rgba(10, 21, 53, 0.5);
    line-height: 36px;
    cursor: pointer; }
    .c-select .Select-option:hover {
      color: #0B7AC2; }
    .c-select .Select-option.is-selected {
      color: #0B7AC2; }
      .c-select .Select-option.is-selected:after {
        content: "";
        position: absolute;
        top: calc(50% - 8px);
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        font-family: var(--font-family-icons);
        font-size: 10px;
        color: #0B7AC2;
        transition: all 200ms ease-in-out; }
  .c-select .Select-input {
    display: inline !important; }
  @media screen and (min-width: 768px) {
    .c-select {
      min-width: 180px; } }

.c-select--simple .Select-control {
  height: 46px;
  line-height: 44px; }

.c-select--searchable {
  height: 30px; }
  .c-select--searchable .Select-control {
    height: 30px;
    line-height: 28px; }
  .c-select--searchable .Select-menu {
    margin-top: 2px;
    max-height: 160px;
    overflow: auto; }
  .c-select--searchable .Select-input input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border: 0; }
  .c-select--searchable.is-open .Select-input input {
    z-index: 1;
    width: 100% !important;
    height: 100% !important;
    border-bottom: 2px solid #0B7AC2;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 300;
    color: #0A1535; }

.c-select__arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10; }
  .c-select__arrow:after {
    content: "";
    position: absolute;
    top: calc(50% - 8px);
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    font-family: var(--font-family-icons);
    font-size: 10px;
    color: #0B7AC2;
    transition: all 200ms ease-in-out; }
  .c-select__arrow::after {
    transform: rotate(-90deg);
    transform-origin: center; }
  .c-select__arrow.is-open::after {
    transform: rotate(90deg);
    transform-origin: center; }
