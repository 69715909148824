@import '~@sonnen/shared-web/abstracts';

.c-guide-contact__card {
  @include borderRadius();
  @include boxShadow();
  font-size: 14px;
}

.c-guide-contact__card-title {
  @include borderRadius();
  @include boxShadow($_side: 'bottom');
  font-weight: 500;
  padding: var(--gap-2) var(--gap-3);
}

.c-guide-contact__headline {
  font-weight: 500;
}

.c-guide-contact__day {
  width: 100px;
  display: inline-block;
}

.c-guide-contact__card-content {
  padding: var(--gap-2) var(--gap-3);
}

.c-guide-contact__separator {
  margin: var(--gap-2) 0;
  border: 1px solid getColor('grey-lightest');
  border-width: 1px 0 0;
}
