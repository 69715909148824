@import '~@sonnen/shared-web/abstracts';

.c-guide-purchase__card-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--gap-1) 0;
}

.c-guide-purchase__subheadline {
  margin-bottom: var(--gap-5);
}

.c-guide-purchase__offers {
  display: flex;
  flex-direction: column;
}

.c-guide-purchase__offer-container {
  margin: var(--gap-2);
}

.c-guide-purchase__step-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--gap-5) 0;
}
