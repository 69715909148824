@import '~@sonnen/shared-web/abstracts';

.m-c-community-map-section__wrapper {
  margin: 0 calc(var(--gutter-layout) * -1) var(--mobile-section-margin);

  @include breakpointUp(SM) {
    margin-left: 0;
    margin-right: 0;
  }
}

.m-c-community-map-section__container {
  @include boxShadow();
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  @include breakpointUp(SM) {
    @include borderBottomRadius('big');
    overflow: hidden;
  }
}

.m-c-community-map-section__container--error {
  --icon-size: 100px;

  justify-content: center;
  align-items: center;
  min-height: 350px;
}
