@import '~@sonnen/shared-web/abstracts';

.c-guide-purchase-step-cession-doc-modal__modal-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: var(--gap-3) 0;
}

.c-guide-purchase-step-cession-doc-modal__document {
  margin: 0 calc(var(--modal-layout-padding-h) * -1) var(--gap-5);
  border: 1px solid getColor('grey-lightest');
  border-width: 1px 0;
}

.c-guide-purchase-step-cession-doc-modal__side-content {
  @include breakpointUp(MD) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.c-guide-purchase-step-cession-doc-modal__info {
  margin-bottom: var(--gap-3);
}

.c-guide-purchase-step-cession-doc-modal__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0;
  height: 100%;
}

.c-guide-purchase-step-cession-doc-modal__error {
  white-space: pre-wrap;
}
