// ==========================================================================
// *1. Moves bubble to the right by width of the bubble's triangle.
// ==========================================================================

@import '~@sonnen/shared-web/abstracts';

.c-guide-text-with-tooltip {
  --guide-text-with-tooltip-visibility: hidden;
  --guide-text-with-tooltip-opacity: 0;
}

.c-guide-text-with-tooltip {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.c-guide-text-with-tooltip__bubble {
  position: absolute;
  z-index: var(--z-index-tooltip);
  top: calc(100% + 12px);
  right: -14px; // 1
  width: 230px;
  visibility: var(--guide-text-with-tooltip-visibility);
  opacity: var(--guide-text-with-tooltip-opacity);
  transition: visibility 200ms, opacity 200ms;

  @include breakpointUp(XXS) {
    width: 250px;
  }
}

.c-guide-text-with-tooltip__trigger {
  @include createIcon(
    $_height: 20px,
    $_color: getTextColor('blue'),
  );

  cursor: pointer;
}

.c-guide-text-with-tooltip__content {
  position: relative;
  font-size: 14px;
  align-self: flex-end;

  &:hover {
    --guide-text-with-tooltip-visibility: visible;
    --guide-text-with-tooltip-opacity: 1;
  }
}
