@import '~@sonnen/shared-web/abstracts';

.c-guide-verify-identity {
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.c-guide-verify-identity__header {
  --logo-color: #{getTextColor('white')};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: var(--header-height);
  padding: 0 var(--gap-3);
  background: getColor('grey-darkest');
}

.c-guide-verify-identity__wrapper {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: var(--gap-10) var(--gap-6);

  @include breakpointUp(XS) {
    padding: 0 var(--gap-9);
  }
}

.c-guide-verify-identity__title {
  margin-bottom: var(--gap-4);
  font-size: 24px;
  font-weight: 300;
  color: getTextColor('dark');
}

.c-guide-verify-identity__headline {
  margin-bottom: var(--gap-4);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
  text-align: center;
}

.c-guide-verify-identity__input {
  @include borderRadius();
  height: var(--gap-5);
  padding: var(--gap-1) var(--gap-2);
  border: 1.5px solid getColor('grey-lightest');
  font-size: 18px;
  font-weight: 300;
  color: getTextColor('grey');
  margin-bottom: var(--gap-1);

  &::placeholder {
    color: getTextColor('grey');
  }

  &.is-error {
    border-color: $color-coral;
  }
}

.c-guide-verify-identity__error {
  align-self: flex-start;
  color: $color-coral;
}

.c-guide-verify-identity__checkbox {
  align-self: flex-start;
  margin: var(--gap-1) 0 var(--gap-6);
}

.c-guide-verify-identity__error-icon {
  width: var(--gap-8);
  margin-bottom: var(--gap-4);
}

.c-guide-verify-identity__error-icon-svg {
  fill: $color-coral;
}
