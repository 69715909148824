@import '~@sonnen/shared-web/abstracts';

$_gap: var(--gap-3) / 2;

.c-guide-grid-stretched {
  --guide-grid-stretched-item-width: 50%;
}

.c-guide-grid-stretched {
  @include breakpointUp(SM) {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 calc(#{$_gap} * -1);
  }
}

.c-guide-grid-stretched__item {
  margin-bottom: var(--gap-3);

  @include breakpointUp(SM) {
    display: flex;
    justify-items: stretch;
    flex: 1 0;
    max-width: var(--guide-grid-stretched-item-width);
    margin-bottom: 0;
    padding: 0 calc(#{$_gap});
  }
}

.c-guide-grid-stretched__item--1\/3 {
  --guide-grid-stretched-item-width: #{percentage(1 / 3)};
}

.c-guide-grid-stretched__item--no-gap {
  margin-bottom: 0;
}
