@import '~@sonnen/shared-web/abstracts';

@mixin borderGradient($color: getColor('grey-lightest'), $size: 1px, $length: 10px) {
  background-image:
    linear-gradient(to right, $color 50%, transparent 50%),
    linear-gradient(to right, $color 50%, transparent 50%),
    linear-gradient(to bottom, $color 50%, transparent 50%),
    linear-gradient(to bottom, $color 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: $length $size, $length $size, $size $length, $size $length;
}

.c-guide-acceptance-utility-meter-id {
  width: 100%;
  margin: var(--gap-4) 0;
}

.c-guide-acceptance-utility-meter-id__text {
  margin: var(--gap-2) 0;
}

.c-guide-acceptance-utility-meter-id__input {
  @include breakpointUp(XS) {
    max-width: (var(--guide-acceptance-utility-input-width));
  }
}

.c-guide-acceptance-utility-meter-id__link {
  color: getTextColor('blue');
  cursor: pointer;

  &:hover {
    color: lighten($color-light-blue, 15%);
  }
}

.c-guide-acceptance-utility-meter-id__link-wrapper {
  margin-bottom: var(--gap-2);
  line-height: 1.4em;
  font-size: 14px;
}

.c-guide-acceptance-utility-meter-id__meters {
  @include borderGradient(getColor('grey'));

  display: flex;
  justify-content: space-between;
  padding: var(--gap-3);
  margin: var(--gap-3) 0 var(--gap-5);

  @include breakpointUp(SM) {
    justify-content: space-around;
  }
}

.c-guide-acceptance-utility-meter-id__meter-img {
  max-height: 160px;
}

.c-guide-acceptance-utility-meter-id__info {
  margin-top: var(--gap-2);
  margin-bottom: 0;
  line-height: 1.4em;
  font-size: 14px;
}

.c-guide-acceptance-utility-meter-id__info--bold {
  font-weight: 500;
}

.c-guide-acceptance-utility-meter-id__link-icon {
  @include createIcon(
    $_width: var(--gap-2),
    $_height: var(--gap-2)
  );
}

.c-guide-acceptance-utility-meter-id__link-question {
  margin-bottom: 0;
}

.c-guide-acceptance-utility-meter-id__checkbox-wrapper {
  display: flex;
  align-items: center;
}

.c-guide-acceptance-utility-meter-id__icon-info {
  @include createIcon(
    $_color: getTextColor('blue'),
    $_width: 20px
  );
  cursor: pointer;
}

.c-guide-acceptance-utility-meter-id__tooltip {
  position: relative;
  margin-bottom: var(--gap-1);
  margin-left: var(--gap-2);
}

.c-guide-acceptance-utility-meter-id__bubble-wrapper {
  position: absolute;
  line-height: 1.4;
  z-index: 2;
  top: 29px;
  right: -14px;
  width: 160px;

  @include breakpointUp(SM) {
    right: -125px;
    width: 270px;
  }
}
