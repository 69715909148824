@import '~@sonnen/shared-web/abstracts';

.c-ef-explanation-tooltip {
  @include dropdown($_bottom: calc(100% + 15px), $_right: -150%, $_padding: 15px);
  @include createTriangleArrow($_side: 'bottom', $_offset: '50% - 8px');

  @include breakpointUp(SM) {
    @include dropdown($_bottom: calc(100% + 17px), $_right: 50%, $_padding: 15px);
    @include createTriangleArrow($_side: 'bottom', $_offset: '50% - 6px');
    transform: translateX(50%);
  }

  @include breakpointUp(SM) {
    @include dropdown($_bottom: calc(100% + 17px), $_right: 50%, $_padding: 15px);
    @include createTriangleArrow($_side: 'bottom', $_offset: '50% - 6px');
    transform: translateX(50%);
  }
}

.c-ef-explanation-tooltip--right {
  @include dropdown($_bottom: calc(100% + 17px), $_right: 0, $_padding: 15px);
  @include createTriangleArrow($_side: 'bottom', $_offset: '100% - 41px');

  @include breakpointUp(SM) {
    @include dropdown($_bottom: calc(100% + 17px), $_right: 0, $_padding: 15px);
    @include createTriangleArrow($_side: 'bottom', $_offset: '100% - 74px');
    transform: translateX(0);
  }

  @include breakpointUp(MD) {
    @include dropdown($_bottom: calc(100% + 17px), $_right: 50%, $_padding: 15px);
    @include createTriangleArrow($_side: 'bottom', $_offset: '50% - 6px');
    transform: translateX(50%);
  }
}

.c-ef-explanation-tooltip--left {
  @include dropdown($_bottom: calc(100% + 17px), $_left: 0, $_padding: 15px);
  @include createTriangleArrow($_side: 'bottom', $_offset: '26px');

  @include breakpointUp(SM) {
    @include dropdown($_bottom: calc(100% + 17px), $_right: 50%, $_padding: 15px);
    @include createTriangleArrow($_side: 'bottom', $_offset: '64px');
    transform: translateX(0);
  }

  @include breakpointUp(MD) {
    @include dropdown($_bottom: calc(100% + 17px), $_right: 50%, $_padding: 15px);
    @include createTriangleArrow($_side: 'bottom', $_offset: '50% - 6px');
    left: initial;
    transform: translateX(50%);
  }
}
