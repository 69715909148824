@import '~@sonnen/shared-web/abstracts';

.c-header-dropdown {
  position: relative;
}

.c-header-dropdown__trigger {
  width: 100%;
}

.c-header-dropdown__content {
  position: absolute;
  top: calc(100% + var(--gap-1));
  right: -13px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 100ms ease 100ms;

  &.is-open {
    visibility: visible;
    opacity: 1;
  }
}
