@import '~@sonnen/shared-web/abstracts';

.c-mobile-headline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--layout-content-gap-h);
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: var(--gap-4);
  background-color: getColor('grey-lightest');
}

.c-mobile-headline__icon {
  @include createIcon(
    $_height: var(--gap-2),
    $_color: getTextColor('grey')
  );
  margin-right: var(--gap-1);
  line-height: 1em;
}
