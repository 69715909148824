@import '~@sonnen/shared-web/abstracts';

$_gutter: var(--gap-1);

.c-guide-purchase-step-accept-offer__offer {
  flex: 1 1;
  border-radius: var(--border-radius-big);
  padding: 0 $_gutter;

  @include breakpointUp(SM) {
    max-width: 50%;
  }

  @include breakpointUp(MD) {
    max-width: percentage(1 / 3);
  }
}

.c-guide-purchase-step-accept-offer__offer-group {
  display: flex;
  flex-direction: column;
}

.c-guide-purchase-step-accept-offer__offer-group-footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.c-guide-purchase-step-accept-offer__button {
  @include boxShadow();
  @include borderRadius('big');
  width: 100%;
  padding: var(--gap-3) var(--gap-9);
  color: getTextColor('white');
  background-color: getColor('blue');
  font-size: 16px;
  font-weight: 500;

  &:hover {
    background-color: darken($color-light-blue, 10%);
  }
}

.c-guide-purchase-step-accept-offer__no-offer {
  @include boxShadow();
  @include borderRadius('big');
  width: 100%;
  padding: var(--gap-2) var(--gap-3);
  color: getTextColor('grey');
  font-size: 12px;
  line-height: 1.67;
  text-align: center;
}

.c-guide-purchase-step-accept-offer__description {
  margin-bottom: var(--gap-3);
  white-space: pre-wrap;
}
