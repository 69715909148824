@import '~@sonnen/shared-web/abstracts';

.c-guide-purchase-configuration-details {
  &__header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__detailed-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }

  &__section {
    margin-top: 16px;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  &__product-divider {
    height: 1px;
    background-color: $grey_100;
  }
}
