// ==========================================================================
// Each powerline has its own animation for controlling dots.
// However, the powerlines look different on mobile devices.
// Therefore, they have two types - one for mobile devices
// and one for desktop devices.
//
// The animations' middle breakpoint is calculated based on lines'
// dimensions so that the dots always have constant speed.
//
// 'translate()' is separated into 'translateX()' and 'translateY()' because
// Edge browser does not handle mixed transforms very well. Separating
// translate prevents postcss-reduce-transforms module from reducing it.
// See: https://github.com/ben-eb/postcss-reduce-transforms/issues/9
// ==========================================================================

@import '../LiveEnergyFlow.helper';

$_line-height: ($_chart-vertical-line-size / 2 - $_chart-line-gap);
$_line-height-desktop: ($_chart-vertical-line-size--desktop / 2 - $_chart-line-gap);
$_line-width: ($_chart-horizontal-line-size / 2 - $_chart-line-gap);
$_line-width-desktop: ($_chart-horizontal-line-size--desktop / 2 - $_chart-line-gap);
$_line-length: ($_line-height + $_line-width);
$_line-length-desktop: ($_line-height-desktop + $_line-width-desktop);

// Animations' middle breakpoints on mobile ($_mb) and desktop ($_mbd) devices.
$_mb: round(percentage($_line-height / $_line-length));
$_mbd: round(percentage($_line-height-desktop / $_line-length-desktop));

// stylelint-disable declaration-block-single-line-max-declarations

@keyframes makeBottomRightTurn {
  0% { transform: translateX(0%) translateY(0%); opacity: 1; }
  #{$_mb} { transform: translateX(0%) translateY(100%); }
  100% { transform: translateX(-100%) translateY(100%); opacity: 1; }
}

@keyframes makeBottomRightTurnDesktop {
  0% { transform: translateX(0%) translateY(0%); opacity: 1; }
  #{$_mbd} { transform: translateX(0%) translateY(100%); }
  100% { transform: translateX(-100%) translateY(100%); opacity: 1; }
}

@keyframes makeBottomLeftTurn {
  0% { transform: translateX(-100%) translateY(0%); opacity: 1; }
  #{$_mb} { transform: translateX(-100%) translateY(100%); }
  100% { transform: translateX(0%) translateY(100%); opacity: 1; }
}

@keyframes makeBottomLeftTurnDesktop {
  0% { transform: translateX(-100%) translateY(0%); opacity: 1; }
  #{$_mbd} { transform: translateX(-100%) translateY(100%); }
  100% { transform: translateX(0%) translateY(100%); opacity: 1; }
}

@keyframes makeTopRightTurn {
  0% { transform: translateX(0%) translateY(100%); opacity: 1; }
  #{$_mb} { transform: translateX(0%) translateY(0%); }
  100% { transform: translateX(-100%) translateY(0%); opacity: 1; }
}

@keyframes makeTopRightTurnDesktop {
  0% { transform: translateX(0%) translateY(100%); opacity: 1; }
  #{$_mbd} { transform: translateX(0%) translateY(0%); }
  100% { transform: translateX(-100%) translateY(0%); opacity: 1; }
}

@keyframes makeTopLeftTurn {
  0% { transform: translateX(0%) translateY(0%); opacity: 1; }
  #{100% - $_mb} { transform: translateX(-100%) translateY(0%); }
  100% { transform: translateX(-100%) translateY(100%); opacity: 1; }
}

@keyframes makeTopLeftTurnDesktop {
  0% { transform: translateX(0%) translateY(0%); opacity: 1; }
  #{100% - $_mbd} { transform: translateX(-100%) translateY(0%); }
  100% { transform: translateX(-100%) translateY(100%); opacity: 1; }
}

@keyframes makeTopBottomTurn {
  from { transform: translateX(0%) translateY(0%); opacity: 1; }
  to { transform: translateX(0%) translateY(100%); opacity: 1; }
}

@keyframes makeTopBottomTurnDesktop {
  from { transform: translateX(0%) translateY(0%); opacity: 1; }
  to { transform: translateX(0%) translateY(100%); opacity: 1; }
}

@keyframes makeRightLeftTurn {
  from { transform: translateX(0%) translateY(0%); opacity: 1; }
  to { transform: translateX(-100%) translateY(0%); opacity: 1; }
}

@keyframes makeRightLeftTurnDesktop {
  from { transform: translateX(0%) translateY(0%); opacity: 1; }
  to { transform: translateX(-100%) translateY(0%); opacity: 1; }
}

// stylelint-enable declaration-block-single-line-max-declarations
