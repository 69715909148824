@import '~@sonnen/shared-web/abstracts';

.c-contract-card-status {
  @include borderRadius();
  padding: var(--gap-1) var(--gap-2);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: getTextColor('grey');
  opacity: .5;
  background-color: getColor('grey-lightest');
}

.c-contract-card-status--done {
  background-color: $color-green;
  color: getTextColor('white');
}
