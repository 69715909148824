@import '~@sonnen/shared-web/abstracts';

.c-notification-feed {
  display: block;
}

.c-notification-feed__badge {
  position: relative;
  height: 100%;
}

.c-notification-feed__content {
  width: 315px;
}

.c-notification-feed__scrollable-container {
  @include createCustomScrollbar();
  max-height: 400px;
  overflow-y: auto;
}

.c-notification-feed__title {
  margin-bottom: -1px;
  border-bottom: 1px solid getColor('grey-lightest');
  padding: 12px var(--gap-3);
  font-size: 14px;
  color: getTextColor('dark');
  line-height: 1em;
}

.c-notification-feed__loader {
  padding: var(--gap-2) 0;
}

.c-notification-feed__info-banner {
  --icon-size: 110px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: var(--gap-4) var(--gap-3);

  @include breakpointUp(NAV) {
    display: block;
    height: initial;
    padding-top: var(--gap-8);
    padding-bottom: var(--gap-8);
  }
}

.c-notification-feed__info-banner-inner {
  max-width: 500px;
  margin: 0 auto;
}
