@import '~@sonnen/shared-web/abstracts';

.c-product {
  display: flex;
  flex: 0 1;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: var(--gap-2);
}

.c-product__side {
  @include createIcon(
    $_width: 20px,
    $_color: getTextColor('blue')
  );
  min-width: 20px;
  margin-right: var(--gap-1);
}

.c-product__content {
  padding-right: var(--gap-1);
}

.c-product__title {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: 1em;
  white-space: nowrap;
}

.c-product__subtitle {
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: 1em;
  white-space: pre-wrap;
}
