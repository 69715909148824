@import '~@sonnen/shared-web/abstracts';

.c-guide-setup-modal-confirmation {
  display: flex;
  flex-direction: column;
  min-height: 280px;
}

.c-guide-setup-modal-confirmation__content {
  --info-banner-icon-color: #{getTextColor('dark')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0;
  padding: var(--gap-1);
}

.c-guide-setup-modal-confirmation__loader {
  margin-top: var(--gap-2);
}

.c-guide-setup-modal-confirmation__footer {
  display: flex;
  justify-content: center;
  border-top: 1px solid getColor('grey-lightest');
  padding: var(--gap-2);
}
