@import '~@sonnen/shared-web/abstracts';

.c-contract-energy-invoices-list__row {
  margin-bottom: var(--gap-2);
  padding-bottom: var(--gap-1);
  border-bottom: 1px solid getColor('grey-lightest');
}

.c-contract-energy-invoices-list__text {
  color: getTextColor('dark');
  font-size: 14px;
  font-weight: 500;
}

.c-contract-energy-invoices-list__icon {
  @include createIcon(
    $_width: 20px,
    $_color: getTextColor('blue'),
  );
}
