@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-desired-delivery-date__input {
  margin-top: var(--gap-2);

  @include breakpointUp(XS) {
    max-width: (var(--guide-acceptance-utility-input-width));
  }
}

.c-guide-acceptance-desired-delivery-date__icon {
  @include createIcon(
    $_color: getTextColor('blue'),
    $_width: 20px,
  );
  cursor: pointer;
}

.c-guide-acceptance-desired-delivery-date__header {
  display: flex;
  align-items: center;
}

.c-guide-acceptance-desired-delivery-date__header-text {
  width: auto;
}

.c-guide-acceptance-desired-delivery-date__tooltip {
  position: relative;
  margin-left: var(--gap-2);
}

.c-guide-acceptance-desired-delivery-date__bubble-wrapper {
  position: absolute;
  top: 28px;
  right: -125px;
  width: 270px;
  z-index: 2;
}

.c-guide-acceptance-desired-delivery-date__error {
  margin: var(--gap-1) 0;
  color: $color-coral;
}
