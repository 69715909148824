@import '~@sonnen/shared-web/abstracts';

.c-form-checkbox {
  display: flex;
  position: relative;
}

.c-form-checkbox__star {
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 18px;
  color: getTextColor('blue');
}
