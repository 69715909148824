@import '~@sonnen/shared-web/abstracts';
@import './LiveEnergyFlow.helper';

.c-live-energy-flow {
  display: flex;
  justify-content: center;
  padding: var(--gap-6) 0 var(--gap-3);

  @include breakpointUp(SM) {
    padding-bottom: 0;
  }
}

.c-live-energy-flow__content {
  position: relative;
  z-index: 1;
  width: (2 * $_chart-circle-size + $_chart-horizontal-line-size - 10px);
  height: (2 * $_chart-circle-size + $_chart-vertical-line-size - 10px);

  @include breakpointUp(SM) {
    width: (2 * $_chart-circle-size--desktop + $_chart-horizontal-line-size--desktop - 10px);
    height: (2 * $_chart-circle-size--desktop + $_chart-vertical-line-size--desktop - 10px);
  }
}

.c-live-energy-flow__lines-container {
  $_width: $_chart-horizontal-line-size;
  $_height: $_chart-vertical-line-size;

  position: absolute;
  z-index: 1;
  top: calc(50% - #{$_height / 2});
  left: calc(50% - #{$_width / 2});
  width: $_width;
  height: $_height;

  // @NOTE: We cannot use CSS Custom Properties here because those values are used in keyframes breakpoints.
  // stylelint-disable scss/no-duplicate-dollar-variables

  @include breakpointUp(SM) {
    $_width: $_chart-horizontal-line-size--desktop;
    $_height: $_chart-vertical-line-size--desktop;

    top: calc(50% - #{$_height / 2});
    left: calc(50% - #{$_width / 2});
    width: $_width;
    height: $_height;
  }

  // stylelint-enable scss/no-duplicate-dollar-variables
}

.c-live-energy-flow__circles-container {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
