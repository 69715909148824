// ==========================================================================
// *1. Bottom nav is fixed instead of being sticky because of iOS
//     scroll bounce.
// ==========================================================================

@import '~@sonnen/shared-web/abstracts';

.c-nav-bottom {
  --nav-list-item-min-width: 20%;
  --nav-item-color: #{getTextColor('grey')};
}

.c-nav-bottom {
  @include boxShadow();
  position: fixed; // 1
  bottom: 0;
  left: 0;
  z-index: var(--z-index-bottom-nav);
  display: flex;
  justify-content: center;
  width: 100%;
  height: var(--bottom-nav-height);
  background-color: getColor('white');
}

.c-nav-bottom__nav {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;

  @include breakpointUp(XS) {
    max-width: 600px;
  }
}

.c-nav-bottom__foundation {
  display: block;
  height: var(--bottom-nav-height);
}
