@import '~@sonnen/shared-web/abstracts';

.c-app-loader {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  padding-top: 70px;
  text-align: center;
}

.c-app-loader__icon {
  color: getTextColor('blue');
  font-size: 62px;
}

.c-app-loader__headline {
  @include typographySubheadline($_include-box-model: false);
  padding: var(--gap-3) 0;
}

.c-app-loader__loader {
  margin-top: var(--gap-4);
}
