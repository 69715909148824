@import '~@sonnen/shared-web/abstracts';
@import '../SiteSwitcher/SiteSwitcher.helper';

.c-site-switcher-list {
  position: relative;
  border-top: 1px solid getColor('grey-lightest');
}

.c-site-switcher-list__item {
  display: block;
  list-style: none;

  // @TODO: Reduce complexity.
  // stylelint-disable sonnen/rule-no-different-bem-blocks

  &:first-of-type .c-site-switcher-button {
    @include buttonActiveState();
  }

  &:last-of-type .c-site-switcher-button {
    box-shadow: none;
  }
}
