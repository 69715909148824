@import '~@sonnen/shared-web/abstracts';

.c-info-box {
  display: flex;
  width: 100%;

  @include breakpointUp(MD) {
    @include boxShadow();
    @include borderRadius();
    padding: var(--gap-2);
  }
}

.c-info-box__icon {
  @include createIcon(
    $_width: 20px,
    $_color: getTextColor('blue'),
  );
  flex: 0 0 20px;
  margin-right: var(--gap-2);
}

.c-info-box__content {
  flex: 1 0;
}

.c-info-box__title {
  margin-bottom: var(--gap-1);
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('dark');
  line-height: 1.4em;
}

.c-info-box__description {
  margin-bottom: 0;
  font-size: 14px;
  color: getTextColor('dark');
  line-height: 1.4em;
}
