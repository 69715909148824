@import '~@sonnen/shared-web/abstracts';

.c-guide-setup-preview__steps {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include breakpointUp(MD) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.c-guide-setup-preview__subheadline {
  margin-bottom: var(--gap-3);
}

.c-guide-setup-preview__wave {
  @include breakpointUp(MD) {
    margin-top: 130px;
    transform: rotate(90deg);
  }
}

.c-guide-setup-preview__wave--reverse\@md {
  @include breakpointUp(MD) {
    transform: rotate(-90deg);
  }
}
