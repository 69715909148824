@import '~@sonnen/shared-web/abstracts';

@keyframes slideIn {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}

@keyframes slideOut {
  from {
    left: 0;
  }

  to {
    left: 100%;
  }
}

.c-nested-nav-slide {
  --animation-duration: 500ms;
}

.c-nested-nav-slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: getColor('white');
}

.c-nested-nav-slide--on-stack {
  left: 0;
}

.c-nested-nav-slide--slide-in {
  animation-name: slideIn;
  animation-duration: var(--animation-duration);
  animation-fill-mode: forwards;
}

.c-nested-nav-slide--slide-out {
  animation-name: slideOut;
  animation-duration: var(--animation-duration);
  animation-fill-mode: forwards;
}
