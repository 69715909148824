@import '~@sonnen/shared-web/abstracts';
@import '../SiteSwitcher/SiteSwitcher.helper';

.c-site-switcher-button {
  @extend %site-container;
  display: flex;
  align-items: center;
  background: getColor('white');
}

.c-site-switcher-button--highlighted {
  @include buttonActiveState();
  margin-top: 0;
}

.c-site-switcher-button__content {
  display: block;
  width: 100%;
  padding-right: $_arrow-size;
}

.c-site-switcher-button__icon {
  @include createIcon(
    $_color: getTextColor('dark'),
    $_width: 20px
  );
  transform: rotate(180deg);
  pointer-events: none;
}
