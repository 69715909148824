@import '~@sonnen/shared-web/abstracts';

.c-cession-document {
  position: relative;
  padding-bottom: percentage(4 / 3);

  @include breakpointUp(SM) {
    padding-bottom: percentage(9 / 16);
  }
}

.c-cession-document__document {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
