@import '~@sonnen/shared-web/abstracts';

@mixin addMobileGaps($_negative: false) {
  $_gap: var(--gutter-layout);

  @if $_negative {
    margin-left: calc(#{$_gap} * -1);
    margin-right: calc(#{$_gap} * -1);
  } @else {
    padding-left: $_gap;
    padding-right: $_gap;
  }

  @include breakpointUp(SM) {
    @if $_negative {
      margin-left: 0;
      margin-right: 0;
    } @else {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.c-guide-setup-active__subheadline {
  margin-bottom: var(--gap-5);
}

.c-guide-setup-active__description {
  margin: var(--gap-3) 0;
}

.c-guide-setup-active__section-header {
  @include addMobileGaps($_negative: true);
  margin-top: -1px;
  margin-bottom: 0;
  padding: 4px var(--gap-3);
  font-size: 12px;
  font-weight: 500;
  color: getTextColor('grey');
  background-color: getColor('grey-lightest');
}

.c-guide-setup-active__tabs {
  @include addMobileGaps($_negative: true);
  overflow: hidden;

  @include breakpointUp(SM) {
    overflow: initial;
  }
}

.c-guide-setup-active__tab {
  @include addMobileGaps();
}
