.c-contract-billing-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--gutter-section);
}

.c-contract-billing-section__additional-info-side {
  justify-self: flex-end;
}
