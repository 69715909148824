@import '~@sonnen/shared-web/abstracts';

.c-collapsible-area {
  --collapsible-area-gap-horizontal-private: var(--collapsible-area-gap-horizontal, 0);
  --collapsible-area-content-height-private: var(--collapsible-area-content-height, 0);
}

.c-collapsible-area__trigger {
  margin-bottom: var(--gap-2);
}

.c-collapsible-area__icon {
  @include createIcon($_color: getTextColor('blue'));
  transform: rotate(-90deg);
  transition: transform 300ms ease 200ms;

  &.is-rotated {
    transform: rotate(90deg);
  }
}

.c-collapsible-area__content-wrapper {
  height: var(--collapsible-area-content-height-private);
  transition: height 300ms ease 200ms;
  overflow: hidden;
  margin: 0 calc(-1 * var(--collapsible-area-gap-horizontal-private));
}

// stylelint-disable-next-line selector-max-type
.c-collapsible-area__content a {
  font-size: var(--link-button-font-size);
  font-weight: 500;
  color: getTextColor('blue');
  line-height: 1.2;

  // stylelint-disable-next-line selector-max-type
  &:hover {
    color: getTextColor('blue');
  }
}
