@import '~@sonnen/shared-web/abstracts';

.c-guide-purchase-info-banner {
  --icon-size: 124px;
  max-width: 570px;
  margin: 0 auto;
  padding: var(--gap-7) 0;

  @include breakpointUp(SM) {
    --icon-size: 160px;
  }
}
