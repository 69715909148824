@import '~@sonnen/shared-web/abstracts';

$_nested-nav-side-content-radial-size: 32px;

.c-nested-nav-side-content__icon {
  @include createIcon(
    $_color: getTextColor('dark'),
    $_height: 18px
  );
  flex: 0 18px;
  transform: rotate(180deg);
}

.c-nested-nav-side-content__icon--theme-dark {
  @include createIcon(
    $_color: getTextColor('white'),
    $_height: 18px
  );
}

.c-nested-nav-side-content__radial {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $_nested-nav-side-content-radial-size;
  height: $_nested-nav-side-content-radial-size;
  border-radius: $_nested-nav-side-content-radial-size / 2;
  background-color: getColor('grey-lightest');
  border: 1px solid getColor('grey-light');
}

.c-nested-nav-side-content__radial--active {
  background-color: getColor('blue');
  border: 1px solid getColor('blue');
}

.c-nested-nav-side-content__radial-checkmark {
  @include createIcon(
    $_color: getTextColor('white'),
    $_height: 16px
  );
}
