@import '~@sonnen/shared-web/abstracts';

.c-overview-community {
  @include breakpointUp(MD) {
    padding: var(--gap-3) var(--gap-4) 0;
  }
}

.c-overview-community__grid {
  @include breakpointUp(MD) {
    justify-content: flex-end;
  }
}

.c-overview-community__text {
  align-self: center;
  width: 100%;
  margin: 0 auto;

  @include breakpointUp(MD) {
    max-width: 300px;
    margin: 0;
  }
}

.c-overview-community__headline-icon {
  @include createIcon(
    $_color: getTextColor('dark'),
    $_width: 20px,
  );
  display: inline-block;
  vertical-align: middle;
  margin-right: var(--gap-1);
}

.c-overview-community__image {
  max-width: 300px;
  margin: 0 auto;
  align-self: center;

  @include breakpointUp(MD) {
    max-width: 400px;
  }
}

.c-overview-community__button {
  display: inline-block;
  padding-top: var(--gap-3);
}
