@import '~@sonnen/shared-web/abstracts';

.c-dashboard-modal-unit-exchange__content {
  display: flex;
  align-items: center;
}

.c-dashboard-modal-unit-exchange__footer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
