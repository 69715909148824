@import '~@sonnen/shared-web/abstracts';

.c-analysis-energy-flow-chart {
  position: relative;
  padding-bottom: 20px; // TODO: Figure out better way to make needle visible.
}

.c-analysis-energy-flow-chart__overflow-container {
  position: relative;
}

// TODO: change this className so it reflects the structure of the component
.c-analysis-energy-flow-chart__wrapper {
  @include breakpointDown(SM) {
    padding-bottom: var(--gap-3);
  }
}

.c-analysis-energy-flow-chart__tooltip {
  padding: 0 var(--gap-1);
}
