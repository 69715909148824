@import '~@sonnen/shared-web/abstracts';

.c-guide-product-info-section {
  max-width: 680px;
  margin-bottom: var(--gap-7);
  white-space: pre-wrap;

  @include breakpointUp(SM) {
    margin-bottom: var(--gap-10);
  }
}

.c-guide-product-info-section__headline {
  margin: var(--gap-2) 0 var(--gap-1);
  font-size: 18px;
  font-weight: 400;
}

.c-guide-product-info-section__image {
  margin-bottom: var(--gap-1);

  @include breakpointUp(XS) {
    padding: var(--gap-2) var(--gap-8);
  }
}

.c-guide-product-info-section__description {
  color: getTextColor('dark');
  font-size: 14px;
  font-weight: 300;
}
