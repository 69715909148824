@import '~@sonnen/shared-web/abstracts';

.c-string-complexity-checker {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -4px calc(var(--gap-1) * -1);
}

.c-string-complexity-checker__item {
  padding: 0 4px var(--gap-1);
}
