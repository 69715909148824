@import '~@sonnen/shared-web/abstracts';

.c-battery-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.c-battery-icon__label {
  margin-top: 6px;
}

.c-battery-icon__status {
  position: relative;
  margin-right: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: getColor('grey-lightest');
}

.c-battery-icon__status--light-green {
  background-color: $color-light-green;
}

.c-battery-icon__circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--gap-5);
  height: var(--gap-5);
  margin-right: var(--gap-1);
  border: 2px solid getColor('grey-lightest');
  border-radius: 50%;
  transition: border-color 250ms ease 100ms;
}

.c-battery-icon__icon {
  @include createIcon(
    $_color: getTextColor('dark'),
    $_width: 22px,
    $_height: 22px
  );
}
