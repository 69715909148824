@import '~@sonnen/shared-web/abstracts';

.c-battery-card-accessories {
  @include borderRadius('big');
  display: flex;
  align-items: center;
  min-height: 100%;
  padding: var(--gap-4) var(--gap-4) var(--gap-3);
  background: getColor('grey-lightest');
}

.c-battery-card-accessories__content {
  width: 100%;
  text-align: center;
}

.c-battery-card-accessories__icon {
  @include createIcon(
    $_color: getTextColor('dark'),
    $_width: 50px
  );
  display: inline-block;
  margin-bottom: var(--gap-3);

  @include breakpointUp(SM) {
    @include createIcon(
      $_color: getTextColor('dark'),
      $_width: 75px
    );
    margin-bottom: var(--gap-4);
  }
}

.c-battery-card-accessories__title {
  @include typographySubheadline($_include-box-model: false);
  margin-bottom: var(--gap-2);
}

.c-battery-card-accessories__link {
  margin-top: var(--gap-3);
}
