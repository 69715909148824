@import '~@sonnen/shared-web/abstracts';

.c-guide-documents__header {
  margin: 0 var(--gap-4) var(--gap-2);
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('grey');
}

.c-guide-documents__headline {
  font-weight: 400;
  font-size: 20px;

  @include breakpointUp(SM) {
    font-weight: 300;
    font-size: 26px;
  }
}

.c-guide-documents__headline-container {
  margin-bottom: 0;
}

.c-guide-documents__banner {
  --icon-size: 100px;

  max-width: 600px;
  margin: 60px auto;

  @include breakpointUp(XS) {
    --icon-size: 160px;
  }

  @include breakpointUp(NAV) {
    margin-top: 120px;
  }
}

.c-guide-documents__sections {
  margin-top: calc(var(--gap-2) * -1);
}

.c-guide-documents__separator {
  margin: var(--gap-3) 0;
  border: 1px solid getColor('grey-lightest');
  border-width: 1px 0 0;
}

.c-guide-documents__title-section {
  display: flex;
  align-items: baseline;
  flex-direction: column;

  @include breakpointUp(SM) {
    flex-direction: row;
  }
}

.c-guide-documents__title {
  font-size: 20px;
  font-weight: 300;
  padding-right: var(--gap-2);
}

.c-guide-documents__title-side {
  font-size: 12px;
  padding: var(--gap-1) 0;

  @include breakpointUp(SM) {
    padding: 0;
  }
}

.c-guide-documents__title-side-label {
  color: getTextColor('grey');
  padding-right: var(--gap-1);
}
