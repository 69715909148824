@import '~@sonnen/shared-web/abstracts';

.c-tag {
  --tag-color: #{getTextColor('white')};
}

.c-tag {
  @include borderRadius();
  display: inline-flex;
  align-items: center;
  height: var(--gap-3);
  padding: 0 var(--gap-1);
  font-size: 12px;
  font-weight: 500;
  line-height: 1em;
  color: var(--tag-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-tag--done {
  --tag-color: #{getTextColor('white')};
  background-color: $color-green-2;
}

.c-tag--sent {
  color: $yellow_700;
  background-color: $yellow_100;
}

.c-tag--expired {
  color: $red_600;
  background-color: $red_100;
}

.c-tag--open {
  --tag-color: #{getTextColor('grey')};
  background-color: getColor('grey-lightest');
}

.c-tag--error {
  --tag-color: #{getTextColor('white')};
  background-color: $color-coral;
}

.c-tag__icon {
  @include createIcon(
    $_height: 1em,
    $_color: var(--tag-color)
  );
  display: inline-block;
  margin-right: .5em;
}
