@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-sign-failure {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpointDown(SM) {
    height: 100%;
  }
}

.c-guide-acceptance-sign-failure__button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: var(--gap-3) 0 var(--gap-1);

  @include breakpointUp(MD) {
    margin: var(--gap-1) 0;
    padding-right: var(--gap-4);
    justify-content: flex-end;
  }
}

.c-guide-acceptance-sign-failure__step {
  display: flex;
  text-align: left;
  margin-bottom: var(--gap-2);
  font-size: 14px;
  font-weight: 300;
}

.c-guide-acceptance-sign-failure__steps-headline {
  margin-bottom: var(--gap-3);
  font-size: 18px;

  @include breakpointUp(MD) {
    margin: var(--gap-3) 0;
    text-align: left;
  }
}

.c-guide-acceptance-sign-failure__step-index {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin-right: var(--gap-2);
  border: 2px solid getColor('blue');
  border-radius: 50%;
  color: getTextColor('blue');
  font-weight: 500;
}

.c-guide-acceptance-sign-failure__step-text {
  display: flex;
  align-items: center;
}
