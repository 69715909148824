@import '~@sonnen/shared-web/abstracts';

.c-community-graph__card {
  margin-bottom: var(--gutter-section);
}

.c-community-graph__chart {
  height: 100%;
  min-height: 320px;
}
