@import '~@sonnen/shared-web/abstracts';

.c-nested-nav-list-item {
  --nav-item-font-size: 16px;
}

.c-nested-nav-list-item {
  display: block;
  width: 100%;
}

.c-nested-nav-list-item--theme-dark {
  --nav-item-color: #{getColor('white')};
}

.c-nested-nav-list-item__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-nested-nav-list-item__subtitle {
  margin-top: 3px;
  font-size: 13px;
  font-weight: 400;
}
