// ==========================================================================
// *1. Position is set according to leaflet controls.
// ==========================================================================

@import '~@sonnen/shared-web/abstracts';

.c-community-map__hovercard { // 1
  position: absolute;
  width: calc(100% - 44px);
  left: 22px;
  right: 22px;
  bottom: 20px;
  z-index: 2;

  @include breakpointUp(XXS) {
    width: auto;
    max-width: 312px;
    right: initial;
  }
}
