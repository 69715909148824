@import '~@sonnen/shared-web/abstracts';

.m-c-demo-nav {
  position: relative;
  z-index: var(--z-index-bottom-nav);
}

.m-c-demo-nav__wrapper {
  width: 100%;
  position: fixed;
  bottom: var(--bottom-nav-height);
}

.m-c-demo-nav__bubble {
  position: absolute;
  z-index: var(--z-index-tooltip);
  top: 0;
  left: 50%;
  width: 210px;
  transform: translate(-50%, calc(-100% - var(--gap-4)));
}
