@import '~@sonnen/shared-web/abstracts';

$_input-height: var(--gap-4);
$_input-padding: var(--gap-3);
$_input-border-color: getColor('grey-lightest');

@mixin inputHover() {
  &:hover {
    @include placeholder(getTextColor('blue'));
    border-color: getColor('blue');
    color: getTextColor('blue');
    cursor: pointer;
  }
}

@mixin inputFocus() {
  &:focus {
    @include placeholder(transparent);
    border-color: $_input-border-color;
    color: getTextColor('dark');
    cursor: text;
  }
}

@mixin disableField() {
  &:hover {
    cursor: not-allowed;
    border-color: $_input-border-color;
    color: getTextColor('dark');
  }

  &:hover ~ .c-form__label--floating:not(.is-active) {
    color: getTextColor('dark');
  }
}

@mixin changeLabelColorOnHover() {
  &:hover ~ .c-form__label--floating:not(.is-active) {
    color: getTextColor('blue');
  }
}

@mixin changeLabelColorOnFocus() {
  &:focus ~ .c-form__label--floating:not(.is-active) {
    color: getTextColor('dark');
  }
}

@mixin formElementTheme() {
  @include placeholder(getTextColor('grey'));
  @include inputHover;
  @include inputFocus;
  @include changeLabelColorOnHover;
  @include changeLabelColorOnFocus;
  height: $_input-height;
  border: 2px solid $_input-border-color;
  border-radius: 0;
  border-width: 0 0 2px 0;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 300;
  color: getTextColor('dark');
  line-height: 1.33em;
  background: getColor('white');
  transition: all .25s ease;
}

@mixin formLabelTheme() {
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: 20px;
}
