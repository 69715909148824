@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-utility-termination {
  width: 100%;
  margin: var(--gap-4) 0;
}

.c-guide-acceptance-utility-termination__text {
  margin: var(--gap-2) 0;
}

.c-guide-acceptance-utility-termination__radio-wrapper {
  display: flex;
  margin-bottom: var(--gap-2);
}

.c-guide-acceptance-utility-termination__radio-btn {
  &:first-child {
    margin-right: var(--gap-9);
  }
}

.c-guide-acceptance-utility-termination__icon-warning {
  @include createIcon($_width: var(--gap-2), $_color: $color-light-gold);
  margin-right: var(--gap-2);
}

.c-guide-acceptance-utility-termination__icon-info {
  @include createIcon($_width: var(--gap-2), $_color: getTextColor('blue'));
  margin-right: var(--gap-1);
}

.c-guide-acceptance-utility-termination__warning {
  display: flex;
  align-items: center;
  color: getTextColor('grey');
  font-size: 14px;
}
