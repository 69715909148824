@import '~@sonnen/shared-web/abstracts';

.c-battery-card-status {
  height: 100%;
}

.c-battery-card-status__battery-wrapper {
  max-width: 90px;
  margin: 0 auto var(--gap-2);

  @include breakpointUp(XXS) {
    max-width: 120px;
    margin-right: 0;
    margin-bottom: 0;
  }
}

.c-battery-card-status__state-alert-wrapper {
  margin-top: calc(var(--gap-1) * -1);
}

.c-battery-card-status__backupbox-value-wrapper {
  display: flex;
  flex-direction: column;

  @include breakpointUp(MD) {
    flex-direction: row;
    align-items: center;
  }
}

.c-battery-card-status__backupbox-value {
  display: flex;
  align-items: center;
}

.c-battery-card-status__backupbox-value-icon {
  @include createIcon(
    $_color: getTextColor('dark'),
    $_width: 16px,
  );
  margin: 0 var(--gap-1);
  transform: rotate(180deg);
}

.c-battery-card-status__backupbox-status {
  @include breakpointUp(MD) {
    margin-left: var(--gap-1);
  }
}

.c-battery-card-status__switch-wrapper {
  display: flex;
  align-items: center;
  margin-top: var(--gap-1);
}
