@import '~@sonnen/shared-web/abstracts';

.c-guide-document-error-modal {
  display: block;
}

.c-guide-document-error-modal__headline {
  color: getTextColor('dark');
  font-size: 18px;
  font-weight: 500;
}

.c-guide-document-error-modal__text {
  max-width: 240px;
  margin-bottom: var(--gap-2);
  margin-top: var(--gap-2);
  color: rgba(getTextColor('dark'), .4);
  font-weight: 500;
  font-size: 14px;
}

.c-guide-document-error-modal__icon {
  @include createIcon(
    $_color: getTextColor('blue'),
    $_width: 45px
  );
}
