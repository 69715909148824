@import '~@sonnen/shared-web/abstracts';

$_nested-navheader-height: 64px;
$_nested-navheader-back-icon-height: 18px;

.c-nested-nav-slide-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: $_nested-navheader-height;
  padding: var(--gap-4);
  background: getColor('grey-darkest');
}

.c-nested-nav-slide-header__title {
  display: flex;
  align-items: center;
  color: getColor('white');
  font-size: 18px;
}

.c-nested-nav-slide-header__icon {
  @include createIcon(
    $_color: getTextColor('white'),
    $_height: $_nested-navheader-back-icon-height,
  );
}

.c-nested-nav-slide-header__button {
  position: absolute;
  top: 0;
  left: 0;
  padding: ($_nested-navheader-height - $_nested-navheader-back-icon-height) / 2  var(--gap-4);
}

.c-nested-nav-slide-header__icon--back {
  position: relative;
  top: 0;
  left: 0;
}

.c-nested-nav-slide-header__icon--title {
  height: 22px;
  margin-right: var(--gap-1);
}
