@import '~@sonnen/shared-web/abstracts';

$_contract-icon-height: 100px;
$_x-icon-height: 36px;

.c-contract-termination__icon {
  --ds-icon-height: #{$_contract-icon-height};
  --ds-icon-color: #{getColor('grey-darkest')};
}

.c-contract-termination__icon-x {
  --ds-icon-height: #{$_x-icon-height};
  --ds-icon-color: #{getColor('blue')};

  position: relative;
  top: calc(-1 * #{$_x-icon-height});
  right: calc(-1 * (#{$_contract-icon-height} - #{$_x-icon-height}));
  background-color: #{getColor('white')};
}
