@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-input-field__input {
  @include borderRadius();
  height: var(--gap-4);
  padding: var(--gap-1) var(--gap-2);
  margin-top: var(--gap-2);
  border: 1px solid getColor('grey-lightest');
  font-weight: 300;

  &::placeholder {
    color: getTextColor('grey');
  }

  &.is-error {
    border-color: $color-coral;
  }
}

.c-guide-acceptance-input-field__error {
  color: $color-coral;
}
