@import '~@sonnen/shared-web/abstracts';

$_highchart-elements: '.highcharts-container, svg';
$_highchart-data-label-elements: '.highcharts-data-labels, .highcharts-data-label, .highcharts-data-label > span';

.c-annual-consumption-chart {
  width: 100%;
  padding-top: 25px;
}

.c-annual-consumption-chart__chart {
  #{$_highchart-elements} {
    width: 100% !important;
    overflow: visible !important;
    font-family: var(--font-family) !important;
  }

  #{$_highchart-data-label-elements} {
    max-width: 480px;
    width: inherit;
  }
}

.c-annual-consumption-chart__tooltip {
  @include dropdown(
    $_bottom: -50px,
    $_left: calc(16px - 25%),
    $_padding: 10px
  );
  @include createTriangleArrow($_side: 'bottom', $_offset: '50% - 7px');
  font-family: var(--font-family);
  font-weight: 400;
  color: getTextColor('dark');
  min-width: 50%;
  width: 50%;
}

.c-annual-consumption-chart__tooltip--extreme-left {
  left: calc(16px - 5%);
  @include createTriangleArrow($_side: 'bottom', $_offset: '10% - 7px');
}

.c-annual-consumption-chart__tooltip--left {
  left: calc(16px - 12.5%);
  @include createTriangleArrow($_side: 'bottom', $_offset: '25% - 7px');
}

.c-annual-consumption-chart__tooltip--right {
  left: calc(16px - 37.5%);
  @include createTriangleArrow($_side: 'bottom', $_offset: '75% - 7px');
}

.c-annual-consumption-chart__tooltip--extreme-right {
  left: calc(16px - 45%);
  @include createTriangleArrow($_side: 'bottom', $_offset: '90% - 7px');
}

.c-annual-consumption-chart__tooltip-date {
  font-size: 12px;
}

.c-annual-consumption-chart__tooltip-text {
  font-size: 14px;
  font-weight: 700;
  white-space: initial;
}

.c-annual-consumption-chart__plotline-label {
  margin-right: 5px;
  font-size: 11px;
  color: getTextColor('grey');
  line-height: 12px;
  letter-spacing: -0.5px;
  text-align: right;
}

.c-annual-consumption-chart__plotline-paragraph {
  margin-bottom: 0;
}

.c-annual-consumption-chart__plotline-paragraph--thick {
  font-weight: 500;
  color: getTextColor('dark');
}

.c-annual-consumption-chart__flag {
  position: relative;
  top: 2px;
  font-size: 20px;
  line-height: 40px;
}

:export {
  colorSuccess: $color-green;
  colorWarning: $color-light-gold;
  colorConsumed: getColor('blue');
  colorConsumedArea: rgba($color-light-blue, 0.1);
}
