@import '~@sonnen/shared-web/abstracts';

.c-image-uploader {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.c-image-uploader__preview {
  position: relative;
  width: 100%;
  height: 100%;
}

.c-image-uploader__canvas {
  width: 100%;
  height: 100%;
}

.c-image-uploader__placeholder,
.c-image-uploader__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.c-image-uploader__button-wrapper {
  position: absolute;
  top: var(--gap-2);
  left: var(--gap-2);
}

.c-image-uploader__button-icon {
  @include createIcon(
    $_width: 13px,
    $_color: getTextColor('white'),
  );
  margin-right: 5px;
}

.c-image-uploader__input {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  opacity: 0;
  cursor: pointer;
}
