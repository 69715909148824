@import '~@sonnen/shared-web/abstracts';

.c-analysis-area-chart {
  padding-top: 0;
}

.c-analysis-area-chart__line-chart-tooltip-wrapper {
  position: absolute;
  padding: 0 5px;
  z-index: var(--z-index-tooltip);

  @include breakpointUp(SM) {
    padding: 0;
  }
}

.c-analysis-area-chart__line-chart-tooltip-link {
  padding-top: 8px;
  font-weight: 500;
  color: getTextColor('blue');
}
