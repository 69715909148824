@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-personal-details-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @include breakpointDown(SM) {
    height: 100%;
  }
}

.c-guide-acceptance-personal-details-error__icon {
  margin-bottom: var(--gap-4);

  @include createIcon(
    $_height: var(--gap-9),
    $_color: getTextColor('blue'),
  );
}

.c-guide-acceptance-personal-details-error__title {
  margin-bottom: var(--gap-2);
  color: getTextColor('dark');
  font-size: 18px;
}

.c-guide-acceptance-personal-details-error__description {
  padding: 0 var(--gap-3);
  color: getTextColor('grey');
  font-size: 12px;
  text-align: center;
  white-space: pre-wrap;

  @include breakpointDown(SM) {
    margin-bottom: var(--gap-4);
  }
}

.c-guide-acceptance-personal-details-error__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpointUp(SM) {
    flex-direction: row-reverse;
    align-self: flex-end;
    margin-right: var(--gap-4);
  }
}

.c-guide-acceptance-personal-details-error__button {
  margin: var(--gap-1);
}
