// ==========================================================================
// *1. Needed for flex parents.
// ==========================================================================

@import '~@sonnen/shared-web/abstracts';

$_desktop-content-gutter: var(--gap-5);

.c-panel {
  --panel-gap-top: var(--gap-5);
  --logo-color: #{getTextColor('white')};

  @include breakpointUp(SM) {
    --header-gutter: #{$_desktop-content-gutter};
  }
}

.c-panel--theme-header-light {
  --logo-color: #{getTextColor('dark')};
}

.c-panel {
  display: flex;
  flex-direction: column;
  height: 100%; // 1
}

.c-panel__content {
  flex: 1 0;
  padding:
    var(--panel-gap-top)
    var(--gutter-layout)
    var(--gap-6);
  background: getColor('white');

  @include breakpointUp(SM) {
    padding-left: $_desktop-content-gutter;
    padding-right: $_desktop-content-gutter;
  }
}

.c-panel__content--small-bottom-gap {
  padding-bottom: var(--gap-4);
}
