@import '~@sonnen/shared-web/abstracts';

.m-c-community-graph__chart {
  --negative-margin: calc(var(--gap-3) * -1);
}

.m-c-community-graph__chart {
  position: relative;
  width: calc(100% + var(--gap-6));
  min-height: 320px;
  margin: 0 var(--negative-margin) var(--mobile-section-margin) var(--negative-margin);
}

.m-c-community-graph__header,
.m-c-community-graph__expandable {
  margin-bottom: var(--mobile-section-margin)
}

.m-c-community-graph__subheadline {
  @include typographySmallHeadline;
  border-bottom: 0,
}
