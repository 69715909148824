@import '~@sonnen/shared-web/abstracts';

.c-guide-purchase-hardware-offer-footer {
  padding: var(--gap-1) 0;
}

.c-guide-purchase-hardware-offer-footer__title {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-guide-purchase-hardware-offer-footer__attachment-title {
  font-size: 12px;
}

.c-guide-purchase-hardware-offer-footer__title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5em;
}

.c-guide-purchase-hardware-offer-footer__icon {
  @include createIcon(
    $_width: 20px,
    $_color: var(--download-link-color)
  );
  margin-right: var(--gap-1);
}
