// ==========================================================================
// *1. Places content above page footer.
// *2. Enables children to use flex-grow, etc.
// ==========================================================================

@import '~@sonnen/shared-web/abstracts';

.c-layout-with-background {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: {
    image: url('../../../../images/background.jpg');
    size: cover;
    position: 0% 50%;
    repeat: no-repeat;
  }
}

.c-layout-with-background__content {
  position: relative;
  z-index: 2; // 1
  display: flex; // 2
  flex: 1 0;
}

.c-layout-with-background__footer {
  display: block;
}
