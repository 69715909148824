@import '~@sonnen/shared-web/abstracts';

$_banner-margin: var(--gap-1);

.c-guide-activate-banner {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  padding: $_banner-margin var(--gutter-layout);
  background-color: #{getColor('blue')};
  font-size: 14px;
  font-weight: 500;
}

.c-guide-activate-banner__title {
  display: flex;
  align-items: center;
  margin: $_banner-margin $_banner-margin $_banner-margin 0;
}

.c-guide-activate-banner__button {
  margin: $_banner-margin 0;
}

.c-guide-activate-banner__button-arrow {
  @include createIcon(
    $_width: var(--gap-3),
  );
  padding-right: var(--gap-1);
  transform: rotate(180deg);
}
