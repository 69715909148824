// ==========================================================================
// *1. https://stackoverflow.com/a/10055302
// ==========================================================================

@import '~@sonnen/shared-web/abstracts';

.c-nav-horizontal {
  position: relative;
  display: flex;
  flex-direction: column;
}

.c-nav-horizontal__inner {
  position: relative;
  z-index: 2;
  padding: 0 var(--gutter-layout);
  overflow-x: auto;
}

.c-nav-horizontal__nav {
  display: inline-flex; // 1
  min-width: 100%;
}

.c-nav-horizontal__separator {
  position: sticky;
  z-index: 1;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 2px;
  margin-top: -2px;
  background-color: getColor('grey-lightest');
}

.c-nav-horizontal__label {
  text-align: center;
}
