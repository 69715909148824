@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-completion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.c-guide-acceptance-completion__icon {
  margin: var(--gap-4) 0;

  @include createIcon(
    $_height: var(--gap-10),
    $_color: getTextColor('blue'),
  );
}

.c-guide-acceptance-completion__button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: var(--gap-5) 0;

  @include breakpointUp(MD) {
    padding-right: var(--gap-4);
  }
}

.c-guide-acceptance-completion__title {
  max-width: 400px;
  margin: 0 auto;
  font-size: 26px;
  font-weight: 300;
  color: getTextColor('dark');
}
