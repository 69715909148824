@import '~@sonnen/shared-web/abstracts';

.c-guide-documents-section__button {
  margin-top: var(--gap-2);

  @include breakpointUp(SM) {
    display: flex;
    justify-content: flex-end;
  }
}

.c-guide-documents-section__row {
  margin: var(--gap-3) 0 var(--gap-1);
}

.c-guide-documents-section__row-cell {
  font-size: 14px;
  color: getTextColor('grey');
}

.c-guide-documents-section__row-cell--dark {
  color: getTextColor('dark');
}

.c-guide-documents-section__row-cell--align-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.c-guide-documents-section__row-icon {
  @include createIcon(
    $_color: getTextColor('blue'),
    $_width: var(--gap-3),
  );
}
