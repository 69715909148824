@import '~@sonnen/shared-web/abstracts';

.c-analysis-accessories-chart {
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;

  @include breakpointUp(SM) {
    margin-bottom: 0;
    overflow: initial;
  }

  @include breakpointUp(SM) {
    margin-top: var(--gap-5);
  }
}

.c-analysis-accessories-chart__title {
  @include typographySmallHeadline();
  display: flex;
  justify-content: space-between;
  padding: 0 var(--gap-2);
  margin-bottom: var(--gap-3);

  @include breakpointUp(SM) {
    padding: 0 0 var(--gap-1);
  }
}

.c-analysis-accessories-chart__tooltip {
  padding: 0 var(--gap-1);
}

.c-analysis-accessories-chart__tip-icon {
  @include createIcon(
    $_width: 18px,
    $_color: getTextColor('blue')
  );
}
