@import '~@sonnen/shared-web/abstracts';

$_gap-h: var(--gap-3);

.c-guide-info-card {
  @include borderRadius();
  @include boxShadow();
  width: 100%;
}

.c-guide-info-card__header {
  @include boxShadow($_side: 'bottom');
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: var(--gap-6);
  padding: var(--gap-2) $_gap-h;
}

.c-guide-info-card__header-icon {
  @include createIcon(
    $_height: var(--gap-2),
    $_color: getTextColor('dark'),
  );
  margin-right: var(--gap-1);
}

.c-guide-info-card__header-icon--big {
  @include createIcon(
    $_height: 19px,
  );
}

.c-guide-info-card__header-title {
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('dark');
}

.c-guide-info-card__content {
  padding: var(--gap-2) $_gap-h;
  font-size: 14px;
  color: getTextColor('grey');
  line-height: 1.4em;
  white-space: pre-wrap;
}

.c-guide-info-card__content--scrollable {
  @include createCustomScrollbar();
  max-height: calc(var(--gap) * 48px);
  overflow-y: auto;
}
