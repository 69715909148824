@import '~@sonnen/shared-web/abstracts';

.c-demo-info-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;

  @include breakpointUp(NAV) {
    height: initial;
    padding-top: var(--gap-5);
  }
}

.c-demo-info-banner__icon {
  @include createIcon(
    $_color: getTextColor('grey'),
    $_height: 50px,
  );
  margin-bottom: var(--gap-3);
}

.c-demo-info-banner__title {
  @include typographyHeadline($_include-box-model: false);
  margin-bottom: var(--gap-2);
}

.c-demo-info-banner__description {
  @include typographySubheadline($_include-box-model: false);
  max-width: 500px;
  font-size: 18px;
}
