@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-prefilled-line {
  display: flex;
  align-items: center;
  height: var(--gap-5);
  font-size: 14px;

  @include breakpointDown(XS) {
    justify-content: space-between;
  }
}

.c-guide-acceptance-prefilled-line__edit-icon {
  @include createIcon(
    $_width: var(--gap-2),
    $_color: getTextColor('blue')
  );
  margin-left: var(--gap-3);
  cursor: pointer;
}
