@import '~@sonnen/shared-web/abstracts';

.m-c-analysis-energy-flow-chart {
  margin: 0 calc(var(--gutter-layout) * -1);
  padding-bottom: 40px;

  @include breakpointUp(SM) {
    margin-left: 0;
    margin-right: 0;
  }
}

.m-c-analysis-energy-flow-chart__inner {
  position: relative;
}
