@import '~@sonnen/shared-web/abstracts';

.c-contract-card {
  display: block;
}

.c-contract-card__header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 var(--gap-2);
}

.c-contract-card__headline {
  padding-bottom: var(--gap-1);
  font-size: 16px;
  font-weight: 500;
}

.c-contract-card__paragraph {
  font-weight: 400;
}

.c-contract-card__icon {
  @include createIcon(
    $_height: 32px,
    $_width: 32px,
    $_color: getTextColor('grey')
  );
}

.c-contract-card__icon--active {
  @include createIcon(
    $_height: 32px,
    $_width: 32px,
    $_color: $color-green
  );
}

.c-contract-card__info {
  padding-bottom: var(--gap-1);
}

.c-contract-card__info-value {
  font-weight: 500;
}
