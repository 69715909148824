@import '~@sonnen/shared-web/abstracts';

.c-progress-card {
  --progress-card-progress-protected: var(--progress-card-progress, 0);
}

.c-progress-card {
  @include boxShadow();
  @include borderRadius();
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 260px;
  transition: box-shadow 300ms;

  &:hover {
    @include boxShadow(
      $_is_hover: true,
      $_color: rgba(getColor('grey-darkest'), .25)
    );
  }

  @include breakpointUp(MD) {
    min-height: 330px;
  }
}

.c-progress-card--theme-faded {
  background-color: getColor('grey-lightest');
}

.c-progress-card__button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
  width: 100%;
  padding: var(--gap-3) var(--gap-1) var(--gap-1);

  @include breakpointUp(MD) {
    padding: var(--gap-5) var(--gap-2) var(--gap-2);
  }
}

.c-progress-card__title {
  margin-bottom: var(--gap-3);
  font-size: 20px;
  font-weight: 500;

  @include breakpointUp(MD) {
    margin-bottom: var(--gap-4);
  }
}

.c-progress-card__image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin: 0 auto var(--gap-2);

  @include breakpointUp(SM) {
    height: 140px;
  }
}

.c-progress-card__image {
  max-height: 100%;
}

.c-progress-card__description {
  margin-bottom: var(--gap-1);
  color: getTextColor('grey');
  font-size: 12px;
  font-weight: 500;

  @include breakpointUp(MD) {
    margin-bottom: var(--gap-2);
    padding: 0 var(--gap-4);
    font-size: 14px;
  }
}

.c-progress-card__checkmark {
  margin-bottom: var(--gap-2);

  @include createIcon(
    $_height: 20px,
    $_color: $color-green,
  );

  @include breakpointUp(MD) {
    @include createIcon(
      $_height: var(--gap-5),
    );
  }
}

.c-progress-card__in-progress {
  align-content: center;
  margin-bottom: var(--gap-2);
  font-size: 14px;
  font-weight: 500;
  color: getTextColor('dark');

  @include breakpointUp(MD) {
    font-size: 16px;
  }
}

.c-progress-card__cta {
  align-content: center;
  width: 100%;
  margin-bottom: var(--gap-1);
  padding: 10px;
  border-radius: 3px;
  color: getTextColor('white');
  font-size: 14px;
  font-weight: 500;
  background-color: getColor('blue');

  @include breakpointUp(MD) {
    margin-bottom: var(--gap-2);
    font-size: 16px;
  }
}

.c-progress-card__progress-bar {
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: getColor('grey-lightest');
  overflow: hidden;
}

.c-progress-card__progress-bar-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(var(--progress-card-progress-protected) * 1%);
  height: 100%;
  border-radius: 4px;
  background-color: $color-green;
}
