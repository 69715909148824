@import '~@sonnen/shared-web/abstracts';

.c-guide-product-info-footer {
  font-size: 14px;
  font-weight: 500;

  @include breakpointUp(SM) {
    margin-top: var(--gap-10);
  }
}

.c-guide-product-info-footer__headline {
  margin-bottom: var(--gap-1);
  font-size: 16px;
}
