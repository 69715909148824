@import '~@sonnen/shared-web/abstracts';

.c-guide-details-tag {
  display: flex;
  align-items: center;
}

.c-guide-details-tag__text {
  display: flex;
  align-items: center;
  flex: 1 1;
  min-width: 0;
  margin: 0 0 0 var(--gap-1);
  font-size: 12px;
  color: getTextColor('grey');
}

.c-guide-details-tag__separator {
  margin: 0 4px;
}

.c-guide-details-tag__responsibility {
  display: block;
  flex: 1 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
