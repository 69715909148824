@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-flat-offer-download__button {
  margin: var(--gap-2) 0 0;
  font-weight: 500;
  text-align: right;

  @include breakpointUp(SM) {
    margin: var(--gap-4) 0 0;
  }
}

.c-guide-acceptance-flat-offer-download__button-icon {
  padding-right: var(--gap-3);
}
