@import '~@sonnen/shared-web/abstracts';

.c-analysis-energy-flow__reset-wrapper {
  margin-bottom: var(--gap-2);
  text-align: right;
  visibility: hidden;

  &.is-visible {
    visibility: visible;
  }
}

.c-analysis-energy-flow__reset-icon {
  @include createIcon(
    $_width: 18px,
    $_height: 18px,
  );
}
