@import '~@sonnen/shared-web/abstracts';

.c-infographic-icon-house__inside {
  fill: map-get($infographic-colors, gray-light-2);
}

.c-infographic-icon-house__border {
  fill: none;
  stroke: map-get($infographic-colors, gray-dark-2);
  stroke-width: 2.5;
}

.c-infographic-icon-house__roof {
  fill: map-get($infographic-colors, gray-dark-2);
}

.c-infographic-icon-house__roof-shadow {
  fill: getColor('grey-darkest');
  opacity: .03;
}
