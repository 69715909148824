@import '~@sonnen/shared-web/abstracts';

.c-guide-discovery-step-distribution__company {
  margin-bottom: var(--gap-1);
  color: getTextColor('dark');
}

.c-guide-discovery-step-distribution__link {
  margin-top: var(--gap-1);
}
