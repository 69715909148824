@import '~@sonnen/shared-web/abstracts';

.c-analysis-history-toolbar {
  position: relative;
  width: 100%;
}

.c-analysis-history-toolbar__date-switcher-icon {
  @include createIcon(
    $_color: getTextColor('dark'),
    $_width: 14px
  );
}
