@import '~@sonnen/shared-web/abstracts';

// stylelint-disable-next-line selector-class-pattern
.mobile-app {
  -webkit-tap-highlight-color: transparent;

  // stylelint-disable-next-line selector-max-type
  :not(input):not(textarea) {
    user-select: none;
    -webkit-touch-callout: none;
  }
}

// @NOTE: This targets hotjar's bullshit feedback button, don't modify unless it's not working
// Sample hotjar selectors:
// #_hj-f5b2a1eb-9b07_feedback[data-minimized-position="bottom_right"] #_hj-f5b2a1eb-9b07_feedback_minimized
// #_hj-f5b2a1eb-9b07_feedback_minimized
// ._hj-f5b2a1eb-9b07_widget
// #_hj-f5b2a1eb-9b07_feedback
// ID included in these selectors is randomized and we cannot predict it

// stylelint-disable selector-id-pattern, selector-max-attribute, selector-max-id
// stylelint-disable selector-max-specificity, selector-max-type, selector-no-qualifying-type,
// stylelint-disable declaration-no-important

#_hj_feedback_container > div[id*='_hj-'][id*='_feedback'][class*='_widget'][data-minimized-position*='bottom_right'],
#_hj_feedback_container > div[id*='_hj-'][id*='_feedback'][data-minimized-position*='bottom_right'],
#_hj_feedback_container > div[class*='_widget'][data-minimized-position*='bottom_right'] {
  bottom: var(--bottom-nav-height) !important;
  z-index: 1 !important;
}

// stylelint-enable
// stylelint-disable sonnen/rule-no-different-bem-blocks

.c-body--disabled {
  overflow-y: hidden;
}
