@import '~@sonnen/shared-web/abstracts';

.c-guide-acceptance-utility {
  --guide-acceptance-utility-input-width: 350px;

  position: relative;
}

.c-guide-acceptance-utility__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(getColor('white'), .7);
  z-index: 1;
}

.c-guide-acceptance-utility__button {
  display: flex;
  justify-content: center;
  margin-top: var(--gap-6);

  @include breakpointUp(SM) {
    width: 100%;
    padding-right: var(--gap-7);
    margin: var(--gap-3) 0;
    justify-content: flex-end;
  }
}

.c-guide-acceptance-utility__error {
  margin: 0 auto;
  color: $color-coral;
}

.c-guide-acceptance-utility__error-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
