@import '~@sonnen/shared-web/abstracts';

.c-guide-preview-step {
  max-width: 300px;
  margin: var(--gap-3);
  text-align: center;
}

.c-guide-preview-step__image {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 140px;
}

.c-guide-preview-step__title {
  font-size: 18px;
  font-weight: 500;
  margin: var(--gap-2) 0;
}

.c-guide-preview-step__subtitle {
  font-size: 14px;
  line-height: 20px;
  color: getTextColor('grey');
}
