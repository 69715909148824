// ==========================================================================
// *1. Card is designed to be placed inside scollable container. This will
//     ensure the card fills the container.
// ==========================================================================

@import '~@sonnen/shared-web/abstracts';

$_profile-card-gutter: var(--gap-3);
$_profile-card-header-aspect-ratio: percentage(8 / 13);

@mixin profileCardTitleTheme() {
  margin-bottom: var(--gap-1);
  font-size: 10px;
  font-weight: 500;
  color: getTextColor('grey');
  line-height: 1.5em;
}

@mixin profileCardPromoTheme() {
  font-weight: 500;
  color: getTextColor('white');
  line-height: 1em;
  text-shadow: 0 0 3px rgba(getColor('grey-darkest'), .1);
}

.c-account-profile-card {
  @include boxShadow();
  @include borderRadius();
  position: relative;
  flex: 1 1; // 1
  background-color: getColor('white');
}

.c-account-profile-card__header {
  @include borderTopRadius();
  position: relative;
  top: 0;
  padding-bottom: $_profile-card-header-aspect-ratio;
}

.c-account-profile-card__header-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.c-account-profile-card__content {
  position: relative;
  padding: var(--gap-2) $_profile-card-gutter;
  background-color: getColor('white');
}

.c-account-profile-card__promo-text {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  padding: var(--gap-5) $_profile-card-gutter var(--gap-1);
  background-image: linear-gradient(to bottom, transparent, rgba(getColor('grey-darkest'), .25));
}

.c-account-profile-card__headline {
  @include profileCardPromoTheme();
  min-height: 1em;
  margin-bottom: 4px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-account-profile-card__subheadline {
  @include profileCardPromoTheme();
  margin-bottom: 0;
  font-size: 12px;
}

.c-account-profile-card__separator {
  margin: var(--gap-2) calc(#{$_profile-card-gutter} * -1);
  border: 1px solid getColor('grey-lightest');
  border-width: 1px 0 0;
}

.c-account-profile-card__products {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: calc(var(--gap-2) * -1);
}

.c-account-profile-card__products-title {
  @include profileCardTitleTheme();
}

.c-account-profile-card__tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: calc(var(--gap-1) * -1);
}

.c-account-profile-card__tags-title {
  @include profileCardTitleTheme();
  margin-right: var(--gap-1);
}

.c-account-profile-card__link {
  padding: var(--gap-2) var(--gap-3);
}
