@import '~@sonnen/shared-web/abstracts';

.c-demo-analysis-history {
  position: relative;
  margin: 0 calc(var(--gutter-layout) * -1) var(--gutter-section);

  @include breakpointUp(SM) {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-demo-analysis-history__card {
  @include borderRadius();
  @include boxShadow();
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: getColor('white');
}

.c-demo-analysis-history__card-main {
  flex: 1 1;
  padding: var(--gutter-card);
}
