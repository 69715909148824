@import '~@sonnen/shared-web/abstracts';

.c-icon-badge {
  --icon-badge-color-protected: var(--icon-badge-color, var(--header-font-color));
}

.c-icon-badge {
  display: flex;
  align-items: center;
  min-height: var(--gap-5);
  font-family: var(--font-family);
  color: var(--icon-badge-color-protected);
}

.c-icon-badge__label {
  min-height: 15px;
  margin: 0;
  margin-right: var(--gap-1);
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3em;
  text-align: right;
}

.c-icon-badge__icon {
  @include createIcon(
    $_color: var(--icon-badge-color-protected),
    $_height: var(--gap-3),
    $_width: var(--gap-3)
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-icon-badge__arrow {
  @include createIcon(
    $_color: var(--icon-badge-color-protected),
    $_width: 10px,
  );
  margin-right: var(--gap-1);
  transform: rotate(-90deg);
  transition: transform 250ms ease-in-out;

  &.is-active {
    transform: rotate(90deg);
  }
}
