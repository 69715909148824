@import '~@sonnen/shared-web/abstracts';

.c-analysis-slide {
  @include breakpointUp(SM) {
    padding: 0 calc(var(--gutter-columns) * .5);
  }
}

.c-analysis-slide__inner {
  @include borderRadius('big');
  @include boxShadow();
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 135px;
  padding: var(--gap-2);
}

.c-analysis-slide__content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-analysis-slide__icon {
  @include createIcon(
    $_color: getTextColor('grey'),
    $_width: 60px,
    $_height: 60px
  );
  margin-right: var(--gap-2);
}

.c-analysis-slide__text {
  flex: 0 1 130px;
}

.c-analysis-slide__value {
  display: inline-block;
  margin: 0 4px var(--gap-1) 0;
  font-size: 32px;
  font-weight: 500;
  color: $color-light-gold;
  line-height: 1em;
}

.c-analysis-slide__unit {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: getTextColor('grey');
}
